import appResource from "@/resources/AppResource";
import Vue from "vue";

const state = Vue.observable({
    appVersion: "",
    apiVersion: "",
    updateAvailable: false,
});

class AppStatusService {
    private readonly state = state;
    private interval: number = 0;

    get appVersion() {
        return this.state.appVersion;
    }
    get apiVersion() {
        return this.state.apiVersion;
    }
    get updateAvailable() {
        return this.state.updateAvailable;
    }

    init(): void {
        this.reset();
        this.interval = setInterval(this.ping.bind(this), 60 * 1000); // every 1 minute
        this.ping();
    }

    ping(): void {
        appResource
            .getApiStatus()
            .then((resp) => {
                this.state.apiVersion = resp.data.apiVersion;
            })
            .catch(appResource.defaultErrorHandler);
        
        appResource
            .getAppStatus()
            .then((resp) => {
                if (!this.state.appVersion) {
                    this.state.appVersion = resp.data.ver;
                } else {
                    if (this.state.appVersion !== resp.data.ver) {
                        this.state.updateAvailable = true;
                    }
                }
            });
    }

    reset() {
        clearInterval(this.interval);
        this.interval = 0;
    }
}

const appStatusService = new AppStatusService();

export default appStatusService;
