<template>
  <div class="info-messages-container">
    <div>
      <v-alert v-for="(alert, index) in infoMsg.state.messages" :key="index" dismissible border="left" :type="alert.type">
        {{ alert.message }}
      </v-alert>
    </div>
  </div>
</template>

<script lang="ts">
import infoMessageService from "@/services/InfoMessageService";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InfoMessage extends Vue {
  infoMsg = infoMessageService;
}
</script>

<style scoped>
.info-messages-container {
  position: fixed;
  top: 60px;
  right: 10px;
  z-index: 9999;
}
.v-alert {
  padding: 8px 16px 8px 16px;
  margin-left: 10px;
  font-size: 1rem;
}
</style>