<template>
  <v-card :loading="loading" :disabled="loading" flat>
    <v-card-text class="pa-0">
      <v-sheet class="add-customer-comment">
        <RichText v-model="newContent" :disabled="loading" small="true" placeholder="Write your new comment here..." />
      </v-sheet>
      <div class="d-sm-flex justify-space-between align-end">
        <Attachments
          ref="attachmentsRef"
          :targetType="targetType"
          :targetId="commentId"
          v-on:updateLocalFiles="updateLocalFiles"
          isActionByCommand="true"
        />
        <div class="text-right mt-4">
          <v-btn color="primary" @click="addComment" :loading="loading" :disabled="!isReadyForm || loading" small>
            Add comment
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { UserPermissionType } from "@/types/UserPermissionType";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import userProfileService from "@/services/UserProfileService";
import customerResource from "@/resources/CustomerResource";
import { InfoMessageType } from "@/types/InfoMessageType";
import infoMessageService from "@/services/InfoMessageService";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";

@Component({
  components: { RichText, Attachments },
})
export default class AddCustomerComment extends Vue {
  @Prop()
  readonly customerId!: number | undefined;

  loading = false;
  newContent = "";
  targetType = AttachmentTargetType.CustomerComment;
  commentId = 0;
  localFiles: File[] = [];

  get canAddComment() {
    return userProfileService.hasPermission(UserPermissionType.ViewCustomers);
  }

  get isReadyForm() {
    return Boolean(this.newContent.trim().length);
  }

  updateLocalFiles(files: File[]) {
    this.localFiles = files;
  }

  addComment() {
    if (!this.canAddComment || !this.isReadyForm) {
      if (!this.newContent.trim().length) {
        infoMessageService.show(InfoMessageType.Info, "Add text to the new comment");
      }
      return;
    }
    if (this.customerId === undefined) return;

    this.loading = true;
    let data: any;
    return customerResource
      .addCustomerComment(this.customerId, this.newContent)
      .then((resp) => {
        this.commentId = resp.data.commentId;
        data = resp.data;
      })
      .then(() => (this.$refs.attachmentsRef as Attachments)?.uploadLocalFiles())
      .then((attachments) => {
        data.attachments = attachments;
        this.commentId = 0;
        this.localFiles = [];

        infoMessageService.show(InfoMessageType.Success, "Comment added");
        this.$emit("created", data);
        this.newContent = "";
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>
.add-customer-comment {
  position: relative;
}
</style>
