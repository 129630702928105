<template>
  <v-btn
    class="mr-2 btn"
    :class="btnStyles"
    :color="`${$vuetify.theme.dark ? 'secondary' : undefined}`"
    :style="{ width: data.title.length * 7 + 45 + 'px' }"
    small
    @click="setActive"
  >
    <span v-if="!isActive">
      <v-icon class="mr-1" small>{{ data.icon }}</v-icon>
      <span> {{ data.title }} </span>
    </span>

    <v-combobox
      :loading="loading"
      :value="data.disableMultiple ? data.selected[0] : data.selected"
      @input="onInput"
      v-if="isActive"
      :ref="data.filterName"
      :items="suggestedItems"
      item-text="text"
      dense
      :placeholder="data.title"
      class="btn-field"
      :class="{ 'icon-rotate': isOpenMenu }"
      hide-details
      :hide-no-data="loading || !isLoaded"
      :multiple="data.disableMultiple ? false : true"
      auto-select-first
      @keyup.space.stop.prevent
      @blur="blur"
      :search-input.sync="search"
      :menu-props="{ value: isOpenMenu, contentClass: 's-custom-menu-content-filter' }"
      @click:append.stop.prevent="blur"
    >
      <template v-slot:prepend-inner>
        <v-icon :color="$vuetify.theme.dark ? 'white' : 'dark darken-1'" center class="ml-1 labe-icon" small>
          {{ data.icon }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-list-item class="no-data"> {{ `No ${data.title} available` }} </v-list-item>
      </template>
      <template v-slot:selection=""> </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          class="list-item"
          :class="{
            'list-item-mobile': $vuetify.breakpoint.xs,
            delimiterItem: (data.dividerValues || []).includes(item.value) && !search,
          }"
          active-class="active-item"
          @click="onClick(item)"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action class="mr-2 my-0 pa-1">
              <v-checkbox dense hide-details small :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
            <v-divider v-if="(data.dividerValues || []).includes(item.value) && !search" class="delimiter" />
          </template>
        </v-list-item>
      </template>
      <template v-slot:append-item="">
        <div class="footer">
          <v-divider></v-divider>
          <v-list-item :ripple="false" dense @click="() => {}" class="close-menu-btn">
            <v-list-item-title>Close</v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-combobox>
  </v-btn>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import TableFilter from "@/types/TableFilter";

@Component({})
export default class FilterSelect extends Vue {
  search: string | null = null;
  isActive: boolean = false;
  suggestedItems: TableFilter["selected"][] = [];
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  isAnimationCompleted = false;
  isLoaded = false;
  openMenuTimeout = 0;

  @Prop({ default: [] })
  data!: TableFilter;

  get btnStyles() {
    return {
      "btn-active": this.isActive,
      "btn-active-mobile": this.$vuetify.breakpoint.xs,
      "btn-transition": !this.$vuetify.breakpoint.xs,
    };
  }

  get isOpenMenu() {
    return this.isAnimationCompleted && !this.loading && this.isLoaded;
  }

  @Watch("search")
  onSearchChanged() {
    if (!this.search?.trim() && this.search) {
      (this.$refs[this.data.filterName] as any).internalSearch = "";
      return;
    }

    if (!this.data.searchable) {
      return this.getFastData();
    }

    this.clearSearchTimeout();
    this.loading = true;

    this.searchThrottleTimer = setTimeout(() => this.getData(), 1000);
  }

  onClick(item: { text: string; value: any }[] | { text: string; value: any }) {
    if (this.data.disableMultiple && !Array.isArray(item) && this.data.selected.includes(item)) {
      this.data.selected = [];
    }
  }
  
  onInput(value: { text: string; value: any }[] | { text: string; value: any }) {
    if (Array.isArray(value)) {
      this.data.selected = value;
    } else {
      this.data.selected = value ? [value] : [];
    }
    //@ts-ignore
    Vue.nextTick(() => this.$refs[this.data.filterName].activateMenu());
  }
  clearSearchTimeout() {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }
  }

  async getFastData() {
    const searchName = this.search === null ? undefined : this.search;
    const data = await this.data.itemsCallback(searchName, this.cancelToken);

    if (data) {
      this.suggestedItems = data;
      if (!this.isLoaded) this.isLoaded = true;
    }
    if (!data) {
      this.suggestedItems = [];
    }
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(async () => {
      // Timeout is workaround for finally() being executed after request was canceled and new request already began
      this.cancelToken = axios.CancelToken.source();
      const searchName = this.search === null ? undefined : this.search;

      const data = await this.data.itemsCallback(searchName, this.cancelToken);

      if (data) {
        this.suggestedItems = data;
        if (!this.isLoaded) this.isLoaded = true;
      }
      if (!data) {
        this.suggestedItems = [];
      }
      this.loading = false;
      this.cancelToken = undefined;
    }, 10);
  }

  blur() {
    if (this.openMenuTimeout) {
      clearTimeout(this.openMenuTimeout);
    }
    this.isAnimationCompleted = false;
    setTimeout(() => (this.isActive = false), 100);
  }

  setActive() {
    if (this.isActive) return;
    this.isActive = true;
    if (!this.suggestedItems.length) {
      this.getData();
    }

    Vue.nextTick(() => {
      (this.$refs[this.data.filterName] as HTMLElement).focus();
      //@ts-ignore
      this.$refs[this.data.filterName].activateMenu();
    });

    this.openMenuTimeout = setTimeout(() => (this.isAnimationCompleted = true), 400);
  }
}
</script>

<style scoped>
.btn {
  margin: 5px;
  text-transform: none;
  max-width: 100%;
  overflow: hidden;
}

.btn:focus:before {
  opacity: 0 !important;
}

.btn-transition {
  transition: all 0.3s ease;
}

.btn-active {
  width: 250px !important;
  max-width: 100%;
  padding: 0 !important;
}

.btn-active-mobile {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.btn-field {
  width: 100% !important;
}
.btn-field ::v-deep input {
  width: 100%;
  font-size: 13px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-bottom: 3px !important;
}
.btn-field ::v-deep .v-input__icon--append .v-icon {
  transform: rotate(0);
}

.labe-icon {
  margin-top: 2px;
}

.icon-rotate ::v-deep .v-input__icon--append .v-icon {
  transform: rotate(180deg);
}

.no-data {
  font-size: 13px;
  min-height: 20px;
}

.list-item {
  font-size: 14px;
  font-weight: 500;
  max-width: 240px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-item-mobile {
  max-width: 100% !important;
}

.close-menu-btn {
  text-align: right;
  font-size: 13px;
  margin-top: 5px;
  min-height: 30px !important;
  min-width: 150px;
  color: var(--v-primary-base) !important;
  cursor: pointer;
}

:root[theme="light"] .close-menu-btn {
  color: #ffaa03 !important;
  font-weight: 700 !important;
}

.active-item.v-list-item--active:before,
.v-list-item--highlighted:before {
  opacity: 0.07 !important;
}

.footer {
  position: sticky;
  bottom: 0;
  background: #2d2d2d;
  width: 100%;
}

:root[theme="light"] .footer {
  background: #fff;
}
</style>

<style>
.s-custom-menu-content-filter {
  background: #2d2d2d;
}

:root[theme="light"] .s-custom-menu-content-filter {
  background: #fff;
}

.delimiter {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: -5px;
}

.delimiterItem {
  position: relative;
  margin-bottom: 10px;
  overflow: visible !important;
}
</style>
