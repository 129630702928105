<template>
  <div>
    <v-snackbar v-model="show" top timeout="-1" color="purple">
      Application update available! Refresh to get the latest features and improvements.
      <template v-slot:action="{}">
        <v-btn color="white" text @click="refresh()" :loading="refreshing">Refresh</v-btn>
        <v-btn icon small color="white" @click="postpone()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import appStatusService from "@/services/AppStatusService";

@Component({})
export default class AppUpdateNotification extends Vue {
  updatePosponed = false;
  refreshing = false;

  get show() {
    return appStatusService.updateAvailable && this.updatePosponed === false;
  }

  postpone() {
    this.updatePosponed = true;
  }

  refresh() {
    this.refreshing = true;
    window.location.reload();
  }
}
</script>