<template>
  <div v-if="loading" class="w-100 d-flex justify-center my-5">
    <v-progress-linear class="w-25 my-5" indeterminate></v-progress-linear>
  </div>
  <div v-else-if="!error" class="html-source-container" v-html="html" />
  <div v-else>{{ error }}</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import staticResource from "@/resources/StaticResource";

@Component({})
export default class HtmlSource extends Vue {
  loading = false;
  error = "";
  html = "";

  @Prop()
  readonly src!: string;

  mounted() {
    this.getHTML();
  }

  getHTML() {
    if (!this.src) return;

    this.loading = true;

    staticResource
      .getHTML(this.src)
      .then((res) => (this.html = res.data))
      .catch(() => {
        staticResource.defaultErrorHandler;
        this.error = "No description available";
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
