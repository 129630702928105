import Confirm from '@/services/ConfirmService';
import themeSwitcher from '@/services/ThemeSwitcher';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        dark: themeSwitcher.isDark,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#FEDD10',
                secondary: '#2D2D2B',
                accent: '#FEDD10',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                dark: '#1D1D1B',
                darkgrey: '#3D3D3D',
            },
            dark: {
                primary: '#FEDD10',
                secondary: '#2D2D2B',
                accent: '#FEDD10',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                dark: '#1D1D1B',
                darkgrey: '#3D3D3D',
            },
        },
    },
});

Vue.use(Confirm, { vuetify });

export default vuetify;