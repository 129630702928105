<template>
  <div>
    <side-sheet v-if="value" v-model="showDialog" heading="Affected devices">
      <div v-if="(canAddIssues || canEditIssues) && canViewDevices" class="text-right">
        <v-autocomplete
          v-if="!isMultipleMode"
          v-model="newSelectedItem"
          :items="newDeviceItems"
          :loading="loading"
          :search-input.sync="newDeviceSearchTerm"
          clearable
          hide-no-data
          no-filter
          label="Add new affected device"
          return-object
          item-value="deviceId"
          persistent-hint
          hint="Search by device ID or device name"
        >
          <template v-slot:selection="{ item }">
            {{ `${item.deviceId} - ${item.deviceName}` }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              {{ `${item.deviceId} - ${item.deviceName}` }}
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-textarea 
          v-if="isMultipleMode"
          label="Enter multiple device IDs"
          hint="Comma, space or new-line separated device IDs"
          persistent-hint
          auto-grow
          rows="1"
          v-model="newIDsTherm"
        ></v-textarea>
        
        <div class="d-flex align-center justify-end">
          <v-switch class="mr-6 mt-0 new-device-mode-switch" dense hide-details label="Add multiple" v-model="isMultipleMode" />
          <v-btn color="primary" v-if="isMultipleMode" small @click="addDevices" :disabled="!newIDsTherm"> Add </v-btn>
          <v-btn color="primary" v-if="!isMultipleMode" small @click="addDevice" :disabled="!newSelectedItem"> Add </v-btn>
        </div>
      </div>

      <IssueCurrentDevices
        v-model="issue"
        :forceReload="reloadCurrentDevices"
        @remove="removeDevice"
        @changeStatus="$emit('changeStatus')"
        :statusSummary="statusSummary"
      />

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" text @click="showDialog = false">Close</v-btn>
      </template>
    </side-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import IssueCurrentDevices from "@/components/issues/IssueCurrentDevices.vue";
import SideSheet from "@/components/layout/SideSheet.vue";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import Device from "@/types/Device";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import issueResource from "@/resources/IssueResource";
import deviceResource from "@/resources/DeviceResource";
import axios, { CancelTokenSource } from "axios";
import Issue from "@/types/Issue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  components: { SideSheet, DeviceMenu, IssueCurrentDevices },
})
export default class IssueAffectedDevices extends Vue {
  @Prop({ default: [] })
  readonly statusSummary!: { status: IssueDeviceStatus; count: number }[];

  @Prop()
  readonly value!: boolean;

  @Prop()
  readonly issue!: Issue;
  loading = false;
  cancelToken: CancelTokenSource | undefined = undefined;
  newSelectedItem: Device | null = null;
  newDeviceItems: Device[] = [];
  newDeviceSearchTerm: string | null = null;
  searchThrottleTimer = 0;
  reloadCurrentDevices = {};
  newIDsTherm = "";
  isMultipleMode = false;
  
  @Watch("newDeviceSearchTerm")
  onDeviceSearchTermChanged(oldValue: any, newValue: any) {
    if (this.issue && oldValue !== newValue) {
      if (this.searchThrottleTimer) {
        clearTimeout(this.searchThrottleTimer);
        this.searchThrottleTimer = 0;
      }
      this.searchThrottleTimer = setTimeout(() => {
        this.getIssueDevices();
      }, 1000);
    }
  }

  get showDialog() {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit("input", value);
  }

  get canViewDevices() {
    return userProfileService.hasPermission(UserPermissionType.ViewDevices);
  }

  get canAddIssues() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  get canEditIssues() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues);
  }

  removeDevice() {
    this.$emit("changeTotalDevices", -1);
  }

  addDevices() {
    const idData = this.newIDsTherm
      .split(/\s+|,/g)
      .filter((v) => v.trim())
      .filter((v: string, ind: number, arr: string[]) => arr.indexOf(v) === ind);

    const iDs = idData.map((v) => Number(v.trim())).filter((v) => !isNaN(v));

    if (idData.length !== iDs.length) {
      const invalidIds = idData.filter((id) => !iDs.find((v) => String(v) === id));
      return infoMessageService.show(InfoMessageType.Error, `The following values are not device IDs: ${invalidIds.join(", ")}`);
    }

    if (iDs?.length > 300) {
      return infoMessageService.show(
        InfoMessageType.Error,
        "Too many davices. You can only add 300 devices at a time."
      );
    }

    if (!this.issue || !iDs.length) {
      return;
    }

    this.loading = true;

    const promise = Promise.all(
      iDs.map((id) => {
        return issueResource.addDevice(this.issue.issueId, id).catch((err) => ({ err, isError: true, id }));
      })
    );

    promise
      .then((resp) => {
        const errors = resp.filter((v: any) => v?.isError);
        if (errors.length) {
          this.$confirm.show(
            `One or more devices were not linked to the issue. Either device is already linked, or device does not exist.<br/><br/>${errors.map((v: any) => v.id).join(", ")}`, 
            {
              color: "red",
              width: 500,
              persistent: true,
              buttonTrueText: "Close",
              buttonFalseText: null
            }
          );
        }
        this.newIDsTherm = "";
        this.reloadCurrentDevices = {};

        if (iDs.length - errors.length > 0) {
          this.$emit("changeTotalDevices", iDs.length - errors.length);
        }
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  addDevice() {
    if (!this.issue || !this.newSelectedItem) {
      return;
    }

    this.loading = true;

    issueResource
      .addDevice(this.issue.issueId, this.newSelectedItem.deviceId)
      .then(() => {
        this.newSelectedItem = null;
        this.reloadCurrentDevices = {};
        this.$emit("changeTotalDevices", 1);
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  getIssueDevices() {
    if (!this.issue) {
      return;
    }
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is a workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      if (!this.issue) {
        return;
      }
      deviceResource
        .getDevicesPaged(5, 1, this.newDeviceSearchTerm ?? undefined, undefined, undefined, undefined, undefined, undefined, this.cancelToken)
        .then((resp) => (this.newDeviceItems = resp.data.items))
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}
</script>

<style scoped>
.new-device-mode-switch {
  text-transform: unset !important
}
.new-device-mode-switch >>> .v-label {
  font-size: .85rem;
}
</style>
