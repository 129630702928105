<template>
  <div>
    <div class="d-block mt-4">
      <v-select
        attach
        v-model="period"
        :items="periodOptions"
        outlined
        dense
        class="flex-grow-0"
        style="min-width: 150px"
        hide-details
        label="Time window"
      />
    </div>
    <div class="d-block pt-4">
      <div>Studio API requests</div>
      <div class="user-activity-resp-status-chart-wrap">
        <div v-if="showChart" class="user-activity-resp-status-chart">
          <v-overlay v-if="loading" absolute class="progress" opacity="0" z-index="101">
            <v-progress-linear indeterminate absolute />
          </v-overlay>
          <v-chart ref="chart" :option="option" autoresize :class="{ 'opacity-50': loading }" 
          />
        </div>
        <div v-else class="text-center body-2 text--disabled mt-2">No data available</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import User from "@/types/User";
import axios, { CancelTokenSource } from "axios";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { use, ComposeOption } from "echarts/core";
import moment from "moment";
import userResource from "@/resources/UserResource";

use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<
  LineSeriesOption | TitleComponentOption | TooltipComponentOption | LegendComponentOption | GridComponentOption
>;

@Component({
  components: { VChart },
})
export default class UserActivity extends Vue {
  showChart: boolean = false;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  period = 7;

  periodOptions = [
    { text: "Last 7 days", value: 7 },
    { text: "Last 30 days", value: 30 },
    { text: "Last 90 days", value: 90 },
  ];

  get textColor() {
    return this.$vuetify.theme.dark ? "#fff" : "#333";
  }

  get barTextColor() {
    return this.$vuetify.theme.dark ? "#fedd10" : "#333";
  }

  get secondaryColor() {
    return this.$vuetify.theme.dark ? "#333" : "#ccc";
  }

  option: ECOption = {
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },

    grid: { top: 30, bottom: 30 },

    tooltip: {
      trigger: "axis",
      confine: true,
      formatter: (args: any) => {
        let tooltip = `<div>${moment(args[0].axisValue).format("D MMM, YYYY")}</div> `;

        args.forEach(({ value }: any) => {
          tooltip += `<div class="w-100 text-right">
                        <b>${value[1] || 0}</b>
                      </div>`;
        });

        return tooltip;
      },
      axisPointer: {
        type: "line",
      },
    },

    xAxis: {
      type: "time",
      axisLine: {
        lineStyle: {
          color: this.secondaryColor,
        },
      },
      axisLabel: {
        color: this.textColor,
      },
      splitLine: {
        show: true,
        lineStyle: { color: this.secondaryColor },
      },
    },

    yAxis: {
      type: "value",
      alignTicks: true,
      minInterval: 1,
      axisLabel: { color: this.textColor },
      splitLine: {
        show: true,
        lineStyle: { color: this.secondaryColor },
      },
    },

    series: [],
    animation: false,
  };

  @Prop()
  readonly value!: User | null;

  @Watch("period")
  onChangePeriod() {
    if (this.period) this.getData();
  }

  mounted() {
    this.getData();
  }
  destroyed() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      if (!this.value) return;

      userResource
        .getApiUserActivity(this.value.userId, this.period, this.cancelToken)
        .then((resp) => {
          const data = resp.data;
          this.showChart = Object.values(data).length > 0;

          this.option.series = [
            {
              type: "line",
              areaStyle: { color: this.$vuetify.theme.currentTheme.primary as string },
              lineStyle: { color: this.$vuetify.theme.currentTheme.primary as string },
              showSymbol: false,
              dimensions: [{ type: "time" }, { type: "number" }],
              data: Object.entries(data),
            },
          ];
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}
</script>

<style scoped>
.user-activity-resp-status-chart-wrap {
  width: 100%;
  flex: 1;
}
.user-activity-resp-status-chart {
  height: 500px;
  max-height: 70dvh;
  width: 100%;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
}

.progress >>> .v-overlay__content {
  width: 25%;
  margin-bottom: 55px;
}
</style>
