var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{staticClass:"align-self-end",attrs:{"dot":"","bordered":"","overlap":"","value":_vm.headersUpdated}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","title":"Table configuration"},on:{"click":_vm.openDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-columns")])],1)],1)]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"primary","height":"4px"}}),_c('v-card-title',[_vm._v(" Table configuration ")]),_c('v-card-text',{class:{
        'd-sm-flex list-container pb-1': true,
        'mobile-list-container': _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('div',{class:{
          'column mr-sm-2 fill-sm-height': true,
          'mobile-column': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-subheader',{staticClass:"heading"},[_vm._v("Available columns")]),_c('v-card',{staticClass:"columns-card",attrs:{"tile":""}},[_c('v-list',{staticClass:"items-list",attrs:{"dense":""}},[(_vm.availableColumns.length)?_c('div',_vm._l((_vm.availableColumns),function(item,i){return _c('v-list-item',{key:i,attrs:{"selectable":false,"ripple":false,"inactive":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1),_c('v-btn',{staticClass:"table-settings-remove-btn",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.add(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)}),1):_c('v-list-item',[_c('v-list-item-content',{staticClass:"grey--text"},[_c('v-list-item-title',[_vm._v("No more columns available")])],1)],1)],1)],1)],1),_c('div',{class:{
          'column ml-sm-2 fill-sm-height': true,
          'mobile-column': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-subheader',{staticClass:"heading mt-4 mt-sm-auto"},[_vm._v("Selected columns")]),_c('v-card',{staticClass:"columns-card",attrs:{"tile":""}},[_c('v-list',{staticClass:"items-list",attrs:{"dense":""}},[_c('v-list-item-group',{staticClass:"items-list"},[(_vm.selectedHeaders.length)?_c('draggable',{attrs:{"delay":"200","touchStartThreshold":"4","delayOnTouchOnly":true,"ghost-class":"dragged-item-ghost"},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},[_c('transition-group',_vm._l((_vm.selectedHeaders),function(item,i){return _c('v-list-item',{key:item.value,attrs:{"selectable":false,"ripple":false,"inactive":""}},[_c('v-list-item-content',{staticClass:"drag"},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1),_c('v-btn',{attrs:{"x-small":"","disabled":i === 0,"icon":""},on:{"click":function($event){return _vm.moveUp(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")])],1),_c('v-btn',{attrs:{"x-small":"","disabled":i === _vm.selectedHeaders.length - 1,"icon":""},on:{"click":function($event){return _vm.moveDown(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-down ")])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)}),1)],1):_c('v-list-item',{attrs:{"disabled":true}},[_c('v-list-item-content',{staticClass:"grey--text"},[_c('v-list-item-title',[_vm._v("No columns selected")])],1)],1)],1)],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }