<template>
  <v-dialog v-if="canAddIssue" v-model="showNewIssueDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="primary" class="align-self-end" v-bind="attrs" v-on="on">
        New issue
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense flat color="primary" height="4px"> </v-toolbar>
      <v-card-title> New Issue </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          :disabled="loading"
          label="Issue title"
          autofocus
          maxlength="120"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel"> Cancel </v-btn>
        <v-btn color="primary" :disabled="!name" :loading="loading" @click="createIssue">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { UserPermissionType } from "@/types/UserPermissionType";
import axios, { CancelTokenSource } from "axios";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
//import infoMessageService from "@/services/InfoMessageService";
//import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  components: {},
})
export default class CreateIssue extends Vue {
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  showNewIssueDialog = false;
  name = "";

  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  cancel() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
    this.name = "";
    this.showNewIssueDialog = false;
  }

  createIssue() {
    if (!this.canAddIssue || !this.name) {
      return;
    }
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
    this.loading = true;
    this.cancelToken = axios.CancelToken.source();
    issueResource
      .addIssue(this.name, undefined, this.cancelToken)
      .then((resp) => {
        const newIssueId = resp.data.issueId;
        //infoMessageService.show(InfoMessageType.Success, `New issue created`);
        this.$router.push(`/support/issues/${newIssueId}`);
        this.cancel();
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.cancelToken = undefined;
      });
  }
}
</script>

<style scoped></style>
