<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="text-right align-self-end mt-0 d-block d-sm-none">
          <CreateIssue />
        </div>
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            hide-details
            clearable
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
          ></v-text-field>

          <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

          <TableConfiguration
            :allHeaders="headers"
            v-model="selectedHeaders"
            tableKey="issuesTableColumns"
          />

          <v-btn
            small
            icon
            class="align-self-end"
            @click="showFilter = !showFilter"
            :color="showFilter ? 'primary' : undefined"
            :disabled="loading"
            title="Filters"
          >
            <v-icon>mdi-filter-variant</v-icon>
            <v-badge v-if="numberOfFilter" transition="v-fade-transition" dot bordered offset-x="8" offset-y="-1" />
          </v-btn>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 d-none d-sm-block">
          <CreateIssue />
        </div>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" />

      <v-data-table
        dense
        :item-class="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="footerProps"
        :mobile-breakpoint="0"
        @click:row="rowClick"
      >
        <template v-slot:[`item.status`]="{ item }">
          <span :class="IssueHelper.getIssueStatusColor(item.status, true)">{{ getIssueStatusName(item.status) }}</span>
        </template>

        <template v-slot:[`item.priority`]="{ item }">
          <v-icon :color="IssueHelper.getIssuePriorityColor(item.priority)" :title="IssueHelper.getIssuePriorityName(item.priority)">
            {{ IssueHelper.getIssuePriorityIcon(item.priority) }}
          </v-icon>
        </template>

        <template v-slot:[`item.tags`]="{ item }">
          <v-chip small class="ma-1px" v-for="tag in item.tags" v-bind:key="tag.tagId">
            {{ tag.name }}
          </v-chip>
        </template>

        <template v-slot:[`item.author`]="{ item }">
          <span>{{ item.author.username }}</span>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span v-if="item.createdAt">{{ moment(item.createdAt).format("lll") }}</span>
        </template>
        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">{{ moment(item.lastModifiedAt).format("lll") }}</span>
        </template>
      </v-data-table>

      <v-overlay absolute :value="loading" opacity="0" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import Filters from "@/components/common/Filters/Filters.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import CreateIssue from "@/components/issues/CreateIssue.vue";
import userStorage from "@/services/UserStorageService";
import IssueHelper from "@/helpers/issueHelper";
import TableFilter from "@/types/TableFilter";
import { IssueStatus } from "@/types/IssueStatus";
import tagResource from "@/resources/TagResource";
import { TagType } from "@/types/TagType";

@Component({
  name: "Issues", // name is needed for keep-alive
  components: { CreateIssue, Filters, TableConfiguration },
})
export default class Issues extends Vue {
  @Prop({ default: null })
  readonly initFilter!: { [key: string]: TableFilter["selected"] };

  moment = moment;
  IssueHelper = IssueHelper;
  showFilter = Boolean(Object.values(this.$props.initFilter || {}).length);
  total = 0;
  items: Issue[] = [];
  loading = false;

  optionsStorageKey = "issuesTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: ["issueId"],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 15,
  };

  footerProps = {
    showFirstLastPage: true,
    "items-per-page-options": [15, 25, 50],
  };
  searchTermStorageKey = "issuesTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  issueToEdit: Issue | null = null;

  @Watch("options", { deep: true })
  onPropertyChanged() {
    this.getData();
  }

  selectedHeaders = [];
  headers = [
    { text: "ID", align: "start", value: "issueId" },
    { text: "Status", value: "status" },
    { text: "Priority", value: "priority" },
    { text: "Title", value: "name" },
    { text: "Tags", value: "tags" },
    { text: "Affected devices", value: "numberOfAffectedDevices", sortable: false },
    { text: "Comments", value: "numberOfComments", sortable: false },
    { text: "Created", value: "createdAt" },
    { text: "Reporter", value: "createdBy" },
    { text: "Updated", value: "lastModifiedAt" },
    { text: "Updated by", value: "lastModifiedBy" },
  ];

  filter: TableFilter[] = [
    {
      title: "Status",
      icon: "mdi-checkbox-multiple-marked",
      filterName: "status",
      searchable: false,
      selected: this.$props.initFilter.status || [],
      itemsCallback: (search?: string) => {
        if (!search) return IssueHelper.getIssueFilter();
        return IssueHelper.getIssueFilter().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
      },
    },

    {
      title: "Tags",
      icon: "mdi-tag-multiple",
      filterName: "tags",
      searchable: true,
      selected: this.$props.initFilter.tags || [],
      itemsCallback: this.getTags,
    },

    {
      title: "Reporters",
      icon: "mdi-account-multiple",
      filterName: "reporters",
      searchable: true,
      selected: this.$props.initFilter.reporters || [],
      itemsCallback: this.getReporters,
    },
  ];

  get numberOfFilter() {
    return Object.values(this.filter).reduce((acc, { selected }) => (acc += selected.length), 0);
  }

  updateFilter(newFilter: TableFilter[]) {
    this.filter = newFilter;
    if (this.options.page > 1) {
      this.options.page = 1;
      return;
    }

    this.getData();
  }

  getIssueStatusName(status: number) {
    return IssueHelper.getIssueStatusDisplayName(status);
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, this.options);
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      issueResource
        .getIssuesPaged(
          itemsPerPage,
          page,
          this.searchTerm,
          sortBy[0],
          sortDesc[0],
          this.filter.find(({ filterName }) => filterName === "status")?.selected.map(({ value }) => value),
          this.filter.find(({ filterName }) => filterName === "reporters")?.selected.map(({ value }) => value),
          this.filter.find(({ filterName }) => filterName === "tags")?.selected.map(({ value }) => value),
          undefined,
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
          if (resp.data.totalPages < page) this.options.page = 1;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  getReporters(search?: string, cancelToken?: CancelTokenSource) {
    return issueResource
      .getReporters(search?.trim(), 5, cancelToken)
      .then((resp) => resp.data.map((user) => ({ text: user.username, value: user.userId })))
      .catch(issueResource.defaultErrorHandler);
  }

  getTags(search?: string, cancelToken?: CancelTokenSource) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    return tagResource
      .getTagsPaged(5, 1, search?.trim(), [TagType.Issue], "issue_count", true, cancelToken)
      .then((resp) => resp.data.items.map((tag) => ({ text: tag.name, value: tag.tagId })))
      .catch(tagResource.defaultErrorHandler);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData();
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData();
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: Issue) {
    this.$router.push(`/support/issues/${item.issueId}`);
  }

  rowClass(item: Issue) {
    if (item.status === IssueStatus.Closed) {
      return "cursor-default grey--text";
    } else {
      return "cursor-default";
    }
  }
}
</script>

<style scoped></style>
