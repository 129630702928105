<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            clearable
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
            test-id="search"
          ></v-text-field>
          <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="usersTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn small color="primary" class="align-self-end" @click="newUser()" test-id="new-user"> New user </v-btn>
            <v-menu v-if="isAdmin" bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="ml-4"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportUserActivity(moment().subtract(1, 'year').year())">
                <v-list-item-title>{{`Export user activity - ${moment().subtract(1, 'year').year()}`}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportUserActivity(moment().year())">
                <v-list-item-title>{{`Export user activity - ${moment().year()}`}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>

      <v-data-table
        dense
        :item-class="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="footerProps"
        :mobile-breakpoint="0"
        @click:row="rowClick"
        @contextmenu:row="openContenxMenu"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon small color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isAdministrator`]="{ item }">
          <v-icon small color="green" v-if="item.isAdministrator">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.groups`]="{ item }">
          <v-chip
            small
            class="ma-1px cursor-pointer"
            v-for="group in item.userGroups"
            v-bind:key="group.userGroupId"
            @click.native.stop="userGroupToEdit = JSON.parse(JSON.stringify(group))"
            >{{ group.name }}</v-chip
          >
        </template>
        <template v-slot:[`item.lastLoginDate`]="{ item }">
          <span v-if="item.lastLoginDate">{{ moment(item.lastLoginDate).format("lll") }}</span>
        </template>
        <template v-slot:[`item.mfa`]="{ item }">
          <span v-if="item.mfaType">{{ getMfaTypeName(item.mfaType) }}</span>
        </template>
      </v-data-table>

      <v-overlay absolute :value="loading" opacity="0" />
    </v-card>

    <EditUser v-model="userToEdit" v-on:updated="reload" :initTab="userInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
    <EditUserGroup v-model="userGroupToEdit" v-on:updated="reload" :isDeleteBtn="false" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import User from "@/types/User";
import userResource from "@/resources/UserResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import EditUser from "@/components/users/EditUser.vue";
import UserGroup from "@/types/UserGroup";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { MultiFactorAuthType } from "@/types/MultiFactorAuthType";
import UserHelper from "@/helpers/userHelper";
import userStorage from "@/services/UserStorageService";
import EditUserGroup from "@/components/users/EditUserGroup.vue";
import userGroupResource from "@/resources/UserGroupResource";
import commonHelper from "@/helpers/commonHelper";
import userProfileService from "@/services/UserProfileService";

@Component({
  components: {
    EditUser,
    DataTableContextMenu,
    TableConfiguration,
    EditUserGroup,
  },
})
export default class Users extends Vue {
  @Prop({ default: null })
  initData!: { userId: number; userGroupId: number; userTab: string } | null;

  moment = moment;

  total = 0;
  items: User[] = [];
  loading = false;
  userGroupToEdit: UserGroup | null = null;
  userInitTab: string | null = null;

  optionsStorageKey = "usersTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 15,
  };
  footerProps = {
    showFirstLastPage: true,
    "items-per-page-options": [15, 25, 50],
  };
  searchTermStorageKey = "userTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  userToEdit: User | null = null;

  get isAdmin() {
    return userProfileService.currentUser?.isAdministrator;
  }

  @Watch("options", { deep: true })
  onPropertyChanged() {
    this.getData();
  }

  mounted() {
    if (this.initData?.userId) {
      this.getInitUserById(this.initData?.userId);
    }
    if (this.initData?.userGroupId) {
      this.getInitUserGroupById(this.initData?.userGroupId);
    }
  }


  getInitUserGroupById(userGroupId: number) {
    userGroupResource
      .getUserGroupById(userGroupId)
      .then((resp) => {
        this.userGroupToEdit = resp.data;
      })
      .catch(userGroupResource.defaultErrorHandler);
  }

  getInitUserById(userId: number) {
    userResource
      .getUserById(userId)
      .then((resp) => {
        this.userInitTab = this.initData?.userTab || null;
        this.userToEdit = resp.data;
      })
      .catch(userResource.defaultErrorHandler);
  }

  selectedHeaders = [];
  headers = [
    { text: "ID", align: "start", value: "userId" },
    { text: "Username", value: "username" },
    { text: "Active", value: "isActive" },
    { text: "Admin", value: "isAdministrator" },
    { text: "Groups", value: "groups" },
    { text: "MFA", value: "mfa" },
    { text: "Last sign in", value: "lastLoginDate" },
  ];

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getMfaTypeName(type: MultiFactorAuthType) {
    return UserHelper.getMfaTypeDisplayName(type);
  }

  newUser() {
    this.userToEdit = {
      userId: 0,
      username: "",
      password: "",
      email: "",
      isActive: true,
      mfaType: MultiFactorAuthType.None,
      userGroups: [] as UserGroup[],
      isPasswordUpdateRequired: true,
    } as User;
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, this.options);
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      userResource
        .getUsersPaged(itemsPerPage, page, this.searchTerm, sortBy[0], sortDesc[0], this.cancelToken)
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData();
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData();
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: User) {
    if (!this.contextMenuEventItem) {
      this.userToEdit = Object.assign({}, item);
    }
  }

  rowClass(item: User) {
    if (!item.isActive) {
      return "cursor-default grey--text";
    } else {
      return "cursor-default";
    }
  }

  exportUserActivity(year:string) {
    window.open(`${commonHelper.apiHost}/export/user-activity?year=${year}`, "_blank");
  }
}
</script>

<style scoped></style>
