<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex justify-space-between flex-grow-1">
          <div class="d-flex flex-grow-1">
            <v-text-field
              v-model="searchTerm"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
              clearable
              style="max-width"
              v-on:input="search()"
              v-on:keypress.enter="search(true)"
            ></v-text-field>
            <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="devicesTableColumns" />
          <v-tooltip bottom :disabled="!searchTerm">
            <template v-slot:activator="{ on, attrs }">
                <span class="align-self-end ml-2 p-relative" v-bind="attrs" v-on="on">
                  <v-btn
                    small
                    icon
                    class="align-self-end"
                    @click="showFilter = !showFilter"
                    :color="showFilter ? 'primary' : undefined"
                    :disabled="loading || Boolean(searchTerm)"
                    title="Filters"
                  >
                    <v-icon v-if="searchTerm">mdi-filter-off</v-icon>
                    
                    <v-icon v-if="!searchTerm">mdi-filter-variant</v-icon>
                    <v-badge v-if="numberOfFilter && !searchTerm" transition="v-fade-transition" dot bordered offset-x="8" offset-y="-1" />
                  </v-btn>
                </span>
              </template>
              Filters ignored during search
            </v-tooltip>
          </div>

          <v-spacer class="d-none d-sm-block"></v-spacer>
          <div class="text-right align-self-end mt-2 mt-sm-0">
            <v-menu offset-y v-if="isAdmin" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" class="ml-2">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="showExport = true" link>
                  <v-list-item-title>Export devices</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" />

      <v-data-table
        dense
        :item-class="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="footerProps"
        :mobile-breakpoint="0"
        @click:row="rowClick"
        @contextmenu:row="openContenxMenu"
      >
        <template v-slot:[`item.deviceId`]="{ item }">
          <div class="no-wrap">
            {{ item.deviceId }}
            <DeviceMenu v-model="item.deviceId" :size="'small'" />
          </div>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ getDeviceTypeName(item.type) }}
        </template>
        <template v-slot:[`item.isBlocked`]="{ item }">
          <v-icon small color="green" v-if="item.isBlocked">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isDebugMode`]="{ item }">
          <v-icon small color="green" v-if="item.isDebugMode">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isUpdateAllowed`]="{ item }">
          <v-icon small color="green" v-if="item.isUpdateAllowed">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.customerId`]="{ item }">
          <span>{{ item.customerId }}</span>
          <v-btn v-if="canViewCustomers && item.customerId" @click="editCustomer(item.customerId, $event)" text icon small>
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.subscriptionEndDate`]="{ item }">
          {{ moment(item.subscriptionEndDate).format("lll") }}
        </template>
        <template v-slot:[`item.lastSeenDate`]="{ item }">
          {{ item.lastSeenDate != undefined ? moment(item.lastSeenDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" bottom color="secondary" max-width="500">
            <template v-slot:activator="{ on }">
              <div class="no-wrap" v-on="on">
                {{ item.notes.substring(0, 15) + "..." }}
              </div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>
      </v-data-table>

      <v-overlay absolute :value="loading" opacity="0" />
    </v-card>

    <EditDevice v-model="deviceToEdit" v-on:updated="reload" :deviceInitTab="deviceInitTab" />
    <EditCustomer v-model="customerToEdit" v-on:updated="reload" :initData="initData" :customerInitTab="customerInitTab" />
    <ExportDevices v-model="showExport" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import EditDevice from "@/components/devices/EditDevice.vue";
import EditCustomer from "@/components/customers/EditCustomer.vue";
import deviceResource from "@/resources/DeviceResource";
import customerResource from "@/resources/CustomerResource";
import Device from "@/types/Device";
import Customer from "@/types/Customer";
import { DeviceType } from "@/types/DeviceType";
import DeviceHelper from "@/helpers/deviceHelper";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import ExportDevices from "@/components/devices/ExportDevices.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";

@Component({
  name: "DeviceList", // name is needed for keep-alive
  components: {
    EditDevice,
    EditCustomer,
    DataTableContextMenu,
    DeviceMenu,
    ExportDevices,
    TableConfiguration,
    Filters,
  },
})
export default class DeviceList extends Vue {
  @Prop({ default: null })
  readonly initFilter!: { [key: string]: TableFilter["selected"] };

  @Prop({ default: null })
  initData!: {
    customerId?: number;
    customerTab?: string;
    deviceId?: number;
    deviceTab?: string | null;
  } | null;

  moment = moment;
  showFilter = Boolean(Object.values(this.$props.initFilter || {}).length);
  filter: TableFilter[] = [
    {
      title: "Type",
      icon: "mdi-format-list-bulleted-type",
      filterName: "type",
      searchable: false,
      selected: this.$props.initFilter?.type || [],
      itemsCallback: (search?: string) => {
        if (!search) return DeviceHelper.getDeviceFilterByType();
        return DeviceHelper.getDeviceFilterByType().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
      },
    },
    {
      title: "Blocked",
      icon: "mdi-cancel",
      filterName: "blocked",
      searchable: false,
      selected: this.$props.initFilter?.state || [],
      itemsCallback: (search?: string) => {
        const data = [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ];
        if (!search) return data;
        return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
      },
    },
    {
      title: "Allow updates",
      icon: "mdi-upload",
      filterName: "updateAllowed",
      searchable: false,
      selected: this.$props.initFilter?.updateAllowed || [],
      itemsCallback: (search?: string) => {
        const data = [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ];
        if (!search) return data;
        return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
      },
    },
  ];

  get numberOfFilter() {
    return Object.values(this.filter).reduce((acc, { selected }) => (acc += selected.length), 0);
  }

  total?: number = 0;
  items: Device[] = [];
  loading = false;

  optionsStorageKey = "devicesTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: ["deviceId"],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 15,
  };
  footerProps = {
    showFirstLastPage: true,
    "items-per-page-options": [15, 25, 50],
  };
  searchTermStorageKey = "devicesTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  cancelTokenArr: CancelTokenSource[] = [];

  deviceToEdit: Device | null = null;
  deviceInitTab: string | null = null;
  showExport: boolean = false;

  ignoreOptionsChange: boolean = false;
  @Watch("options", { deep: true })
  onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      this.getData();
    }
  }

  @Watch("customerToEdit")
  onChangeCustomerToEdit() {
    if (!this.customerToEdit) {
      this.customerInitTab = null;
      this.deviceInitTab = null;
    }
  }

  @Watch("deviceToEdit")
  onChangeDeviceToEdit() {
    if (!this.deviceToEdit) {
      this.deviceInitTab = null;
    }
  }
  selectedHeaders = [];
  get headers() {
    var headers = [
      { text: "ID", align: "start", value: "deviceId" },
      { text: "Device name", value: "deviceName" },
      { text: "Type", value: "type" },
      { text: "Customer ID", value: "customerId" },
      { text: "Blocked", value: "isBlocked" },
      { text: "Debug mode", value: "isDebugMode" },
      { text: "Allow updates", value: "isUpdateAllowed" },
      { text: "Notes", value: "notes", sortable: false },
      { text: "Created", value: "created" },
      { text: "Subscription end", value: "subscriptionEndDate" },
      { text: "Last seen", value: "lastSeenDate" },
    ];

    return headers;
  }
  get canViewCustomers() {
    return userProfileService.hasPermission(UserPermissionType.ViewCustomers);
  }
  get isAdmin() {
    return userProfileService.currentUser && userProfileService.currentUser.isAdministrator;
  }

  customerToEdit: Customer | null = null;
  customerInitTab: string | null = null;
  dataReloadTimeoutId: number | undefined = undefined;
  dataReloadIntervalSeconds = 180;
  componentActive = false;

  activated() {
    this.componentActive = true;

    if (this.initData?.customerId) {
      this.editCustomer(this.initData?.customerId, new Event("init"));
      return;
    }
    if (this.initData?.deviceId) {
      this.getDeviceById(this.initData?.deviceId, true);
    }

    // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0) {
      this.getData();
    }
  }
  deactivated() {
    this.componentActive = false;
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.getData();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getDeviceTypeName(type: DeviceType) {
    return DeviceHelper.getDeviceTypeDisplayName(type);
  }

  getDeviceById(deviceId: number, isInitData = false) {
    deviceResource
      .getDeviceById(deviceId)
      .then((resp) => {
        this.deviceToEdit = resp.data;
        if (isInitData) {
          this.deviceInitTab = this.initData?.deviceTab || null;
        }
      })
      .catch(deviceResource.defaultErrorHandler);
  }

  getDevicesByIds(deviceIds: number[]) {
    this.cancelExistingRequests();

    if (!deviceIds?.length) return;

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.ignoreOptionsChange = true;
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      const promise = Promise.all(
        deviceIds.map((id) => {
          const token = axios.CancelToken.source();
          this.cancelTokenArr.push(token);
          return deviceResource
            .getDeviceById(id, token)
            .then((resp) => resp.data)
            .catch((err) => {
              if (axios.isCancel(err)) return err;
              if (err.response.status === 404) return;
              deviceResource.defaultErrorHandler(err);
            });
        })
      );

      promise
        .then((resp) => {
          if (resp.some((v) => axios.isCancel(v))) return;
          this.items = resp.filter((v) => v) as Device[];
          this.total = undefined;
        })
        .finally(() => {
          this.loading = false;
          this.cancelTokenArr = [];
        });
    }, 10);
  }

  getSearchTermAsIds(): number[] | null {
    const dividers = [" ", ",", ";"];
    let result: number[] | null = null;

    dividers.some((divider) => {
      const termArr = this.searchTerm
        ?.split(divider)
        .filter((v: string) => v.trim())
        .map((v: string) => v.trim())
        .filter((v: string, ind: number, arr: string[]) => arr.indexOf(v) === ind);

      if (termArr?.every((v: string) => !isNaN(Number(v))) && termArr.length > 1) {
        result = termArr;
      }
      return result;
    });
    return result;
  }

  cancelExistingRequests() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.cancelToken = undefined;
    }

    // Cancel existing requests when making a search by IDs
    if (this.cancelTokenArr.length) {
      this.cancelTokenArr.forEach((t) => t?.cancel());
      this.cancelTokenArr = [];
    }
  }

  getData(resetPagination: boolean = false) {
    this.cancelExistingRequests();

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.options.page = 1;
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, this.options);
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    const deviceIds = this.getSearchTermAsIds();

    if (deviceIds) {
      if (deviceIds?.length > 300) {
        return infoMessageService.show(
          InfoMessageType.Info,
          "Too many device IDs. The maximum available number of IDs is 300."
        );
      }
      return this.getDevicesByIds(deviceIds);
    }
    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let typeFilter = undefined
      let stateFilter = undefined
      let updateAllowedFilter = undefined
      if(!this.searchTerm) {
        typeFilter = this.filter.find(({ filterName }) => filterName === "type")?.selected.map(({ value }) => value);
        stateFilter = this.filter.find(({ filterName }) => filterName === "state")?.selected.map(({ value }) => value);
        updateAllowedFilter = this.filter.find(({ filterName }) => filterName === "updateAllowed")?.selected.map(({ value }) => value);
      }

      deviceResource
        .getDevicesPaged(
          itemsPerPage,
          page,
          this.searchTerm,
          sortBy[0],
          sortDesc[0],
          typeFilter,
          stateFilter?.[1] ? undefined : stateFilter?.[0],
          updateAllowedFilter?.[1] ? undefined : updateAllowedFilter?.[0],
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(deviceResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData(true);
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: Device) {
    if (!this.contextMenuEventItem) {
      this.deviceToEdit = Object.assign({}, item);
    }
  }

  rowClass(item: Device) {
    if (item.isBlocked) {
      return "red--text cursor-default";
    } else if (item.isDebugMode) {
      return "blue--text cursor-default";
    }
    return "cursor-default";
  }

  editCustomer(customerId: number, e: Event) {
    e.stopPropagation();

    if (customerId) {
      this.loading = true;

      customerResource
        .getCustomerById(customerId)
        .then((resp) => {
          this.customerToEdit = resp.data;
          if (e.type === "init") {
            this.customerInitTab = this.initData?.customerTab || null;
          }
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    }
  }

  updateFilter(newFilter: TableFilter[]) {
    this.filter = newFilter;
    if (this.options.page > 1) {
      this.options.page = 1;
      return;
    }

    this.getData();
  }
}
</script>

<style scoped></style>
