<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <SidebarNav />
    </v-navigation-drawer>

    <v-app-bar app dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <WhatIsNewBtn />
      <AppBar />
    </v-app-bar>

    <v-main>
      <InfoMsg />
      <ChangesWarning />
      <keep-alive
        include="Dashboard,Customers,DeviceList,DeviceLocationHistory,DeviceLogs,PoiList,PoiMap,Adverts,GeohashMap"
      >
        <router-view></router-view>
      </keep-alive>
    </v-main>

    <AppUpdateNotification />
    <AuthRefresh />

    <v-overlay absolute :value="!inited" opacity="0.3" z-index="99999">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/layout/AppBar.vue";
import SidebarNav from "@/components/layout/SidebarNav.vue";
import InfoMsg from "@/components/layout/InfoMessage.vue";
import ChangesWarning from "@/components/layout/ChangesWarning.vue";
import AppUpdateNotification from "@/components/layout/AppUpdateNotification.vue";
import userProfileService from "@/services/UserProfileService";
import appConfigService from "@/services/AppConfigService";
import appStatusService from "@/services/AppStatusService";
import AuthRefresh from "@/components/common/AuthRefresh.vue";
import WhatIsNewBtn from "@/components/layout/WhatIsNewBtn.vue";

@Component({
  components: {
    AppBar,
    SidebarNav,
    InfoMsg,
    ChangesWarning,
    AppUpdateNotification,
    AuthRefresh,
    WhatIsNewBtn
  },
})
export default class AppLayout extends Vue {
  drawer: any = null;

  get inited() {
    return userProfileService.currentUser && appConfigService.inited;
  }

  mounted() {
    userProfileService.init();
    appConfigService.init();
    appStatusService.init();
  }

  destroyed() {
    appStatusService.reset();
  }
}
</script>
