<template>
  <v-menu bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" :ripple="false" :x-small="isXSmall" :small="isSmall" :large="isLarge" :x-large="isXLarge" :plain="plain">
        <v-icon :color="dark ? 'black' : ''">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list light dense class="py-0">
      <v-list-item @click="menuItemCopyId(value)">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Copy ID</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canViewDeviceLocation" @click="menuItemOpenLocationHistory(value)">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-map-marker-path</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Open in Location History</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canViewDeviceLogs" @click="menuItemOpenInLogs(value)">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-text-search</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Open in Logs</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import router from "@/router/router";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

@Component({
  components: {},
})
export default class DeviceMenu extends Vue {
  @Prop({ default: null })
  readonly value!: number;

  @Prop({ default: false })
  readonly dark!: boolean;

  @Prop({ default: "" })
  readonly size!: string;

  @Prop({ default: false })
  readonly plain!: boolean;

  get isXSmall() {
    return this.size === "x-small";
  }

  get isSmall() {
    return this.size === "small";
  }

  get isXLarge() {
    return this.size === "x-large";
  }
  
  get isLarge() {
    return this.size === "large";
  }

  get canViewDeviceLogs() {
    return userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs);
  }
  get canViewDeviceLocation() {
    return userProfileService.currentUser?.isAdministrator;
  }

  menuItemCopyId(deviceId: number) {
    navigator.clipboard.writeText(deviceId.toString()).catch((e) => {
      infoMessageService.show(InfoMessageType.Error, "Failed to copy text");
    });
  }

  menuItemOpenInLogs(deviceId: number) {
    router.push({ name: "Device Logs", query: { deviceId: deviceId.toString() } }).catch((e) => {
      if (e.message.includes("Navigation aborted")) return;;
      console.error(e);
    });
  }

  menuItemOpenLocationHistory(deviceId: number) {
    router.push({ name: "Device Location History", query: { deviceId: deviceId.toString() } }).catch((e) => {
      if (e.message.includes("Navigation aborted")) return;;
      console.error(e);
    });;
  }
}
</script>