import ResourceBase from "@/resources/ResourceBase";
import Customer from "@/types/Customer";
import Device from "@/types/Device";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import GenericResponse from "@/types/GenericResponse";
import PagedData from "@/types/PagedData";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import PagedComments from "@/types/PagedComments";
import Comment from "@/types/Comment";

class CustomerResource extends ResourceBase {
    getCustomersPaged(
        itemsPerPage: number,
        pageNumber: number,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        countryIso2?: string,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<PagedData<Customer>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc,
                countryIso2
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Customer>>("/customers", config);
    }

    addCustomer(customer: Customer, cancelToken: any | undefined = undefined): Promise<AxiosResponse<number>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.post<number>("/customers", customer, config);
    }

    updateCustomer(customer: Customer, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/customers/${customer.customerId}`, customer, config);
    }

    deleteCustomer(customerId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/customers/${customerId}`, config);
    }

    getCustomerById(customerId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<Customer>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<Customer>(`/customers/${customerId}`, config);
    }

    getCustomerChangeHistory(customerId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<EntityChangeRecord[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<EntityChangeRecord[]>(`/customers/${customerId}/change-history`, config);
    }

    getDevicesByCustomerId(customerId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<Device[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<Device[]>(`/customers/${customerId}/devices`, config);
    }

    setCustomerDevices(customerId: number, deviceIds: number[], cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/customers/${customerId}/devices`, { customerId, deviceIds }, config);
    }

    addCustomerComment(customerId: number, content: string, cancelToken?: any): Promise<AxiosResponse<Comment>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.post<Comment>(`/customers/${customerId}/comments`, { content }, config);
    }

    getCustomerComments(
        customerId: number,
        limit?: number,
        createdBefore?: Date | string,
        createdAfter?: Date | string
    ): Promise<AxiosResponse<PagedComments>> {
        const config = {
            params: { limit, createdAfter, createdBefore },
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedComments>(`/customers/${customerId}/comments`, config);
    }

    updateCustomerComment(customerId: number, comment: Comment, cancelToken?: any): Promise<AxiosResponse<Comment>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.put<Comment>(
            `/customers/${customerId}/comments/${comment.commentId}`,
            { content: comment.content },
            config
        );
    }

    deleteComment(customerId: number, commentId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`customers/${customerId}/comments/${commentId}`, config);
    }

    getCommentCount(customerId: number, cancelToken?: any ): Promise<AxiosResponse<{total: number}>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<{total: number}>(`/customers/${customerId}/comments/count`, config);
    }
}

const customerResource = new CustomerResource();
export default customerResource;
