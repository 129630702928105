import appResource from "@/resources/AppResource";
import Vue from "vue";

const state = Vue.observable({
    inited: false,
    newPoiDynamicPoiMinAllowedProximityDistance: 0,
    newPoiDynamicToStaticPoIMinAllowedProximityDistance: 0,
    updatePoiDynamicPoiMinAllowedProximityDistance: 0,
    kibanaLogsDashboardId: "",
    kibanaLogsPanelId: "",
});

class AppConfigService {
    private readonly state = state;

    get newPoiDynamicPoiMinAllowedProximityDistance() {
        return this.state.newPoiDynamicPoiMinAllowedProximityDistance;
    }
    get newPoiDynamicToStaticPoIMinAllowedProximityDistance() {
        return this.state.newPoiDynamicToStaticPoIMinAllowedProximityDistance;
    }
    get updatePoiDynamicPoiMinAllowedProximityDistance() {
        return this.state.updatePoiDynamicPoiMinAllowedProximityDistance;
    }
    get kibanaLogsDashboardId() {
        return this.state.kibanaLogsDashboardId;
    }
    get kibanaLogsPanelId() {
        return this.state.kibanaLogsPanelId;
    }
    get inited() {
        return this.state.inited;
    }

    init(): void {
        this.loadConfig();
    }

    loadConfig(): void {
        appResource
            .getConfig()
            .then(resp => {
                this.state.newPoiDynamicPoiMinAllowedProximityDistance = resp.data.newPoiDynamicPoiMinAllowedProximityDistance;
                this.state.newPoiDynamicToStaticPoIMinAllowedProximityDistance = resp.data.newPoiDynamicToStaticPoIMinAllowedProximityDistance;
                this.state.updatePoiDynamicPoiMinAllowedProximityDistance = resp.data.updatePoiDynamicPoiMinAllowedProximityDistance;
                this.state.kibanaLogsDashboardId = resp.data.kibanaLogsDashboardId;
                this.state.kibanaLogsPanelId = resp.data.kibanaLogsPanelId;

                this.state.inited = true;
            })
            .catch(appResource.defaultErrorHandler);
    }
}

const appConfigService = new AppConfigService();
export default appConfigService;