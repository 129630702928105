<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            clearable
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
          ></v-text-field>
          <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="advertsTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn v-if="canAddAdverts" small color="primary" @click="newAdvert()">
            New Ad
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        dense
        :item-class="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="footerProps"
        :mobile-breakpoint="0"
        @click:row="rowClick"
        @contextmenu:row="openContenxMenu"
        class="no-wrap-table-sm"
      >
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon small color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
      </v-data-table>

      <v-overlay absolute :value="loading" opacity="0" />
    </v-card>

    <EditAdvert v-model="advertToEdit" v-on:updated="reload" :advertInitTab="advertInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import EditAdvert from "@/components/adverts/EditAdvert.vue";
import AdvertListItem from "@/types/AdvertListItem";
import Advert from "@/types/Advert";
import advertResource from "@/resources/AdvertResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import userStorage from "@/services/UserStorageService";
import { AdvertType } from "@/types/AdvertType";
import AdvertLocation from "@/types/AdvertLocation";

@Component({
  name: "Adverts", // name is needed for keep-alive
  components: {
    EditAdvert,
    DataTableContextMenu,
    TableConfiguration
  },
})
export default class Adverts extends Vue {
  @Prop({ default: null })
  initData!: { advertTab: string; advertId: number } | null;

  moment = moment;

  total = 0;
  items: AdvertListItem[] = [];
  loading = false;
  
  optionsStorageKey = "advertsTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: ["advertId"],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 15,
  };
  footerProps = {
    "showFirstLastPage": true,
    "items-per-page-options": [15, 25, 50],
  };
  searchTermStorageKey = "advertsTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  advertToEdit: Advert | null = null;
  advertInitTab: string | null = null

  ignoreOptionsChange: boolean = false;
  @Watch("options", { deep: true })
  onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      this.getData();
    }
  }

  @Watch("advertToEdit")
  onChangeCustomerToEdit() {
    if(!this.advertToEdit) {
      this.advertInitTab = null
    }
  }

  selectedHeaders = []
  headers = [
    { text: "ID", value: "advertId" },
    { text: "Org.ID", value: "organisationId" },
    { text: "Campaign ID", value: "campaignId" },
    { text: "Promo text", value: "promoText", sortable: false },
    { text: "Active", value: "isActive" },
    { text: "Created", value: "createdDate" }
  ];

  get canAddAdverts() {
    return userProfileService.hasPermission(UserPermissionType.AddAds);
  }

  dataReloadTimeoutId: number | undefined = undefined;
  dataReloadIntervalSeconds = 180;
  componentActive = false;
  activated() {
    this.componentActive = true;

    // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0) {
      this.getData();
    }
  }
  deactivated() {
    this.componentActive = false;
  }
  
  mounted() {
    if (this.initData?.advertId) {
      this.getInitAdvertById(this.initData?.advertId);
    }
  }

  getInitAdvertById(advertId:number) {
    advertResource
      .getAdvertById(advertId)
      .then((resp) => {
        this.advertToEdit = resp.data;
        this.advertInitTab = this.initData?.advertTab || null;
      })
      .catch(advertResource.defaultErrorHandler);
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.getData();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  newAdvert() {
    this.advertToEdit = {
      advertId: 0,
      organisationId: 0,
      campaignId: 0,
      isActive: true,
      minTimeOnScreen: 5,
      maxTimeOnScreen: 15,
      advertType: AdvertType.Spot,
      activeRadius: 300,
      maximumShows: 50,
      promoText: "",
      imageDataBase64: null,
      imageWidth: 0,
      imageHeight: 0,
      advertLocation: {
        latitude: 64.466956,
        longitude: 11.492757
      } as AdvertLocation
    } as Advert;
  };

  getData(resetPagination: boolean = false) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.options.page = 1;
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, this.options);
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      advertResource
        .getAdvertsPaged(
          itemsPerPage,
          page,
          this.searchTerm,
          sortBy[0],
          sortDesc[0],
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(advertResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData(true);
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: AdvertListItem) {
    if (!this.contextMenuEventItem) {
      // load advert
      this.loading = true;
      advertResource.getAdvertById(item.advertId)
        .then(resp => {
          this.advertToEdit = resp.data;
        })
        .catch(advertResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    }
  }

  rowClass(item: AdvertListItem) {
    return "cursor-default";
  }
}
</script>

<style scoped>
</style>