<template>
  <div class="api-key-resp-status-chart-wrap">
    <div v-if="showChart" class="api-key-resp-status-chart">
      <v-overlay v-if="loading" absolute class="progress" opacity="0" z-index="101">
        <v-progress-linear indeterminate absolute />
      </v-overlay>
      <v-chart ref="chart" :option="option" autoresize :class="{ 'opacity-50': loading }" />
    </div>
    <div v-else class="text-center body-2 text--disabled mt-2">No data available</div>
    <div class="api-key-chart-total text-caption mt-1">
      <div v-for="({ name, value }, index) in total" :key="index" class="mx-3 text-no-wrap">
        <v-icon small class="mb-1" :color="getIconByStatus(name).color">{{ getIconByStatus(name).icon }}</v-icon>
        {{ `${name}: ` }} <span class="font-weight-light"> {{ value }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { use, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, LineSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import axios, { CancelTokenSource } from "axios";
import apiKeysResource from "@/resources/ApiKeysResource";
import ApiKey from "@/types/ApiKey";
import moment from "moment";

use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<
  LineSeriesOption | TitleComponentOption | TooltipComponentOption | LegendComponentOption | GridComponentOption
>;

@Component({ components: { VChart } })
export default class ApiKeyRespStatusChart extends Vue {
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  moment = moment;
  total: { name: string; value: number }[] = [];
  showChart: boolean = true;

  option: ECOption = {
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },

    grid: { top: 50, bottom: 30 },

    legend: {
      top: 20,
      textStyle: { color: this.textColor },
      icon: "circle",
    },

    tooltip: {
      trigger: "axis",
      confine: true,
      formatter: (args: any) => {
        let tooltip = `<p>${moment(args[0].axisValue).format("D MMM, YYYY")}</p> `;
        let total = 0;

        args.forEach(({ marker, seriesName, value }: any) => {
          total += value[1] || 0;
          tooltip += `<div class="w-100 d-flex justify-space-between">
                        <span class='text-left'>${marker} ${seriesName}</span>
                        <b class="ml-5">${value[1] || 0}</b>
                      </div>`;
        });

        tooltip += `
          <div class="w-100 d-flex justify-space-between mt-4">
            <span class='text-left'>Total:</span>
            <b class="ml-5">${total}</b>
          </div>`;

        return tooltip;
      },
      axisPointer: {
        type: "line",
      },
    },

    xAxis: {
      type: "time",
      axisLine: {
        lineStyle: {
          color: this.secondaryColor,
        },
      },
      axisLabel: {
        color: this.textColor,
      },
      splitLine: {
        show: true,
        lineStyle: { color: this.secondaryColor },
      },
    },

    yAxis: {
      type: "value",
      alignTicks: true,
      minInterval: 1,
      axisLabel: {
        color: this.textColor,
        formatter: (val: number) => (val > 10000 ? `${val / 1000}K` : val.toString()),
      },
      splitLine: {
        show: true,
        lineStyle: { color: this.secondaryColor },
      },
    },

    series: [],
    animation: false,
  };

  @Prop()
  readonly value!: ApiKey | null;

  @Prop()
  readonly period!: number;

  get textColor() {
    return this.$vuetify.theme.dark ? "#fff" : "#333";
  }
  get barTextColor() {
    return this.$vuetify.theme.dark ? "#fedd10" : "#333";
  }
  get secondaryColor() {
    return this.$vuetify.theme.dark ? "#333" : "#ccc";
  }

  @Watch("period")
  onChangePeriod() {
    if (this.period) this.getData();
  }

  mounted() {
    this.getData();
  }

  destroyed() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  getColorByStatus(status?: string) {
    if (!status) return;

    const type = status?.substring(0, 1);
    const subtype = Number(status?.substring(1));
    if (type === "2") {
      return `#91${(255 - 12 * subtype).toString(16)}75`;
    } else if (type === "4") {
      return `#fa${(255 - 15 * subtype).toString(16)}57`;
    } else if (type === "5") {
      return `#${(255 - 12 * subtype).toString(16)}6666`;
    }
    return;
  }

  getIconByStatus(status: string = "") {
    const color = this.getColorByStatus(status);
    switch (status[0]) {
      case "2":
        return { icon: "mdi-check-circle", color: color };
      case "4":
        return { icon: "mdi-alert-outline", color: color };
      case "5":
        return { icon: "mdi-alert", color: color };
      default:
        return { icon: "mdi-sigma" };
    }
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      if (!this.value) return;

      apiKeysResource
        .getDailyUsageByStatusCode(this.value.apiKeyId, this.period, this.cancelToken)
        .then((resp) => {
          const data = resp.data;
     
          this.showChart = data.length > 0;

          const legend = [...new Set(data?.flatMap((item) => Object.keys(item.values)))].sort(
            (a, b) => Number(a) - Number(b)
          );

          const total: { [key: string]: number } = { sum: 0 };
          data.forEach((item) => {
            legend.forEach((v) => {
              total.sum += item.values[v] || 0;

              if (!total[v]) total[v] = 0;
              total[v] += item.values[v] || 0;
            });
          });

          this.total = [{ name: "Total", value: total.sum }, ...legend.map((v) => ({ name: v, value: total[v] }))];
          this.option.legend = { ...(this.option.legend || {}), data: legend };

          this.option.series = legend.map((name) => ({
            name,
            type: "line",
            showSymbol: false,
            stack: "Total",
            sampling: "average",
            dimensions: [{ type: "time" }, { type: "number" }],
            areaStyle: { color: this.getColorByStatus(name) },
            lineStyle: { color: this.getColorByStatus(name) },
            itemStyle: { color: this.getColorByStatus(name) },
            data: data.map(({ date, values }) => [date, values[name]]),
          }));
        })
        .catch(apiKeysResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}
</script>

<style scoped>
.api-key-resp-status-chart-wrap {
  width: 100%;
  flex: 1;
}
.api-key-resp-status-chart {
  height: 500px;
  max-height: 70dvh;
  width: 100%;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
}

.progress >>> .v-overlay__content {
  width: 25%;
  margin-bottom: 55px;
}

.api-key-chart-total {
  display: flex;
  justify-content: center;
  border-top: 1px dashed #666;
  flex-wrap: wrap;
  padding-top: 5px;
}
</style>
