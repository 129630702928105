import { render, staticRenderFns } from "./DeviceStats.vue?vue&type=template&id=28bba54c&scoped=true&"
import script from "./DeviceStats.vue?vue&type=script&lang=ts&"
export * from "./DeviceStats.vue?vue&type=script&lang=ts&"
import style0 from "./DeviceStats.vue?vue&type=style&index=0&id=28bba54c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bba54c",
  null
  
)

export default component.exports