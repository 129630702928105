<template>
  <div>
    <div class="subtitle-1 mb-1">Advert Stats</div>

    <v-progress-linear v-if="loading" indeterminate absolute></v-progress-linear>

    <div class="d-block d-sm-flex align-center text-right mt-5">
      <v-select 
        attach
        v-model="selectedDateRangeOption"
        :items="dateRangeOptions"
        outlined
        dense
        label="Date range"
        class="flex-grow-0"
        style="min-width:150px"
        hide-details
      />
      <div v-if="selectedDateRangeOption === 'define'" class="d-block d-sm-flex align-center">
        <v-text-field
          v-model="from"
          outlined
          dense
          label="From"
          class="flex-grow-0 ml-0 ml-md-4 mt-2 mt-md-0"
          style="min-width:150px"
          hide-details
        />
        <span class="d-none d-md-inline px-1">-</span>
        <v-text-field
          v-model="to"
          outlined
          dense
          label="To"
          class="flex-grow-0 mt-2 mt-md-0"
          style="min-width:150px"
          hide-details
        />
      </div>
      <v-btn 
        color="primary"
        class="ml-0 ml-md-4 mt-2 mt-md-0 "
        :loading="loading"
        @click="getData()"
      >Update</v-btn>
    </div>

    <table v-if="advertStats" class="subtitle-2 mt-4 info-table">
      <tr>
        <td class="grey--text">Unique views:</td>
        <td>{{ advertStats.uniqueViews }}</td>
      </tr>
      <tr>
        <td class="grey--text">Total views:</td>
        <td>{{ advertStats.totalViews }}</td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import advertResource from "@/resources/AdvertResource";
import AdvertStats from "@/types/AdvertStats";

@Component({
  components: {},
})
export default class AdvertStatsView extends Vue {
  moment = moment;

  @Prop()
  readonly advertId!: number | null;

  @Watch("advertId", { immediate: true })
  onValueChanged() {
    if (this.advertId !== null) {
      this.getData();
    } else {
      this.advertStats = null;
    }
  }

  loading = false;

  advertStats: null | AdvertStats = null;
  from = moment("2022-01-01").format("YYYY-MM-DD HH:mm");
  to = moment().add(1, "days").startOf("day").format("YYYY-MM-DD HH:mm");

  selectedDateRangeOption = "all";
  dateRangeOptions = [
    { text: "From start", value: "all" },
    { text: "Define", value: "define" }
  ];

  getData() {
    if (!this.advertId) {
      return;
    }

    this.loading = true;

    var fromDate: Date | undefined = undefined;
    var toDate: Date | undefined = undefined;
    if (this.selectedDateRangeOption === "define") {
      if (this.from) {
        fromDate = moment(this.from).utc().toDate();
      }
      if (this.to) {
        toDate = moment(this.to).utc().toDate();
      }
    }

    advertResource
      .getAdvertStats(this.advertId, fromDate, toDate)
      .then((resp) => {
        this.advertStats = resp.data;
      })
      .catch(advertResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>
.info-table td:first-child {
  padding-right: 2rem;
}
</style>