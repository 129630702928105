<template>
  <form @submit.prevent="verify" class="form-auth" method="post">
    <h3 class="form-auth-heading">
      <img src="/favicon.png" alt="Safedrive" />Safedrive
    </h3>

    <p v-if="isEmailVerification">
      Please enter the code we sent to your email.
    </p>
    <p v-else>Please enter the code from the SMS we just sent you.</p>

    <p v-if="showLockoutWarning" class="red--text">
      This is your last attempt. Your account will be locked if you fail to
      provide correct verification code.
    </p>

    <div class="input-field">
      <v-text-field
        v-model="code"
        type="text"
        label="Code"
        required
        autocomplete="off"
      />
    </div>
    <v-btn
      class="submit-button mb-5"
      type="submit"
      :loading="loading"
      :disabled="loading"
    >
      Verify
    </v-btn>

    <p v-if="isEmailVerification" class="link-container">
      Didn't get the code?<br />Check SPAM folder or try
      <a class="link-btn" @click.prevent="resendCode">sending again</a>.
    </p>
    <p v-else class="link-container">
      Didn't get the code? Try
      <a class="link-btn" @click.prevent="resendCode">sending again</a>.
    </p>

    <p class="link-container">
      <router-link class="link-btn" to="/login">Back to login page</router-link>
    </p>
  </form>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import authService from "@/services/AuthService";
import { MfaType } from "@/types/MfaType";
import authResource from "@/resources/AuthResource";
import router from "@/router/router";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  name: "Verification", // name is needed for keep-alive
  components: {},
})
export default class Verification extends Vue {
  showLockoutWarning = false;
  loading = false;
  isEmailVerification = Number(this.$route.query.type) === MfaType.email;
  code = "";

  username: string = "";

  mounted() {
    const authInfo = authService.authInfo;
    if (!authInfo.username) {
      router.push("/login");
    } else {
      this.username = authInfo.username;
    }
  }

  resendCode() {
    this.loading = true;
    authResource
      .resendCode(this.username)
      .then(() => {
        infoMessageService.show(
          InfoMessageType.Success,
          "Verification code sent"
        );
      })
      .catch(authResource.defaultErrorHandler)
      .finally(() => (this.loading = false));
  }

  verify() {
    if (!this.code) return;

    this.loading = true;
    authResource
      .verify(this.code, this.username)
      .then((resp) => {
        if (resp.data.token) {
          // Sign in
          authService.signIn(resp.data.token, resp.data.expires!);
        } else {
          // Wrong code
          infoMessageService.show(InfoMessageType.Error, "Oops, wrong code!");
          if (resp.data.isLastAttempt) {
            this.showLockoutWarning = true;
          }
        }
      })
      .catch(authResource.defaultErrorHandler)
      .finally(() => (this.loading = false));
  }
}
</script>

<style scoped></style>
