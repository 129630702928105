<template>
  <v-container>
    <div v-if="issue" class="issue-container flex-column flex-md-row-reverse">
      <!-- SIDEBAR SECTION -->
      <div class="mt-0 mb-3 ml-md-8 mt-md-12">
        <IssueSideBar :issue="issue" @remove="changesControl.deactivate()" />
      </div>

      <!-- MAIN SECTION -->
      <v-form ref="issueForm" class="issue-form">
        <!-- BACK (desktop only) -->
        <div class="mb-3 d-none d-md-block">
          <v-btn icon color="primary" @click="back" :disabled="loading">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </div>

        <!-- ISSUE -->
        <v-card :loading="loading" :disabled="loading">
          <v-card-text>
            <v-sheet class="d-flex align-baseline mb-1">
              <div class="issue-title font-weight-normal mr-2">{{ issue.issueId }}</div>
              <v-textarea
                :readonly="!canEditIssues"
                :rules="rules"
                v-model="issue.name"
                @input="() => changesControl.activate()"
                placeholder="Issue title"
                class="issue-title w-hover"
                dense
                solo
                flat
                :hide-details="!!issue.name.trim()"
                single-line
                auto-grow
                rows="1"
                maxlength="120"
                no-resize
              ></v-textarea>
              <v-btn color="primary" class="ml-4" icon small title="Copy id and name to clipboard" @click="copyToClipboard">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-sheet>

            <TagsField
              :tags="tags"
              @update="updateTags"
              :canCreate="canAddTags"
              :disabled="!canEditIssues"
              :type="tagType"
              orderBy="issue_count"
              :orderByDesc="true"
            />

            <div class="subtitle-2 mt-5">Description</div>
            <v-sheet class="mt-1">
              <RichText
                v-model="issue.description"
                @input="() => changesControl.activate()"
                :disabled="!canEditIssues"
                :small="true"
                placeholder="Write issue description here..."
              />
            </v-sheet>
            <Attachments
              ref="attachmentsRef"
              isActionByCommand="true"
              :files="files"
              :targetType="targetType"
              :targetId="issueId"
              v-on:update="updateFiles"
              v-on:updateLocalFiles="updateLocalFiles"
              :disabled="!canEditIssues"
            />

            <div class="text-right mt-4">
              <v-btn
                v-if="canEditIssues"
                color="primary"
                @click="submit"
                class="ml-4"
                :loading="loading"
                :disabled="loading || !changesControl.data || !changesControl.data.isChanged || !isReadyForm"
                small
                >Save</v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <!-- COMMENTS -->
        <div class="mt-6">
          <IssueComments />
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import Issue from "@/types/Issue";
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import Tag from "@/types/Tag";
import { TagType } from "@/types/TagType";
import { UserPermissionType } from "@/types/UserPermissionType";
import PropEditor from "@/components/layout/PropEditor.vue";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import ChangeManager from "@/services/ChangeManager";
import TagsField from "@/components/common/TagsField.vue";
import IssueComments from "@/components/issues/IssueComments.vue";
import IssueSideBar from "@/components/issues/IssueSideBar.vue";

@Component({
  components: {
    RichText,
    PropEditor,
    Attachments,
    IssueComments,
    IssueSideBar,
    TagsField,
  },
})
export default class IssueComponent extends Vue {
  moment = moment;
  targetType = AttachmentTargetType.Issue;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  issueId = Number(this.$route.params.id);
  issue: Issue | null = null;
  tagType = TagType.Issue;
  localFiles: File[] = [];
  changesControl = new ChangeManager();

  get canAddIssues() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }
  get canEditIssues() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues);
  }

  get canAddTags() {
    return userProfileService.hasPermission(UserPermissionType.ViewIssues);
  }

  get files() {
    return this.issue?.attachments || [];
  }
  get tags() {
    return this.issue?.tags || [];
  }

  get isReadyForm() {
    // Validate form
    return (this.$refs.issueForm as Vue & { validate: () => boolean }).validate();
  }

  @Watch("issueId", { immediate: true })
  onIssueIdChanged() {
    this.getIssueData();
  }

  rules = [(v: string) => !!v.trim() || "Title is required"];

  back() {
    this.$router.back();
  }

  getIssueData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    if (!this.issueId) {
      return;
    }

    this.loading = true;
    this.cancelToken = axios.CancelToken.source();
    issueResource
      .getIssueById(this.issueId, this.cancelToken)
      .then((resp) => {
        const issue = resp.data;
        this.issue = issue;
        this.changesControl.init({
          target: `issue_${issue?.issueId}`,
          onSave: this.submit,
          message: "You have unsaved Issue changes.",
          isChanged: false,
        });
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.cancelToken = undefined;
      });
  }

  updateLocalFiles(files: File[]) {
    this.localFiles = files;
    this.changesControl.activate();
  }

  updateFiles(newFiles: AttachedFile[]) {
    if (this.issue) {
      this.issue.attachments = newFiles;
      this.changesControl.activate();
    }
  }
  submit() {
    if (!this.issue) {
      return;
    }
    if (!this.canEditIssues) {
      return;
    }

    if (this.isReadyForm) {
      this.loading = true;
      issueResource
        .updateIssue(this.issue)
        .then(() => (this.$refs.attachmentsRef as Attachments)?.uploadLocalFiles())
        .then(() => (this.$refs.attachmentsRef as Attachments)?.removeFiles())
        .then(() => {
          infoMessageService.show(InfoMessageType.Success, "Issue information updated");
          this.changesControl.deactivate();
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    }
  }

  updateTags(tags: Tag[]) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
    if (!this.canEditIssues || !tags || !this.issue) {
      return;
    }
    this.changesControl.activate();
    this.issue.tags = tags;
  }

  copyToClipboard() {
    if (this.issue) {
      navigator.clipboard
        .writeText(`${this.issue.issueId}: ${this.issue.name}`)
        .then(() => {
          infoMessageService.show(InfoMessageType.Success, "Copied to clipboard");
        })
        .catch((e) => {
          infoMessageService.show(InfoMessageType.Error, "Failed to copy content");
        });
    }
  }
}
</script>

<style scoped>
.issue-container {
  display: flex;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  overflow-x: visible;
}

.issue-form {
  width: 100%;
  max-width: 1000px;
}

.issue-title {
  font-weight: 500;
  font-size: 1.2rem;
}
</style>
