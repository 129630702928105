<template>
  <v-combobox
    v-model="tagModel"
    :items="suggestion"
    :hide-no-data="!search && !creating"
    :loading="loading || creating || isProcessing"
    :disabled="creating || disabled"
    item-text="name"
    multiple
    :search-input.sync="search"
    auto-select-first
    ref="tags"
    hide-details
    solo
    flat
    dense
    class="w-hover"
    prepend-icon="mdi-tag-multiple"
    label="No tags selected"
    @focus="onFocus"
  >
    <template v-slot:no-data>
      <v-list-item v-if="creating" class="justify-center primary--text"
        >...Creating</v-list-item
      >
      <v-list-item v-else class="justify-center">Searching...</v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, index, selected }">
      <v-chip
        small
        v-bind="attrs"
        :input-value="selected"
        :close="!disabled"
        @click:close="removeTags(index)"
        class="my-1"
      >
        {{ item.name }}
      </v-chip>
    </template>

    <template v-slot:item="{ item }">
      <span v-if="item.tagId">{{ item.name }}</span>
      <v-container v-else-if="item.name" class="text-center w-100">
        <span class="subheading mr-2">Create Tag</span>
        <v-chip label small> {{ item.name }}</v-chip>
      </v-container>
    </template>
  </v-combobox>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import Tag from "@/types/Tag";
import { TagType } from "@/types/TagType";
import tagResource from "@/resources/TagResource";

@Component({
  components: {},
})
export default class TagsField extends Vue {
  @Prop()
  readonly tags!: Tag[] | undefined;

  @Prop({ default: TagType.Default })
  readonly type!: number;

  @Prop({ default: undefined})
  readonly orderBy!: string | undefined;

  @Prop({ default: undefined})
  readonly orderByDesc!: boolean | undefined;

  @Prop({ default: false })
  readonly deleting!: boolean;

  @Prop({ default: false })
  readonly canCreate!: boolean;

  @Prop({ default: false })
  readonly enableCreateOnServer!: boolean;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: false })
  readonly isProcessing!: boolean;

  cancelToken: CancelTokenSource | undefined = undefined;
  tagModel: Tag[] = [];
  loading = false;
  creating = false;
  search: string | null = null;
  searchThrottleTimer = 0;
  suggestion: Tag[] = [];

  @Watch("tags", { deep: true, immediate: true })
  onChangeTags() {
    this.tagModel = [...(this.tags || [])] ;
  }

  @Watch("tagModel")
  onChangeModelTags(val: Tag[], prev: Tag[]) {
    if (val.length === prev.length) return;

    this.suggestion = this.suggestion.filter((v) => v.tagId);
    //Normalize data. When the enter key is pressed, added a string to the combobox
    this.tagModel = val.map((tag) =>
      typeof tag === "string" ? { tagId: 0, name: tag, type: this.type } : tag
    );
 
    const newTag = val.find((v) => v.name && !v.tagId);
    if (!this.enableCreateOnServer) {
      this.$emit("update", this.tagModel);
      if (this.suggestion.length === 0) {
        this.getData();
      }
    } else {
      //Add new tag after upload to database
      this.tagModel = val.filter((v) => v.tagId);
      if (newTag) {
        this.createTag(newTag);
      }

      if (!newTag && this.tags?.length !== this.tagModel.length) {
   
        this.$emit("update", this.tagModel);
        if (this.suggestion.length === 0) {
          this.getData();
        }
      }
    }
  }

  @Watch("search")
  onSearchChanged() {
    if (this.creating || !this.search) return;
    this.suggestion = this.suggestion.filter((v) => v.tagId);
    this.clearSearchTimeout();
    this.searchThrottleTimer = setTimeout(() => {
      this.getData();
    }, 1000);
  }

  onFocus() {
    // Initial tags load on focus
    if (this.creating) return;
    if (this.suggestion?.length === 0) {
      this.getData();
    }
  }

  clearSearchTimeout() {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }
  }
  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finally() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const searchName = this.search === null ? undefined : this.search;
      const typeOf = this.type ? [this.type] : undefined;
      tagResource
        .getTagsPaged(10, 1, searchName, typeOf, this.orderBy, this.orderByDesc, this.cancelToken)
        .then((resp) => {
          this.suggestion = resp.data.items;

          if (!this.canCreate || !searchName) return;
          const newTag = this.tagModel.find((v) => v.name.toLowerCase() === searchName?.toLowerCase());
          const isCreateOption = this.suggestion.every(
            (v) => v.name.toLowerCase() !== searchName?.toLowerCase()
          );
          if (isCreateOption && !newTag) {
            this.suggestion.push({ tagId: 0, name: searchName, type: this.type });
          }
        })
        .catch(tagResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  createTag(newTag: Tag) {
    if (!this.canCreate || !newTag) {
      return;
    }

    this.suggestion = [];
    this.creating = true;
    this.clearSearchTimeout();
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    tagResource
      .addTag(newTag)
      .then((resp) => this.tagModel.push(resp.data))
      .catch(tagResource.defaultErrorHandler)
      .finally(() => {
        this.creating = false;
        this.$nextTick(() => {
          (this.$refs.tags as HTMLInputElement).focus();
        });
      });
  }

  removeTags(index: number) {
    this.tagModel = [...this.tagModel.slice(0, index), ...this.tagModel.slice(index+1)]
  }
}
</script>

<style scoped></style>
