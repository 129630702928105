<template>
  <div>
    <div class="d-flex">
      <v-autocomplete
        class="customer-select"
        attach
        v-model="selectedItem"
        @input="customerChanged"
        @blur="onBlur"
        :items="customers"
        :loading="loading"
        :search-input.sync="searchTerm"
        :clearable="canEdit"
        no-filter
        return-object
        item-value="customerId"
        placeholder="Start typing to search..."
        dense
        :outlined="canEdit"
        :solo="!canEdit"
        :append-icon="!canEdit ? null : undefined"
        :flat="!canEdit"
        :readonly="!canEdit"
        :disabled="disabled"
        hide-details
      >
        <template v-slot:selection="{ item }">
          <span v-if="!searchTerm">
            <span>{{ `${item.firstName} ${item.lastName}` }}</span>
          </span>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <div>{{ `${item.firstName} ${item.lastName}` }}</div>
            <div class="caption">
              <span class="mr-3">ID {{ item.customerId }} </span>
              <span class="mr-3 icon-label">
                <v-icon class="mr-1" small>mdi-phone-outline</v-icon>
                <span>{{ item.phoneNumber }}</span>
              </span>
              <span v-if="item.companyName" class="icon-label">
                <v-icon class="mr-1" small>mdi-briefcase-outline</v-icon>
                <span>{{ item.companyName }}</span>
              </span>
            </div>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-btn
        v-if="canEdit"
        icon
        class="ml-2"
        :disabled="initCustomerId === value"
        @click="undo"
        title="Undo"
      >
        <v-icon>mdi-arrow-u-left-top</v-icon>
      </v-btn>
    </div>
    <div class="caption ml-3 mt-1">
      <div v-if="selectedItem">
        <span class="mr-3">ID {{ selectedItem.customerId }} </span>
        <span class="mr-3 icon-label">
          <v-icon class="mr-1" small>mdi-phone-outline</v-icon>
          <span>{{ selectedItem.phoneNumber }}</span>
        </span>
        <span v-if="selectedItem.companyName" class="icon-label">
          <v-icon class="mr-1" small>mdi-briefcase-outline</v-icon>
          <span>{{ selectedItem.companyName }}</span>
        </span>
      </div>
      <div v-else>No customer linked to device</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import customerResource from "@/resources/CustomerResource";
import Customer from "@/types/Customer";
import axios, { CancelTokenSource } from "axios";

@Component({})
export default class CustomerSelect extends Vue {
  loading = false;
  cancelToken: CancelTokenSource | undefined = undefined;
  selectedItem: Customer | null = null;
  customers: Customer[] = [];
  searchTerm: string | null = null;
  searchThrottleTimer = 0;
  disabled = false;
  initCustomerId: number | null = null;

  @Prop({ default: null })
  readonly value!: number | null;

  @Prop({ default: true })
  readonly canEdit!: boolean;

  @Watch("value", { immediate: true })
  onValueChanged() {
    if (!this.value) {
      this.selectedItem = null;
    } else if (this.value && this.value !== this.selectedItem?.customerId) {
      this.getCustomerById();
    }
  }

  @Watch("searchTerm")
  onSearchTermChanged(newValue: any, oldValue: any) {
    if (!newValue) return;

    if (oldValue !== newValue) {
      if (this.searchThrottleTimer) {
        clearTimeout(this.searchThrottleTimer);
        this.searchThrottleTimer = 0;
      }
      this.searchThrottleTimer = setTimeout(() => {
        this.getCustomers();
      }, 1000);
    }
  }

  mounted() {
    this.initCustomerId = this.value;
  }

  customerChanged() {
    this.searchTerm = null;
    this.$emit("input", this.selectedItem?.customerId);
  }

  onBlur() {
    this.searchTerm = null;
  }

  getCustomerById() {
    if (this.value) {
      this.loading = true;
      this.disabled = true;
      customerResource
        .getCustomerById(this.value)
        .then((resp) => {
          this.customers = [resp.data];
          this.selectedItem = resp.data;
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.disabled = false;
        });
    }
  }

  getCustomers() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      customerResource
        .getCustomersPaged(
          10,
          1,
          this.searchTerm ?? undefined,
          "customerId",
          false,
          undefined,
          this.cancelToken
        )
        .then(async (resp) => {
          // Try to get customer by exact ID
          const customerById = await this.tryGetCustomerById(this.searchTerm, this.cancelToken);
          
          const customers: Customer[] = [];
          if (this.selectedItem) {
            customers.push(this.selectedItem);
          }
          if (customerById) {
            customers.push(customerById);
          }
          customers.push(...resp.data.items);

          this.customers = customers;
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  undo() {
    this.$emit("input", this.initCustomerId);
  }

  async tryGetCustomerById(searchTerm: string | null, cancel: CancelTokenSource | undefined) : Promise<Customer | null> {
    if (!searchTerm)
      return null;

    if (/^\d+$/.test(searchTerm)) {
      return new Promise((resolve, reject) => {
        customerResource.getCustomerById(parseInt(searchTerm), cancel)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => resolve(null));
      });
    }

    return null;
  }
}
</script>

<style scoped>
.customer-select >>> .v-text-field__details {
  position: static;
  margin-bottom: 0;
  padding-left: 0;
}

.icon-label > * {
  vertical-align: middle;
}
</style>
