<template>
  <div class="issue-current-devices-container">
    <div class="subtitle-2">Affected devices</div>
    <div class="d-flex flex-grow-1">
      <v-text-field
        v-model="searchTerm"
        append-icon="mdi-magnify"
        label="Search"
        hide-details
        clearable
        v-on:input="search()"
        v-on:keypress.enter="search(true)"
      ></v-text-field>
      <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <div class="mt-2 mb-3">
      <v-chip
        class="mr-2"
        v-for="item in issueDeviceStatuses"
        :key="item.value"
        :outlined ="!activeFilters.includes(item.value)"
        :color="IssueHelper.getDeviceStatusColor(item.value, true)"
        :text-color="$vuetify.theme.dark || activeFilters.includes(item.value) ? 'white' : 'secondary-darken3'"
        pill
        @click="changeFilter(item.value)"
        small
      >
        {{ `${item.text}: ${getStatusCount(item.value)}` }}
      </v-chip>
    </div>
    <v-data-table
      item-key="deviceId"
      dense
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <template v-slot:[`item.deviceId`]="{ item }">
        <div class="no-wrap">
          {{ item.device.deviceId }}
          <DeviceMenu v-model="item.device.deviceId" :size="'small'" />
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getDeviceTypeName(item.device.type) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-select
          v-model="item.status"
          :items="issueDeviceStatuses"
          dense
          solo
          flat
          background-color="transparent"
          hide-details
          class="status-select"
          item-color="grey"
          :readonly="!canEditIssues"
          :disabled="!canEditIssues"
          @input.capture="newStatus => { setDeviceStatus(newStatus, item) }"
        >
          <template slot="selection" slot-scope="data">
            <span class="device-status" :class="data.item.textColor">{{ data.item.text }}</span>
          </template>
          <template slot="item" slot-scope="data">
            <span class="device-status" :class="data.item.textColor">{{ data.item.text }}</span>
          </template>
        </v-select>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="canViewDevices" @click="deviceToEdit={...item.device}" icon title="Open">
          <v-icon small>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          v-if="canEditIssues"
          :loading="deletingDeviceId.includes(item.device.deviceId)"
          @click="removeDeviceConfirm(item.device)"
          icon
          title="Remove from affected devices"
        >
          <v-icon small> mdi-close-thick </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-overlay absolute :value="loading" opacity="0" />
    <EditDevice v-model="deviceToEdit" v-on:updated="reload" :hasUrlUpdate="false" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import DeviceHelper from "@/helpers/deviceHelper";
import IssueDeviceListItem from "@/types/IssueDeviceListItem";
import { UserPermissionType } from "@/types/UserPermissionType";
import Issue from "@/types/Issue";
import { DeviceType } from "@/types/DeviceType";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import Device from '@/types/Device'
import userProfileService from "@/services/UserProfileService";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import EditDevice from "@/components/devices/EditDevice.vue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";

@Component({
  components: { DeviceMenu, EditDevice },
})
export default class IssueCurrentDevices extends Vue {
  @Prop({default: []})
  readonly statusSummary!: { status: IssueDeviceStatus; count: number }[]

  @Prop()
  readonly value!: Issue;

  @Prop()
  readonly forceReload!: object;
  
  total = 0;
  loading = false;
  deletingDeviceId: number[] = [];
  optionsStorageKey = "issueDevicesTable";
  options = {
    sortBy: [],
    sortDesc: [],
    page: 1,
    itemsPerPage: 15,
  };
  footerProps = {
    showFirstLastPage: true,
    "items-per-page-options": [15, 25, 50],
  };
  searchTerm = "";
  items: IssueDeviceListItem[] = [];
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  ignoreOptionsChange: boolean = false;
  deviceToEdit: Device | null = null;
  cancelDeviceStatusToken: CancelTokenSource | undefined = undefined;
  IssueHelper = IssueHelper

  @Watch("options", { deep: true })
  onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      this.getIssueDevices();
    }
  }

  @Watch("forceReload")
  onForceReload() {
    this.getIssueDevices(true);
  }

  get canEditIssues() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues);
  }
  get canViewDevices() {
    return userProfileService.hasPermission(UserPermissionType.ViewDevices);
  }

  get headers() {
    const mainHeaders: any = [
      { text: "ID", align: "start", value: "deviceId", sortable: true },
      { text: "Device name", value: "device.deviceName", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Status", value: "status", sortable: true, },
    ];
    if (this.canEditIssues || this.canViewDevices) {
      mainHeaders.push({ text: "", value: "actions", align: "end", cellClass:'actions', sortable: false });
    }
    return mainHeaders;
  }

  issueDeviceStatuses = [
    {
      text: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.New),
      value: IssueDeviceStatus.New,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.New),
    },
    {
      text: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Active),
      value: IssueDeviceStatus.Active,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Active),
    },
    {
      text: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Resolved),
      value: IssueDeviceStatus.Resolved,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Resolved),
    },
  ];

  activeFilters: IssueDeviceStatus[] = []
  getDeviceTypeName(type: DeviceType) {
    return DeviceHelper.getDeviceTypeDisplayName(type);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getIssueDevices(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getIssueDevices(true);
      }, 1000);
    }
  }

  changeFilter(status: IssueDeviceStatus) {
    const ind = this.activeFilters.findIndex(value => value === status)
    if(ind !== -1) {
      this.activeFilters.splice(ind, 1)
    }
    if(ind === -1) {
      this.activeFilters= [status]
    }
    this.reload()
  }
  reload() {
    this.getIssueDevices();
  }

  getIssueDevices(resetPagination: boolean = false) {
    if (!this.value) {
      return;
    }
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.options.page = 1;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (!this.value) {
        return;
      }
      issueResource
        .getDevicesByIssuePaged(
          this.value.issueId,
          itemsPerPage,
          page,
          this.searchTerm,
          sortBy[0],
          sortDesc[0],
          this.activeFilters,
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  removeDeviceConfirm(device: Device) {
    if (!this.canEditIssues || !device) {
      return;
    }

    this.$confirm
      .show(`Remove device ID ${device.deviceId} from affected devices?`, {
        width: 360,
      })
      .then((confirmed) => {
        if (confirmed) {
          this.removeDevice(device);
        }
      });
  }

  removeDevice(device: Device) {
    if (!this.canEditIssues || this.value == null) {
      return;
    }

    const deviceId = device.deviceId;
    this.deletingDeviceId.push(deviceId);
    issueResource
      .removeDevice(this.value.issueId, deviceId)
      .then(() => {
        this.items = this.items.filter((v) => v.device.deviceId !== deviceId);
        this.$emit("remove");
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.deletingDeviceId = this.deletingDeviceId.filter((v) => v !== deviceId);
      });
  }

  setDeviceStatus(status:IssueDeviceStatus,  deviceItem:IssueDeviceListItem) {
    const previousStatus = deviceItem.status
    // Cancel existing request
    if (this.cancelDeviceStatusToken) {
      this.cancelDeviceStatusToken.cancel();
    }

    if (!this.canEditIssues || !deviceItem) {
      return;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.cancelDeviceStatusToken = axios.CancelToken.source();
      issueResource
        .updateIssueDeviceStatus(this.value.issueId, deviceItem.device.deviceId, status, this.cancelDeviceStatusToken)
        .then(()=> {
          this.$emit("changeStatus");
        })
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.items = this.items.map((item) => {
            if(item.device.deviceId === deviceItem.device.deviceId) {
              return {...item, status: previousStatus}
            }
            return item
          })
        })
        .finally(() => {
          this.cancelDeviceStatusToken = undefined;
        });
    }, 10);
  }

  getStatusCount(status:IssueDeviceStatus) {
    return this.statusSummary.find(v => v.status === status)?.count || 0
  }
}
</script>

<style scoped>
.status-select {
  width: 82px;
}

.status-select ::v-deep .v-input__control > .v-input__slot {
  padding: 0 !important;
}

.device-status {
  font-size: 14px;
}
.issue-current-devices-container >>> .actions{ 
  white-space: nowrap;
}
</style>
