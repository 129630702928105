import { Route } from "vue-router";
import router from "@/router/router";
import { TargetData, ChangeManagerState } from "@/types/ChangeManagerState";
import Vue from "vue";

export const defaultState = {
    showWarnings: false,
    location: null,
    onLeave: undefined,
    isChanged: false,
    message: "You can save your changes, discard your changes, or cancel to continue editing",
    title: "You have unsaved changes.",
    targets: {},
} as ChangeManagerState;

const state = Vue.observable({ store: JSON.parse(JSON.stringify(defaultState)) });
const stack: ChangeManagerState[] = [];

interface ChangeManagerProps extends TargetData {
    target: string;
}

class ChangeManager {
    static state() {
        return state.store;
    }

    static get stack() {
        return stack;
    }

    static show(location: Route = state.store.location) {
        state.store.location = location;
        if (stack.length && !state.store.isChanged) {
            ChangeManager.popFromStack();
            return;
        }

        if (!state.store.isChanged && !stack.length) {
            ChangeManager.changeLocation();
            return;
        }

        state.store.showWarnings = true;
    }

    static hide({ isCancel = false } = {}) {
        state.store.showWarnings = false;
        if (isCancel) state.store.location = null;
    }

    static changeLocation() {
        const location = state.store.location;
        this.clear();
        if (location) {
            router.push(location);
        }
    }

    static leave() {
        if (state.store.onLeave) {
            state.store.onLeave();
        }

        if (state.store.location) {
            ChangeManager.changeLocation();
            return;
        }
    }

    static clear() {
        stack.length = 0;
        state.store.showWarnings = false;
        state.store.location = null;
        state.store.isChanged = false;
        state.store.message = defaultState.message;
        state.store.title = defaultState.title;
        state.store.targets = {};
        state.store.onLeave = undefined;
    }

    static deactivate(key: string): void {
        state.store.targets[key].isChanged = false;
        state.store.showWarnings = false;
        state.store.isChanged = Object.values(state.store.targets).some(({ isChanged }: any): boolean => isChanged);
    }

    static modalController({
        controller,
        isNewValue,
        isDestroy,
        isUpdateValue,
        data,
        message,
        target,
        onLeave = () => {},
        onSave,
    }: {
        controller: ChangeManager | null;
        isNewValue: boolean | null;
        isDestroy: boolean | null;
        isUpdateValue: boolean | null;
        data: Object | null;
        message: string;
        target: string;
        onLeave: () => void;
        onSave: () => any;
    }): ChangeManager | null {
        if (isNewValue) {
            const changesControl = new ChangeManager({ useStack: true });
            ChangeManager.onLeave = onLeave;
            changesControl.init({ target, onSave, message, isChanged: false, origData: data || {} });
            return changesControl;
        }

        if (isDestroy) {
            controller?.clearByStack();
            return null;
        }

        if (isUpdateValue) {
            controller?.clear();
            controller?.init({ target, onSave, message, isChanged: false, origData: data || {} });
            return controller;
        }

        return controller;
    }

    private static popFromStack() {
        if (stack.length) {
            const previousStore = stack.pop();
            state.store = Object.assign(state.store, previousStore, { location: state.store.location });
            if (state.store.location) {
                this.show();
            }
            return;
        }

        state.store = Object.assign(state.store, defaultState);
    }

    target: string = "";

    constructor({ useStack = false } = {}) {
        if (useStack) {
            stack.push({ ...state.store });
            state.store = Object.assign(state.store, defaultState);
        }
    }

    init({ target, onSave, message, isChanged = false, origData = {} }: ChangeManagerProps) {
        delete state.store.targets[this.target];

        const id = Date.now() + String(Math.random()).substring(2);
        this.target = `${target}_${id}`;
        state.store.targets = Object.assign({}, state.store.targets, {
            [this.target]: { onSave, message, isChanged, origData: JSON.parse(JSON.stringify(origData)) },
        });
    }

    activate(): void {
        if (!this.target || !state.store.targets[this.target]) return;
        state.store.targets[this.target].isChanged = true;
        state.store.isChanged = true;
    }

    deactivate(): void {
        if (!this.target || !state.store.targets[this.target]) return;
        state.store.targets[this.target].isChanged = false;
        state.store.isChanged = Object.values(state.store.targets).some(({ isChanged }: any): boolean => isChanged);
    }

    addOrigData(origData: Object | null = null) {
        if (state.store.targets[this.target]?.origData) {
            state.store.targets[this.target].origData = Object.assign(
                state.store.targets[this.target].origData,
                JSON.parse(JSON.stringify(origData))
            );
        }

        const targetStack = stack.find((store) => store.targets[this.target]?.origData);

        if (targetStack) {
            const origDataByStack = targetStack.targets[this.target].origData;
            origDataByStack && Object.assign(origDataByStack, origData as object);
        }
    }

    clear(): void {
        if (state.store.targets[this.target]) {
            delete state.store.targets[this.target];
            this.target = "";
            state.store.targets = Object.assign({}, state.store.targets);
            state.store.isChanged = Object.values(state.store.targets).some(({ isChanged }: any): boolean => isChanged);
        }
    }

    clearByStack() {
        if (this.target && Object.keys(state.store.targets).find((key) => key === this.target)) {
            ChangeManager.popFromStack();
        }
    }

    get data() {
        return state.store.targets[this.target];
    }

    static set onLeave(func: Function) {
        state.store.onLeave = func;
    }

    static get isBlockedTransition() {
        return state.store.isChanged || Boolean(stack.length);
    }

    static isObjectEqual(
        origObject: object,
        object: object,
        { isOrigPartial = false, exclude = [] }: { isOrigPartial?: boolean; exclude?: string[] } = {}
    ) {
        const transformAsString = (value: object) => {
            return JSON.stringify(value, (_, v) => {
                if (v?.constructor === Object) return Object.entries(v).sort();
                // Usually, an input field converts a number into a string.
                // To ensure proper comparison, we will convert all numbers to the string type.
                if (typeof v === "number") return v.toString();
                return v;
            });
        };

        const normalizeOrigData: { [key: string]: any } = { ...origObject };
        const normalizeObjectData: { [key: string]: any } = { ...object };

        if (exclude.length) {
            exclude.forEach((key) => {
                delete normalizeOrigData[key];
                delete normalizeObjectData[key];
            });
        }

        if (isOrigPartial) {
            Object.entries(normalizeObjectData).forEach(([k, v]) => {
                if (normalizeOrigData[k] === undefined && ["", null].includes(v)) {
                    normalizeOrigData[k] = v;
                }
            });
        }

        const origObjAsString = transformAsString(normalizeOrigData);
        const objAsString = transformAsString(normalizeObjectData);

        return origObjAsString === objAsString;
    }
}

export default ChangeManager;
