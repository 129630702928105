<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1 align-baseline">
          <div class="flex-grow-0 pr-2 select-auto-width">
            <v-select
              v-model="selectedSearchFields"
              :items="searchFields"
              label="Fields"
              hide-details
              @change="searchTerm ? search(true) : false"
            ></v-select>
          </div>
          <v-text-field
            v-model="searchTerm"
            append-icon="mdi-magnify"
            label="Search for"
            hide-details
            clearable
            prefix=":"
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
          ></v-text-field>
          <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="customersTableColumns" />

          <v-tooltip bottom :disabled="!searchTerm">
            <template v-slot:activator="{ on, attrs }">
              <span class="align-self-end ml-2 p-relative" v-bind="attrs" v-on="on">
                <v-btn
                  small
                  icon
                  class="align-self-end"
                  @click="showFilter = !showFilter"
                  :color="showFilter ? 'primary' : undefined"
                  :disabled="loading || Boolean(searchTerm)"
                  title="Filters"
                >
                  <v-icon v-if="searchTerm">mdi-filter-off</v-icon>

                  <v-icon v-if="!searchTerm">mdi-filter-variant</v-icon>
                  <v-badge
                    v-if="numberOfFilter && !searchTerm"
                    transition="v-fade-transition"
                    dot
                    bordered
                    offset-x="8"
                    offset-y="-1"
                  />
                </v-btn>
              </span>
            </template>
            Filters ignored during search
          </v-tooltip>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn v-if="allowAddCustomers" small color="primary" @click="newCustomer()"> New customer </v-btn>

          <v-menu v-if="isAdmin" bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-4">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="exportCustomers('xls')">
                <v-list-item-title>Export customers with last used device id (XLS)</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportCustomers('csv')">
                <v-list-item-title>Export customers with last used device id (CSV)</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" />
      <v-data-table
        dense
        :item-class="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="footerProps"
        :mobile-breakpoint="0"
        @click:row="rowClick"
        @contextmenu:row="openContenxMenu"
        class="no-wrap-table-sm"
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ getCustomerTypeName(item.type) }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-tooltip v-if="item.email && item.email.length > 30" bottom color="secondary" max-width="500">
            <template v-slot:activator="{ on }">
              <div class="no-wrap" v-on="on">{{ item.email.substring(0, 30) + "..." }}</div>
            </template>
            <span class="pre-wrap">{{ item.email }}</span>
          </v-tooltip>
          <span v-else>{{ item.email }}</span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.updated`]="{ item }">
          {{ item.updatedDate ? moment(item.updatedDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.countryIso2`]="{ item }">
          {{ item.countryIso2 ? `${countryByCode(item.countryIso2)}` : "" }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" bottom color="secondary" max-width="500">
            <template v-slot:activator="{ on }">
              <div class="no-wrap" v-on="on">{{ item.notes.substring(0, 15) + "..." }}</div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>
      </v-data-table>

      <v-overlay absolute :value="loading" opacity="0" />
    </v-card>

    <EditCustomer v-model="customerToEdit" v-on:updated="reload" :initData="initData" :customerInitTab="customerInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import EditCustomer from "@/components/customers/EditCustomer.vue";
import Customer from "@/types/Customer";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { CustomerType } from "@/types/CustomerType";
import customerResource from "@/resources/CustomerResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import CustomerHelper from "@/helpers/customerHelper";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import commonHelper from "@/helpers/commonHelper";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

@Component({
  name: "Customers", // name is needed for keep-alive
  components: {
    EditCustomer,
    DataTableContextMenu,
    TableConfiguration,
    Filters,
  },
})
export default class Customers extends Vue {
  moment = moment;

  total = 0;
  items: Customer[] = [];
  loading = false;

  optionsStorageKey = "customersTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: ["customerId"],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 15,
  };
  footerProps = {
    showFirstLastPage: true,
    "items-per-page-options": [15, 25, 50],
  };
  searchTermStorageKey = "customersTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  searchFieldsStorageKey = "customersSelectedSearchField";
  selectedSearchFields = userStorage.get(this.searchFieldsStorageKey) ?? "";
  searchFields = [
    { text: "*", value: "" },
    { text: "ID", value: "id" },
    { text: "Phone number", value: "phone" },
  ];
  
  @Prop({ default: null })
  readonly initFilter!: { [key: string]: TableFilter["selected"] };

  @Prop({ default: {} })
  initData!: { customerId: number; customerTab: string } | null;

  customerToEdit: Customer | null = null;
  customerInitTab: string | null = null;
  ignoreOptionsChange: boolean = false;

  showFilter = Boolean(Object.values(this.$props.initFilter || {}).length);
  filter: TableFilter[] = [
    {
      title: "Country",
      icon: "mdi-map-marker",
      filterName: "countryIso2",
      searchable: false,
      selected: this.$props.initFilter?.countryIso2 || [],
      disableMultiple: true,
      dividerValues: ["DK"],
      itemsCallback: () => {
        const countryArr = Object.entries(countries.getNames("en", { select: "official" }))
          .sort((a, b) => {
            const mainCountries = ["DK", "SE", "NO"];
            const isSortByAlphabet = !mainCountries.includes(a[0]) && !mainCountries.includes(b[0]);
            if (isSortByAlphabet) return a[1] > b[1] ? 1 : -1;
            return mainCountries.indexOf(a[0]) > mainCountries.indexOf(b[0]) ? -1 : 1;
          })
          .map(([k, v]) => ({
            text: `${v} (${k})`,
            value: k,
          }));
        return countryArr;
      },
    },
  ];

  get numberOfFilter() {
    return Object.values(this.filter).reduce((acc, { selected }) => (acc += selected.length), 0);
  }

  @Watch("customerToEdit")
  onChangeCustomerToEdit() {
    if (!this.customerToEdit) {
      this.customerInitTab = null;
    }
  }

  @Watch("options", { deep: true })
  onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      this.getData();
    }
  }

  selectedHeaders = [];
  headers = [
    { text: "ID", value: "customerId" },
    { text: "First name", value: "firstName" },
    { text: "Last name", value: "lastName" },
    { text: "Email", value: "email" },
    { text: "Phone number", value: "phoneNumber" },
    { text: "Type", value: "type" },
    { text: "Company name", value: "companyName" },
    { text: "Notes", value: "notes", sortable: false },
    { text: "Created", value: "created" },
    { text: "Updated", value: "updated" },
    { text: "Country", value: "countryIso2" },
  ];

  get allowAddCustomers() {
    return userProfileService.hasPermission(UserPermissionType.AddCustomers);
  }
  get isAdmin() {
    return userProfileService.currentUser?.isAdministrator;
  }

  dataReloadTimeoutId: number | undefined = undefined;
  dataReloadIntervalSeconds = 180;
  componentActive = false;
  activated() {
    this.componentActive = true;

    if (this.initData?.customerId) {
      this.getCustomerById(this.initData?.customerId, true);
    }

    // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0) {
      this.getData();
    }
  }
  deactivated() {
    this.componentActive = false;
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.getData();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getCustomerTypeName(type: CustomerType) {
    return CustomerHelper.getCustomerTypeDisplayName(type);
  }

  newCustomer() {
    this.customerToEdit = {
      customerId: 0,
      firstName: "",
      lastName: "",
      email: "",
      type: CustomerType.Unknown,
      companyName: "",
      createdDate: new Date(),
    } as Customer;
  }

  getCustomerById(customerId: number, isInitData = false) {
    if (customerId) {
      this.loading = true;

      customerResource
        .getCustomerById(customerId)
        .then((resp) => {
          this.customerToEdit = resp.data;
          if (isInitData) {
            this.customerInitTab = this.initData?.customerTab || null;
          }
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    }
  }

  countryByCode(code: string) {
    return countries.getName(code, "en", { select: "official" });
  }

  getData(resetPagination: boolean = false) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.options.page = 1;
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, this.options);
    userStorage.set(this.searchFieldsStorageKey, this.selectedSearchFields);
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let countryFilter = undefined;

      if (!this.searchTerm) {
        countryFilter = this.filter
          .find(({ filterName }) => filterName === "countryIso2")
          ?.selected.map(({ value }) => value)[0];
      }

      let searchTermValue;
      if (this.selectedSearchFields && this.searchTerm) {
        searchTermValue = this.selectedSearchFields + ":" + this.searchTerm;
      } else {
        searchTermValue = this.searchTerm;
      }

      customerResource
        .getCustomersPaged(itemsPerPage, page, searchTermValue, sortBy[0], sortDesc[0], countryFilter, this.cancelToken)
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData(true);
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: Customer) {
    if (!this.contextMenuEventItem) {
      this.customerToEdit = Object.assign({}, item);
    }
  }

  rowClass(item: Customer) {
    return "cursor-default";
  }

  exportCustomers(format: string) {
    window.open(`${commonHelper.apiHost}/export/customers-and-last-used-device-id?format=${format}`, "_blank");
  }

  updateFilter(newFilter: TableFilter[]) {
    this.filter = newFilter;
    if (this.options.page > 1) {
      this.options.page = 1;
      return;
    }

    this.getData();
  }
}
</script>
