<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        Global advert settings
      </v-card-title>
      <v-card-subtitle>
        The settings below are global and affect all ads and all devices.
      </v-card-subtitle>
      <v-card-text v-if="settings">
        <v-sheet max-width="500">
          <PropEditor name="Message of the day" desc="ID of the advert that will be used as the message of the day."
            titleSize="large">
            <v-text-field v-model="settings.messageOfTheDayId" dense outlined></v-text-field>
          </PropEditor>
          <PropEditor name="Maximum adverts per day"
            desc="Sets the maximum number of adverts each device can display per day." titleSize="large">
            <v-text-field v-model="settings.maxAdvertsPerDay" dense outlined></v-text-field>
          </PropEditor>
          <PropEditor name="Minimum time between adverts" desc="Sets the minimum number of minutes between adverts."
            titleSize="large">
            <v-text-field v-model="settings.minTimeBetweenAdverts" dense outlined></v-text-field>
          </PropEditor>

          <v-btn color="primary" class="mt-8" @click="saveSettings" :loading="loading" :disabled="loading">Save settings
          </v-btn>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-container>
</template>
 
<script lang="ts">
import AdvertSettings from "@/types/AdvertSettings";
import { Component, Vue } from "vue-property-decorator";
import PropEditor from "../components/layout/PropEditor.vue";
import advertResource from "@/resources/AdvertResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  name: "AdvertSettings", // name is needed for keep-alive
  components: {
    PropEditor,
  }
})

export default class AdvertSettingsView extends Vue {
  settings: AdvertSettings | null = null;
  loading: boolean = false;

  getSettings() {
    this.loading = true;

    advertResource
      .getAdvertSettings()
      .then((resp) => {
        this.settings = resp.data;
      })
      .catch(advertResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  saveSettings() {
    if (!this.settings)
      return;

    this.loading = true;

    advertResource
      .updateAdvertSettings(this.settings)
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, `Global advert settings updated`);
        this.getSettings();
      })
      .catch(advertResource.defaultErrorHandler);
  }

  mounted() {
    this.getSettings();
  }
}
</script>
