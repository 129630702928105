<template>
  <v-container fluid style="min-height: 100%; height: 100%">
    <v-flex class="d-flex flex-column" style="height: 100%">
      <v-card class="d-flex flex-grow-1" style="min-height: 400px">
        <div class="map-wrap">
          <l-map
            ref="map"
            class="map-container"
            :zoom.sync="mapZoom"
            :center="mapCenter"
            :bounds.sync="mapBounds"
            :noBlockingAnimations="true"
            :maxZoom="20"
            @click="openPopup"
          >
            <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <l-control position="topright">
              <div class="text-right">
                <div class="d-flex no-wrap mt-2 justify-end">
                  <v-select
                    :items="geohashAreaType"
                    v-model="selectedGeohashAreaType"
                    label="Type"
                    dense
                    filled
                    outlined
                    light
                    hide-details
                    attach
                  ></v-select>
                </div>
                <!-- Radius -->
                <div v-if="selectedGeohashAreaType == 'radius'">
                  <div class="d-flex no-wrap mt-2 justify-end">
                    <v-text-field
                      v-model="circleCenterLatInput"
                      label="Latitude"
                      dense
                      filled
                      outlined
                      light
                      hide-details
                      step="0.001"
                      type="number"
                    ></v-text-field>
                    <div class="ml-2"></div>
                    <v-text-field
                      v-model="circleCenterLonInput"
                      label="Longitude"
                      dense
                      filled
                      outlined
                      light
                      hide-details
                      step="0.001"
                      type="number"
                    ></v-text-field>
                  </div>
                  <div class="d-flex no-wrap mt-2 justify-end">
                    <v-text-field
                      v-model="circleRadiusInput"
                      label="Radius (meters)"
                      dense
                      filled
                      outlined
                      light
                      hide-details
                      step="100"
                      type="number"
                    ></v-text-field>
                  </div>
                </div>
                <!-- Shared -->
                <div class="d-flex no-wrap mt-2 align-center justify-end">
                  <v-switch v-model="autoPrecision" label="Auto" class="mt-0 mr-3" light dense hide-details inset></v-switch>
                  <v-text-field
                    v-model="precision"
                    label="Precision (1-12)"
                    dense
                    filled
                    outlined
                    light
                    hide-details
                    :disabled="autoPrecision"
                    max="12"
                    min="1"
                    step="1"
                    type="number"
                  ></v-text-field>
                </div>
                <div class="d-flex no-wrap mt-2 align-center justify-end">
                  <v-switch v-model="compress" label="Compress" class="mt-0" light dense hide-details inset></v-switch>
                </div>
                <div class="mt-2">
                  <v-btn small @click="getGeohashes()" :disabled="loading"><b>Show on map</b></v-btn>
                </div>
              </div>
            </l-control>
            <div v-if="geoJson.features.length">
              <l-feature-group ref="geohashGroup">
                <div v-if="selectedGeohashAreaType == 'radius' && circle && circleCenter">
                  <l-circle-marker
                    :lat-lng="circle.center"
                    :radius="4"
                    :weight="0"
                    fillColor="#003300"
                    :fillOpacity="0.7"
                    :interactive="false"
                  ></l-circle-marker>
                  <l-circle
                    :lat-lng="circle.center"
                    :radius="circle.radius"
                    :weight="1"
                    color="#003300"
                    :fillOpacity="0"
                    :opacity="0.4"
                    :interactive="false"
                  ></l-circle>
                  <l-circle-marker
                    :lat-lng="circleCenter.center"
                    :radius="4"
                    :weight="0"
                    fillColor="#ff0000"
                    :fillOpacity="0.7"
                    :interactive="false"
                  ></l-circle-marker>
                  <l-circle
                    :lat-lng="circleCenter.center"
                    :radius="circleCenter.radius"
                    :weight="1"
                    color="#ff0000"
                    :fillOpacity="0"
                    :interactive="false"
                  ></l-circle>
                </div>
              </l-feature-group>
            </div>
          </l-map>
        </div>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LControlScale,
  LControl,
  LRectangle,
  LTooltip,
  LPopup,
  LCircle,
  LCircleMarker,
  LFeatureGroup,
} from "vue2-leaflet";
import MapHelper from "@/helpers/mapHelper";
import geohashResource from "@/resources/GeohashResource";
import Geohash from "@/services/Geohash";
import { FeatureCollection } from "geojson";
import geojsonvt, { Feature } from "geojson-vt";
import "@/components/LeafletDraw/leaflet-geojson-vt";
import { pointInPolygon, Polygon } from "geojson-utils-ts";

interface GeoRect {
  name: string;
  bounds: [number[], number[]];
}

@Component({
  name: "GeohashMap", // name is needed for keep-alive
  components: {
    L,
    LMap,
    LTileLayer,
    LControlScale,
    LControl,
    LRectangle,
    LTooltip,
    LPopup,
    LCircle,
    LCircleMarker,
    LFeatureGroup,
  },
})
export default class GeohashMap extends Vue {
  moment = moment;

  loading = false;
  geohashLayer: any | null = null;
  geoJson: FeatureCollection<Polygon, GeoRect> = { type: "FeatureCollection", features: [] };
  canvasLayer: any | null = null;

  circle: { center: [number, number]; radius: number } | undefined = undefined;
  circleCenter: { center: [number, number]; radius: number } | undefined = undefined;
  circleCenterLatInput: number | undefined = 63.428;
  circleCenterLonInput: number | undefined = 10.395;
  circleRadiusInput: number = 3000;

  autoPrecision: boolean = true;
  precision: number = 5;

  compress = true;

  mapCenter = [64, 19];
  mapBounds: { _northEast: { lat: number; lng: number }; _southWest: { lat: number; lng: number } } | null = null;
  mapZoom = 5;
  bounds = {} as {
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
  };
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };

  selectedGeohashAreaType = "radius";
  geohashAreaType = [
    { text: "Radius", value: "radius" },
    // { text: "Bounds", value: "bounds" },
  ];

  mounted() {
    //this.getGeohashes();
  }

  getGeohashes() {
    if (this.selectedGeohashAreaType == "radius") {
      this.getGeohashesInRadius();
    }
  }

  getGeohashesInRadius() {
    if (!this.circleCenterLatInput || !this.circleCenterLonInput) return;

    this.loading = true;

    geohashResource
      .getInRadius(
        this.circleCenterLatInput,
        this.circleCenterLonInput,
        this.circleRadiusInput,
        this.compress,
        this.autoPrecision ? undefined : this.precision
      )
      .then((resp) => {
        const newGeoJson: any = resp.data.geohashes.map((gh) => {
          const bnds = Geohash.bounds(gh);
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [bnds.ne.lon, bnds.ne.lat],
                  [bnds.ne.lon, bnds.sw.lat],
                  [bnds.sw.lon, bnds.sw.lat],
                  [bnds.sw.lon, bnds.ne.lat],
                  [bnds.ne.lon, bnds.ne.lat],
                ],
              ],
            },
            properties: {
              name: gh,
              bounds: [
                [bnds.ne.lat, bnds.ne.lon],
                [bnds.sw.lat, bnds.sw.lon],
              ],
            },
          };
        });

        this.geoJson = {
          type: "FeatureCollection",
          features: [...newGeoJson],
        };

        this.circle = {
          center: [Number(this.circleCenterLatInput!), Number(this.circleCenterLonInput!)],
          radius: Number(this.circleRadiusInput),
        };
        this.circleCenter = {
          center: [resp.data.centerLat, resp.data.centerLon],
          radius: Number(this.circleRadiusInput),
        };
        this.precision = resp.data.precision;

        if (this.canvasLayer) this.canvasLayer.remove();

        const options = {
          maxZoom: 20,
          tolerance: 3,
          debug: 0,
          style: {
            fillColor: "#ddaa22",
            color: "#ddaa22",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          },
        };
        //@ts-ignore
        const map = this.$refs.map.mapObject;
        this.canvasLayer = L.geoJson.vt(this.geoJson, options, geojsonvt).addTo(map);

        setTimeout(this.center, 250);
      })
      .catch(geohashResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  center() {
    var group = this.$refs.geohashGroup as any;
    if (group) {
      this.mapBounds = group.mapObject.getBounds();
    }
  }
  findRectangleByCoordinates(coordinates: [x: number, y: number]) {
    return this.geoJson.features.find(
      (react: any) => !!pointInPolygon({ type: "Point", coordinates }, react.geometry as Polygon)
    );
  }

  openPopup(e: any) {
    const map = this.$refs.map as any;
    const x: number = e.latlng.lng;
    const y: number = e.latlng.lat;
    const foundRectangle = this.findRectangleByCoordinates([x, y]);

    if (!foundRectangle) return;

    const georect = foundRectangle.properties;
    L.popup(e.latlng, {
      content: `<p>
                  <b> ${georect.name}</b>
                  <br />
                  NE: ${georect.bounds[0][0]}, ${georect.bounds[0][1]}<br />
                  SW: ${georect.bounds[1][0]}, ${georect.bounds[1][1]}
                </p>`,
    }).openOn(map.mapObject);
  }
}
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 100%;
}
.geo-rect {
  cursor: default;
}
</style>
