import MapIcons from "@/types/MapIcons";
import MapIcon from "@/types/MapIcon";
import { PoiType } from "@/types/PoiType";
import Poi from "@/types/Poi";
import moment from "moment";

export default class PoiHelper {
    static DefaultSearchRadius = 1000;

    static getPoiTypeDisplayName(type: PoiType): string {
        switch (type) {
            case PoiType.Animal: return "Animal";
            case PoiType.AverageCamera: return "Average speed camera start";
            case PoiType.AverageCameraEnd: return "Average speed camera end";
            case PoiType.Checkpoint: return "Checkpoint";
            case PoiType.CustomsControl: return "Customs control";
            case PoiType.RelayPoint: return "Relay point";
            case PoiType.RoadCondition: return "Road condition";
            case PoiType.SeatBeltControl: return "Seatbelt control";
            case PoiType.SpeedCamera: return "Speed camera";
            case PoiType.SpeedControl: return "Speed control";
            case PoiType.TechnicalControl: return "Technical control";
            default: return type;
        }
    }

    static getTtlMinutes(ttl: Date | undefined): number {
        if (!ttl) {
            return 0;
        }

        var minutes = moment.duration(moment(ttl).diff(moment.now())).asMinutes();
        if (minutes < 0) {
            minutes = 0;
        }

        return minutes;
    }

    static getMapIconByType(type: PoiType): MapIcon {
        if (type === PoiType.SpeedCamera) {
            return MapIcons.camera;
        } else if (type === PoiType.AverageCamera) {
            return MapIcons.cameraAvgStart;
        } else if (type === PoiType.AverageCameraEnd) {
            return MapIcons.cameraAvgEnd;
        } else if (type === PoiType.SpeedControl) {
            return MapIcons.speedControl;
        } else if (type === PoiType.SeatBeltControl) {
            return MapIcons.seatbeltControl;
        } else if (type === PoiType.TechnicalControl) {
            return MapIcons.technicalControl;
        } else if (type === PoiType.CustomsControl) {
            return MapIcons.customsControl;
        } else if (type === PoiType.RoadCondition) {
            return MapIcons.roadCondition;
        } else if (type === PoiType.Animal) {
            return MapIcons.animal;
        } else {
            return MapIcons.default;
        }
    }

    static getIconSpriteConfig() {
        const iconType = ['orig', 'disabled', 'transparent']
        const imgHeight= 100
        const imgWidth = 100
        return {
            imgHeight,
            imgWidth,
            height: imgHeight * Object.keys(PoiType).filter((v) => !isNaN(Number(v))).length,
            width: imgWidth * iconType.length,
            order: Object.values(PoiType).filter((v) => !isNaN(Number(v))).map(v=> PoiHelper.getMapIconByType(v as number).iconUrl).concat([MapIcons.default.iconUrl]),
            origPosition: imgWidth * iconType.findIndex(v => v === 'orig'),
            disabledPosition: imgWidth * iconType.findIndex(v => v === 'disabled'),
            transparentPosition: imgWidth * iconType.findIndex(v => v === 'transparent'),
        };
    }

    static getPoiIconClassName(poi: Poi) {
        return poi.isActive ? "poi-active" : "";
    }

    static measureDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6378.137; // Radius of earth in KM
        var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
        var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;

        return d * 1000; // meters
    }

 static getLocationAreaInfo(geocoderResult: google.maps.GeocoderResult[]): string {
        var countryCode: string = "";
        var districtName: string = "";
        var areaName: string = "";
        var cityName: string = "";
        var routeName: string = "";
        var routeNameShort: string = "";

        // Prefer "route" type result, else first available
        var geoResult = geocoderResult.find((item) => {
            return item.types.some((type) => type === "route");
        }) || geocoderResult[0];

        // Get values from geocoderResult
        if (geoResult.address_components) {
            for (var component of geoResult.address_components) {
                if (component.types) {
                    switch (component.types[0]) {
                        case "route":
                            routeName = component.long_name;
                            routeNameShort = component.short_name;
                            break;
                        case "postal_town":
                            cityName = component.long_name;
                            break;
                        case "country":
                            countryCode = component.short_name;
                            break;
                        case "sublocality":
                            districtName = component.long_name;
                            break;
                        case "administrative_area_level_2":
                            districtName = component.long_name.replace(/\bKommune\b/i, "").trim();
                            break;
                        case "point_of_interest":
                            areaName = component.long_name;
                            break;
                        case "locality":
                            areaName = component.long_name;
                            break;
                    }
                }
            }
        }
        var areaInfo = "";
        if (cityName) {
            areaInfo = cityName;
        } else {
            if (countryCode === "NO") {
                areaInfo = districtName || areaName;
            } else {
                areaInfo = areaName;
            }
        }

        if (routeName.indexOf("Unknown") >= 0 || routeName.indexOf("Unnamed") >= 0) {
            return areaInfo;
        }

        if (routeName) {
            if (routeName.match(/\bFylkesve.\b|\bRiksve.\b|\bEuropave.\b/i)) {
                areaInfo += `: ${routeNameShort}`;
            } else {
                areaInfo += `: ${routeName}`;
            }
        } else {
            var common = geoResult.formatted_address.match(/^.+?(?=,)/);
            if (common) {
                var filtered = common[0].replace(/[A-Z|\d]+\+[[A-Z|\d]+\s/, "").trim();
                if (filtered !== areaInfo) {
                    areaInfo += `: ${filtered}`;
                }
            }
        }

        //console.log(geocoderResult);

        return areaInfo;
    }

    static getNewPoiTemplate(): Poi {
        return {
            poiId: 0,
            deviceId: 0,
            latitude: 64.465257,
            longitude: 11.492262,
            type: PoiType.Checkpoint,
            isActive: true,
            direction: 0,
            areaInfo: "",
            shortRangeWarningRadius: 500,
            longRangeWarningRadius: 3000,
            timeToLive: moment().add(120, "minute").toDate(),
            createdDate: new Date(),
        } as Poi;
    }

    static getNonPermanentPoiTypes() {
        return [
            PoiType.Checkpoint,
            PoiType.SpeedControl,
            PoiType.SeatBeltControl,
            PoiType.TechnicalControl,
            PoiType.CustomsControl,
            PoiType.RoadCondition,
            PoiType.Animal,
        ];
    }

    static getPermanentPoiTypes() {
        return [
            PoiType.SpeedCamera,
            PoiType.AverageCamera,
            PoiType.AverageCameraEnd,
        ];
    }

    static isPermanentPoiType(type: PoiType) {
        return type === PoiType.SpeedCamera || type === PoiType.AverageCamera || type === PoiType.AverageCameraEnd;
    }
}