import { DeviceType } from "@/types/DeviceType";

export default class DeviceHelper {
    static getDeviceTypeDisplayName(type: DeviceType): string {
        switch (type) {
            case DeviceType.GpsRadarPro: return "Pro";
            case DeviceType.GpsRadarProX: return "ProX";
            case DeviceType.MobileApp: return "Mobile App";
            default: return type;
        }
    }

    static getDeviceFilterByType() {
        return [
            { text: this.getDeviceTypeDisplayName(DeviceType.GpsRadarPro), value: DeviceType.GpsRadarPro },
            { text: this.getDeviceTypeDisplayName(DeviceType.GpsRadarProX), value: DeviceType.GpsRadarProX },
            { text: this.getDeviceTypeDisplayName(DeviceType.MobileApp), value: DeviceType.MobileApp },
        ];
    }
}