<template>
  <v-menu
    v-model="showContextMenu"
    :position-x="contextMenuX"
    :position-y="contextMenuY"
    absolute
    offset-y
    z-index="999"
    light
  >
    <v-list dense class="py-0">
      <v-list-item @click="contextMenuCopyText">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Copy</v-list-item-title>
      </v-list-item>
      <slot />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  components: {},
})
export default class DataTableContextMenu extends Vue {
  @Prop({ default: null })
  readonly value!: MouseEvent | null;

  showContextMenu = false;
  contextMenuX = 0;
  contextMenuY = 0;

  @Watch("value")
  onContextItemChanged() {
    if (this.value) {
      this.value.preventDefault();
      this.openContenxMenu();
    }
  }

  @Watch("showContextMenu")
  onShowMenuChanged() {
    if (this.showContextMenu === false) {
      this.$emit("input", null);
    }
  }

  openContenxMenu() {
    this.contextMenuX = this.value!.clientX;
    this.contextMenuY = this.value!.clientY;
    this.showContextMenu = true;
  }

  contextMenuCopyText() {
    var elm = this.value!.target as HTMLElement;
    if (elm && elm.textContent) {
      navigator.clipboard.writeText(elm.textContent)
        .catch((e)=> {
          infoMessageService.show(InfoMessageType.Error, "Failed to copy text")
        });
    }
  }
}
</script>