<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field v-model="searchTerm" append-icon="mdi-magnify" label="Search" hide-details clearable></v-text-field>
          <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn small color="primary" class="align-self-end" @click="newApiKey()"  test-id="newApiKey"> New API key </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :item-class="rowClass"
        :options.sync="options"
        :loading="loading"
        :search="searchTerm"
        :disable-pagination="true"
        :hide-default-footer="true"
        :mobile-breakpoint="0"
        @click:row="rowClick"
        test-id="api-keys-table"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span v-if="item.createdAt">
            {{ `${moment(item.createdAt).format("lll")} (${item.createdBy})` }}
          </span>
        </template>

        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">
            {{ `${moment(item.lastModifiedAt).format("lll")} (${item.lastModifiedBy})` }}
          </span>
        </template>
      </v-data-table>

      <v-overlay absolute :value="loading" opacity="0" />
    </v-card>

    <EditAPIKey v-model="apiKeyToEdit" v-on:updated="reload" :initTab="editInitTab" />
  </v-container>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import apiKeysResource from "@/resources/ApiKeysResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import ApiKey from "@/types/ApiKey";
import EditAPIKey from "@/components/apiKeys/EditApiKey.vue";
import userStorage from "@/services/UserStorageService";

@Component({ components: { EditAPIKey } })
export default class ApiKeys extends Vue {
  @Prop({ default: null })
  initData!: { apiKeyId: number, apiKeyTab: string } | null;

  moment = moment;

  items: ApiKey[] = [];
  loading = false;
  cancelToken: CancelTokenSource | undefined = undefined;

  searchTerm: string = "";

  editInitTab: string | null = null

  optionsStorageKey = "apiKeysTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false],
  };

  @Watch("options", { deep: true })
  onPropertyChanged() {
    userStorage.set(this.optionsStorageKey, this.options);
  }

  headers = [
    { text: "ID", align: "start", value: "apiKeyId" },
    { text: "Name", value: "name" },
    { text: "Created", value: "createdAt", sortable: false },
    { text: "Updated", value: "lastModifiedAt", sortable: false },
  ];

  mounted() {
    if (this.initData?.apiKeyId) {
      this.getInitApiKeyById(this.initData?.apiKeyId);
    }
  }

  getInitApiKeyById(apiKeyId: number) {
    apiKeysResource
      .getApiKeyById(apiKeyId)
      .then((resp) =>{
         this.rowClick(resp.data)
         this.editInitTab = this.initData?.apiKeyTab || null;
      })
      .catch(apiKeysResource.defaultErrorHandler);
  }

  apiKeyToEdit: ApiKey | null = null;

  @Watch("apiKeyToEdit")
  onChangeCustomerToEdit() {
    if(!this.apiKeyToEdit) {
      this.editInitTab = null
    }
  }

  created() {
    this.getData();
  }

  newApiKey() {
    this.apiKeyToEdit = {
      apiKeyId: 0,
      name: "",
      apiKeyPermissions: [] as number[],
    } as ApiKey;
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      apiKeysResource
        .getApiKeys(this.cancelToken)
        .then((resp) => {
          this.items = resp.data
        })
        .catch(apiKeysResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  reload() {
    this.getData();
  }

  rowClick(item: ApiKey) {
    this.apiKeyToEdit = Object.assign({}, item);
  }

  rowClass(item: ApiKey) {
    return "cursor-default";
  }
}
</script>
