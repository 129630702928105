<template>
  <div>
    <div v-if="canAddIssue || canEditIssues">
      <AddDeviceIssue :deviceId="deviceId" @update="onAddIssue" />
    </div>
    <div class="subtitle-1">Related issues</div>
    <div class="d-block d-sm-flex mb-2">
      <div class="d-flex flex-grow-1">
        <v-text-field
          v-model="searchTerm"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          clearable
          v-on:input="search()"
          v-on:keypress.enter="search(true)"
        ></v-text-field>
        <v-btn small icon class="align-self-end ml-4" @click="reload" :disabled="loading" title="Refresh">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>

    <v-data-table
      class="issue-table"
      style="cursor: pointer"
      dense
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="footerProps"
      :mobile-breakpoint="0"
      :item-class="rowClass"
      @click:row="rowClick"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span :class="IssueHelper.getIssueStatusColor(item.status, true)">{{ getIssueStatusName(item.status) }}</span>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon :color="IssueHelper.getIssuePriorityColor(item.priority)" :title="IssueHelper.getIssuePriorityName(item.priority)">
          {{ IssueHelper.getIssuePriorityIcon(item.priority) }}
        </v-icon>
      </template>

      <template v-slot:[`item.tags`]="{ item }">
        <v-chip small class="ma-1px" v-for="tag in item.tags" v-bind:key="tag.tagId">
          {{ tag.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.deviceIssueStatus`]="{ item }">
        <div @click="$event.stopPropagation()">
          <v-select
            :loading="deviceStatusLoading.includes(item.issueId)"
            v-model="item.deviceIssueStatus"
            :items="issueDeviceStatuses"
            dense
            solo
            flat
            background-color="transparent"
            hide-details
            class="status-select"
            :readonly="!canEditIssues"
            :disabled="!canEditIssues"
            @input.capture="(newStatus) => setDeviceIssueStatus(newStatus, item)"
          >
            <template slot="selection" slot-scope="data">
              <span class="body-2" :class="data.item.textColor">{{ data.item.text }}</span>
            </template>
            <template slot="item" slot-scope="data">
              <span class="body-2" :class="data.item.textColor">{{ data.item.text }}</span>
            </template>
          </v-select>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          link
          :href="`/support/issues/${item.issueId}`"
          @click="$event.stopPropagation()"
          target="_blank"
          icon
          title="Open in new tab"
        >
          <v-icon small>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-overlay absolute :value="loading" opacity="0" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Issue from "@/types/Issue";
import { IssueStatus } from "@/types/IssueStatus";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddDeviceIssue from "./AddDeviceIssue.vue";
import deviceResource from "@/resources/DeviceResource";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";

@Component({
  components: { AddDeviceIssue },
})
export default class AssociatedIssues extends Vue {
  @Prop()
  readonly deviceId!: number | null;

  IssueHelper = IssueHelper;
  total = 0;
  items: (Issue & { deviceIssueStatus?: IssueDeviceStatus })[] = [];
  loading = false;
  deviceStatusLoading: number[] = [];

  options = {
    sortBy: ["issueId"],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 15,
  };
  footerProps = {
    showFirstLastPage: true,
    "items-per-page-options": [15, 25, 50],
  };

  searchTerm = "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  deviceIssueStatusToken: CancelTokenSource | undefined = undefined;
  cancelDeviceStatusToken: CancelTokenSource | undefined = undefined;

  @Watch("options", { deep: true })
  onPropertyChanged() {
    this.getData();
  }

  @Watch("deviceId")
  onDeviceChanged() {
    this.getData();
  }

  headers = [
    { text: "ID", align: "start", value: "issueId" },
    { text: "Status", value: "status" },
    { text: "Priority", value: "priority" },
    { text: "Title", value: "name" },
    { text: "Tags", value: "tags" },
    { text: "Device status", value: "deviceIssueStatus", sortable: false },
    { text: "", value: "actions", align: "end", sortable: false },
  ];

  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  get canEditIssues() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues);
  }

  issueDeviceStatuses = [
    {
      text: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.New),
      value: IssueDeviceStatus.New,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.New),
    },
    {
      text: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Active),
      value: IssueDeviceStatus.Active,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Active),
    },
    {
      text: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Resolved),
      value: IssueDeviceStatus.Resolved,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Resolved),
    },
  ];

  getIssueStatusName(status: number) {
    return IssueHelper.getIssueStatusDisplayName(status);
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    if (this.deviceIssueStatusToken) {
      this.deviceIssueStatusToken.cancel();
    }

    if (!this.deviceId) return;
    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      this.deviceIssueStatusToken = axios.CancelToken.source();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (!this.deviceId) return;

      const promise = Promise.all([
        issueResource.getIssuesPaged(
          itemsPerPage,
          page,
          this.searchTerm,
          sortBy[0],
          sortDesc[0],
          undefined,
          undefined,
          undefined,
          this.deviceId,
          this.cancelToken
        ),
        deviceResource.getIssueStatusesByDeviceId(this.deviceId, this.deviceIssueStatusToken),
      ]);
      promise
        .then((resp) => {
          if (resp.some((v) => axios.isCancel(v))) return;
          const allDeviceIssueStatuses = resp[1].data;
          this.items = resp[0].data.items.map((v) => {
            const deviceIssueStatus = allDeviceIssueStatuses.find(({ issueId }) => issueId === v.issueId)?.status;
            return { ...v, deviceIssueStatus };
          });
          this.total = resp[0].data.totalItems;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData();
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData();
      }, 1000);
    }
  }

  setDeviceIssueStatus(status: IssueDeviceStatus, issueItem: Issue & { deviceIssueStatus: IssueDeviceStatus }) {
    const previousStatus = issueItem.deviceIssueStatus;
    // Cancel existing request
    if (this.cancelDeviceStatusToken) {
      this.cancelDeviceStatusToken.cancel();
    }

    if (!this.canEditIssues || !issueItem) {
      return;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began

      if (!this.deviceId) return;
      this.cancelDeviceStatusToken = axios.CancelToken.source();
      this.deviceStatusLoading.push(issueItem.issueId);
      issueResource
        .updateIssueDeviceStatus(issueItem.issueId, this.deviceId, status, this.cancelDeviceStatusToken)
        .then(()=> {
          this.$emit('countUpdate')
        })
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.items = this.items.map((item) => {
            if (item.issueId === issueItem.issueId) {
              return { ...item, deviceIssueStatus: previousStatus };
            }
            return item;
          });
        })
        .finally(() => {
          this.cancelDeviceStatusToken = undefined;
          this.deviceStatusLoading = this.deviceStatusLoading.filter((v) => v !== issueItem.issueId);
        });
    }, 10);
  }

  reload() {
    this.getData();
  }

  rowClick(item: Issue) {
    this.$router.push(`/support/issues/${item.issueId}`).catch((e) => {
      if (e.message.includes("Navigation aborted")) return;
      if (e.message.includes("Avoided redundant navigation to current location")) {
        let route = this.$router.resolve({ path: `/support/issues/${item.issueId}` });
        window.open(route.href, "_blank");
        return;
      }
      console.error(e);
    });
  }

  rowClass(item: Issue) {
    if (item.status === IssueStatus.Closed) {
      return "cursor-pointer grey--text";
    } else {
      return "cursor-pointer";
    }
  }

  onAddIssue() {
    this.options = {
      sortBy: ["issueId"],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 15,
    };
  }
}
</script>

<style scoped>
.issue-table >>> td, .issue-table >>> th {
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.status-select {
  width: 90px;
}
.status-select ::v-deep .v-input__control > .v-input__slot {
  padding: 0 !important;
}
.issue-table >>> .v-data-table-header th {
  white-space: nowrap;
}
</style>
