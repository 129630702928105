import ConfirmDialog from "@/components/layout/ConfirmDialog.vue";
import _Vue from "vue";

export class ConfirmService {
  readonly _instance: any;
  readonly _vue: any;
  options: any = {};

  constructor(Vue: typeof _Vue, options: any) {
    this.options = options;
    this._vue = Vue;
    this._instance = this._vue.extend(Object.assign({ vuetify: options.vuetify }, ConfirmDialog));

    delete options.vuetify;
  }

  createDialogCmp(options: any): Promise<boolean> {
    const container = document.querySelector('[data-app=true]') || document.body;

    return new Promise(resolve => {
      const cmp = new this._instance(Object.assign({}, {
        propsData: Object.assign({}, this._vue.prototype.$confirm.options, options),
        destroyed: () => {
          container.removeChild(cmp.$el);
          resolve(cmp.value);
        }
      }));

      container.appendChild(cmp.$mount().$el);
    });
  }

  show(message: string, options: any = {}): Promise<boolean> {
    options.message = message;
    return this.createDialogCmp(options);
  }
}

export default function Confirm(Vue: typeof _Vue, options: any): void {
  Vue.prototype.$confirm = new ConfirmService(Vue, options);
  Vue.prototype.$confirm.options = options || {};
}