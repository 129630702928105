<template>
  <div>
    <side-sheet
      v-if="value"
      :value="showDialog"
      @input="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template>
        <v-form ref="userForm" v-model="valid" lazy-validation>
          <PropEditor name="Name" desc="">
            <v-text-field dense outlined v-model="value.name" :rules="nameRules"></v-text-field>
          </PropEditor>
          <PropEditor v-for="pg in permissions" :key="pg.name" :name="pg.name" :valign="'top'">
            <v-switch v-for="p in pg.permissions" :key="p.name" inset class="my-0" v-model="p.active" @click="setChangesStatus" :label="p.name" />
          </PropEditor>
        </v-form>
      </template>

      <template v-slot:actions>
        <v-btn
          v-if="allowDelete && value.userGroupId && isDeleteBtn"
          color="secondary"
          @click="deleteUserGroupConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ml-4" @click="submit" :loading="loading" :disabled="loading || disabledSubmitBtn">Submit</v-btn>
      </template>
    </side-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import UserGroup from "@/types/UserGroup";
import { UserPermissionType } from "@/types/UserPermissionType";
import userGroupResource from "@/resources/UserGroupResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import UserGroupPermission from "@/types/UserGroupPermission";
import ChangeManager from "@/services/ChangeManager";

interface Permission {
  name: string;
  type: UserPermissionType;
  active: boolean;
}

interface PermissionsGroup {
  name: string;
  permissions: Permission[];
}

@Component({
  components: {
    SideSheet,
    PropEditor,
  },
})
export default class EditUserGroup extends Vue {
  @Prop()
  readonly value!: UserGroup | null;

  @Prop({ default: true })
  readonly isDeleteBtn!: boolean;

// begin change management
  @Watch("value")
  setChangeManager(val: UserGroup | null, oldValue: UserGroup | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.userGroupId !== val.userGroupId,
      data: { userGroup: val },
      message: "You have unsaved Permissions changes.",
      target: `userGroup_${val?.userGroupId}`,
      onLeave: () => { this.showDialog = false },
      onSave: this.submit,
    })
  }

  @Watch("value", { deep: true })
  checkChangesStatus(){
    this.setChangesStatus()
  }

  @Watch("permissions", { deep: true })
  setPermissionsChangeManager() {
    this.setChangesStatus();
  }

  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origUserGroup = this.changesControl?.data?.origData?.userGroup;
    const origPermissions = this.changesControl?.data?.origData?.permissions;
    if (!this.changesControl || !origUserGroup) return;

    if (!ChangeManager.isObjectEqual(origUserGroup, this.value || {}, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    const currentPermissions = JSON.parse(JSON.stringify(this.permissions)).map((item: PermissionsGroup) => {
      item.permissions = item.permissions.map((v) => {
        // @ts-ignore
        if (!v.active) delete v.active;
        return v;
      });
      return item;
    });
   
    if (!ChangeManager.isObjectEqual(origPermissions, currentPermissions, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    this.changesControl?.deactivate();
  }
// end change management


  get disabledSubmitBtn() {
    return !ChangeManager.state().isChanged
  }
  
  @Watch("value")
  onValueChanged(newValue: UserGroup | null, oldValue: UserGroup | null) {
    if (newValue != null) {
      this.populatePermissionsList();
    }

    this.$setComponentQuery("userGroupId", this.value?.userGroupId ? this.value.userGroupId : null);
  }

  get showDialog() {
    return this.value != null;
  }
  set showDialog(value: boolean) {
    this.$emit("input", null);
  }

  get dialogHeading() {
  
    let heading = "";
    if (this.value) {
      heading = this.value?.userGroupId ? `${this.value.name} (ID: ${this.value.userGroupId})` : "New user group";
    }
    return heading;
  }
  changesControl: ChangeManager | null = null;
  permissions: PermissionsGroup[] = [];
  allPermissions = [
    {
      name: "Customers",
      permissions: [
        { name: "View customers", type: UserPermissionType.ViewCustomers },
        { name: "Add customers", type: UserPermissionType.AddCustomers },
        { name: "Edit customers", type: UserPermissionType.EditCustomers },
        { name: "Delete customers", type: UserPermissionType.DeleteCustomers },
      ],
    },
    {
      name: "Devices",
      permissions: [
        { name: "View devices", type: UserPermissionType.ViewDevices },
        { name: "View firmware update URL", type: UserPermissionType.ViewFirmwareUpdateUrl },
        { name: "View device location", type: UserPermissionType.ViewDeviceLocation },
        { name: "View device logs", type: UserPermissionType.ViewDeviceLogs },
        { name: "Edit devices", type: UserPermissionType.EditDevices },
        { name: "Modify Allow Updates (requires Edit devices)", type: UserPermissionType.EditAllowUpdateProperty },
        { name: "Delete devices", type: UserPermissionType.DeleteDevices },
      ],
    },
    {
      name: "POI",
      permissions: [
        { name: "View POI", type: UserPermissionType.ViewPoi },
        { name: "Add POI", type: UserPermissionType.AddPoi },
        { name: "Edit POI", type: UserPermissionType.EditPoi },
        { name: "Delete POI", type: UserPermissionType.DeletePoi },
      ],
    },
    {
      name: "Advertisement",
      permissions: [
        { name: "View adverts", type: UserPermissionType.ViewAds },
        { name: "Add adverts", type: UserPermissionType.AddAds },
        { name: "Edit adverts", type: UserPermissionType.EditAds },
        { name: "Delete adverts", type: UserPermissionType.DeleteAds },
        { name: "Manage advert settings", type: UserPermissionType.EditAdsSettings },
      ],
    },
    {
      name: "Issues",
      permissions: [
        { name: "View issues", type: UserPermissionType.ViewIssues },
        { name: "Add issues", type: UserPermissionType.AddIssues },
        { name: "Edit issues", type: UserPermissionType.EditIssues },
        { name: "Delete issues", type: UserPermissionType.DeleteIssues },
      ],
    },
  ] as PermissionsGroup[];

  valid = true;
  loading = false;

  nameRules = [
    (v: any) => !!v || "Name is required",
    (v: any) => v.length > 2 || "Username must be at least 3 characters long",
  ];

  get allowDelete() {
    return true;
  }
  deleting = false;

  populatePermissionsList() {
    this.permissions = JSON.parse(JSON.stringify(this.allPermissions));
    if (this.value !== null) {
      for (var permission of this.value.userGroupPermissions) {
        for (var pg of this.permissions) {
          for (var p of pg.permissions) {
            if (p.type === permission.userGroupPermissionType) {
              p.active = true;
            }
          }
        }
      }
    }
    this.changesControl?.addOrigData({ permissions: this.permissions });
  }

  applyPermissionsFromList() {
    if (this.value !== null) {
      var permToApply = [] as UserGroupPermission[];
      for (var pg of this.permissions) {
        for (var p of pg.permissions) {
          if (p.active) {
            permToApply.push({ userGroupPermissionType: p.type } as UserGroupPermission);
          }
        }
      }

      this.value.userGroupPermissions = permToApply;
    }
  }

  submit() {
    if (this.value === null) {
      return;
    }

    // Validate form
    if ((this.$refs.userForm as Vue & { validate: () => boolean }).validate()) {
      this.applyPermissionsFromList();

      if (this.value.userGroupId) {
        // Update user group
        userGroupResource
          .updateUserGroup(this.value)
          .then((resp) => {
            infoMessageService.show(InfoMessageType.Success, "User group updated");
            this.showDialog = false;
            this.$emit("updated");
          })
          .catch(userGroupResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      } else {
        // New user group
        userGroupResource
          .addUserGroup(this.value)
          .then((resp) => {
            infoMessageService.show(InfoMessageType.Success, "New user group created");
            this.showDialog = false;
            this.$emit("updated");
          })
          .catch(userGroupResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  deleteUserGroupConfirm() {
    if (!this.allowDelete || this.value == null) {
      return;
    }

    this.$confirm.show(`Delete user group '${this.value.name}'?`).then((confirmed) => {
      if (confirmed) {
        this.deleteUserGroup();
      }
    });
  }

  deleteUserGroup() {
    if (!this.allowDelete || this.value == null) {
      return;
    }

    this.deleting = true;
    userGroupResource
      .deleteUserGroup(this.value.userGroupId)
      .then((resp) => {
        if (this.value != null) {
          infoMessageService.show(InfoMessageType.Success, `User group '${this.value.name}' deleted`);
        }
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(userGroupResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }
}
</script>
