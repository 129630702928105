<template>
  <div class="p-relative">
    <v-btn
      plain
      @click="openWhatIsNew"
      small
      :ripple="false"
      min-width="0"
      class="px-0 mr-2 mr-md-5"
      :disabled="!appStatus.appVersion"
    >
      <v-icon>mdi-information-slab-circle</v-icon>
      <span class="ml-1 d-none d-md-inline">What's new</span>
    </v-btn>
    <v-badge
      v-if="appStatus.appVersion && appStatus.appVersion !== lastSeenVersion"
      class="what-is-new-badge"
      value="as"
      transition="v-fade-transition"
      dot
      bordered
      offset-x="8"
      offset-y="-1"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import appStatusService from "@/services/AppStatusService";
import userStorage from "@/services/UserStorageService";

@Component({})
export default class WhatIsNewBtn extends Vue {
  appStatus = appStatusService;
  lastSeenVersion = "";

  mounted() {
    this.lastSeenVersion = userStorage.get("lastSeenVersion");
  }

  openWhatIsNew() {
    userStorage.set("lastSeenVersion", this.appStatus.appVersion);
    this.lastSeenVersion = this.appStatus.appVersion;
    this.$router.push(`/whats-new`).catch(() => {});
  }
}
</script>
<style scoped>
.what-is-new-badge {
  position: absolute;
  top: 12px;
  left: 24px;
}
</style>
