<template>
  <div v-bind:value="value">
    <side-sheet
      v-if="value"
      :value="showDialog"
      @input="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template slot="tabs">
        <v-tabs v-show="value && value.userId" v-model="tab" grow :show-arrows="false">
          <v-tab href="#user">User</v-tab>
          <v-tab href="#activity">Activity</v-tab>
        </v-tabs>
      </template>
      <template>
        <v-tabs-items v-model="tab" touchless class="full-height-tabs-wrap tabs-w-100">
          <!-- USER -->
          <v-tab-item value="user" transition="none">
            <v-form ref="userForm" v-model="valid" lazy-validation>
              <PropEditor v-if="value.userId" name="Info">
                <div class="subtitle-2">
                  <div><span class="info-label">User ID:</span> {{ value.userId }}</div>
                  <div v-if="value.createdDate">
                    <span class="info-label">Created:</span> {{ moment(value.createdDate).format("lll") }} ({{
                      value.createdBy
                    }})
                  </div>
                  <div v-if="value.updatedDate">
                    <span class="info-label">Updated:</span> {{ moment(value.updatedDate).format("lll") }} ({{
                      value.updatedBy
                    }})
                  </div>
                  <div v-if="value.lastLoginDate">
                    <span class="info-label">Last sign in:</span> {{ moment(value.lastLoginDate).format("lll") }}
                  </div>
                  <div><span class="info-label">Last sign in IP:</span> {{ value.lastLoginIp }}</div>
                </div>
                <div>
                  <v-btn
                    small
                    color="secondary"
                    class="mt-2"
                    :loading="signOutEverywhereLoading"
                    @click="signOutEverywhere()"
                    >Sign out everywhere</v-btn
                  >
                </div>
              </PropEditor>
              <PropEditor name="Username" desc="Allowed characters: letters, numbers, dash(-), underscore(_).">
                <v-text-field dense outlined v-model="value.username" :rules="usernameRules"></v-text-field>
              </PropEditor>
              <PropEditor
                name="Password"
                :desc="
                  (value.userId ? 'Leave empty to keep current password. ' : '') +
                  'Minimum 8 chars, at least one lower case char, upper case char and a number (and/or special char).'
                "
              >
                <div class="d-flex">
                  <v-text-field dense outlined v-model="value.password" :rules="passwordRules" class="mr-4"></v-text-field>
                  <v-btn color="secondary" class="align-self-center" @click="generatePassword()">Generate</v-btn>
                </div>
              </PropEditor>
              <PropEditor
                v-if="value.userId"
                name="Force password update"
                desc="Force user to update their password on next sign in."
              >
                <v-switch v-model="value.isPasswordUpdateRequired"></v-switch>
              </PropEditor>
              <PropEditor name="Email">
                <v-text-field dense outlined v-model="value.email" :rules="emailRules"></v-text-field>
              </PropEditor>
              <PropEditor name="Phone number" desc="Phone number with country code. Ex. 4745012345">
                <v-text-field dense outlined v-model="value.phoneNumber" :rules="phoneRules"></v-text-field>
              </PropEditor>
              <PropEditor name="Active" desc="Active users are able to sign in and use the app.">
                <v-switch v-model="value.isActive"></v-switch>
              </PropEditor>
              <PropEditor name="Multifactor authentication">
                <v-select attach v-model="value.mfaType" :items="mfaItems" outlined dense></v-select>
              </PropEditor>
              <PropEditor
                name="Administrator"
                desc="Adminisrtators are allowed to access any part of the application and do any changes. User group permissions are ignored."
              >
                <v-switch v-model="value.isAdministrator"></v-switch>
              </PropEditor>
              <PropEditor v-if="!value.isAdministrator" name="User groups">
                <v-combobox v-model="selectedUserGroups" :items="userGroups" multiple outlined dense></v-combobox>
              </PropEditor>
              <PropEditor
                v-if="value.userId === 0"
                name="Notify by email"
                desc="Send email notification to new user with link and login credentials."
              >
                <v-switch v-model="notifyNewUserByEmail"></v-switch>
              </PropEditor>
            </v-form>
          </v-tab-item>
          <!-- ACTIVITY -->
          <v-tab-item value="activity" transition="none">
            <UserActivity v-if="tab === 'activity' && value" v-model="value" />
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template v-slot:actions>
        <v-btn
          v-if="allowDeleteUser && value.userId"
          color="secondary"
          @click="deleteUserConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          test-id="submit"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import User from "@/types/User";
import UserGroup from "@/types/UserGroup";
import userGroupResource from "@/resources/UserGroupResource";
import userResource from "@/resources/UserResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { MultiFactorAuthType } from "@/types/MultiFactorAuthType";
import UserHelper from "@/helpers/userHelper";
import ChangeManager from "@/services/ChangeManager";
import UserActivity from "@/components/users/UserActivity.vue";

interface ComboboxItem {
  text: string;
  value: string;
}

@Component({
  components: {
    SideSheet,
    PropEditor,
    UserActivity,
  },
})
export default class EditUser extends Vue {
  moment = moment;
  changesControl: ChangeManager | null = null;
  tab: string | null = null;

  @Prop()
  readonly value!: User | null;

  @Prop()
  readonly initTab!: string | null;

  @Watch("tab")
  onTabChange(val: string | null) {
    if (this.value?.userId) {
      this.$setComponentQuery("userTab", val);
    }
  }

  @Watch("value")
  onPoiChange(newValue: User | null) {
    if (newValue !== null) {
      this.tab = this.initTab || null;
    } else {
      this.tab = null;
      this.$setComponentQuery("userTab", null);
    }
    this.$setComponentQuery("userId", this.value?.userId ? this.value.userId : null);
  }

  // begin change management
  @Watch("value")
  setChangeManager(val: User | null, oldValue: User | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.userId !== val.userId,
      data: { user: val },
      message: "You have unsaved User changes.",
      target: `user_${val?.userId}`,
      onLeave: () => {
        this.showDialog = false;
      },
      onSave: this.submit,
    });
  }

  @Watch("value", { deep: true })
  checkChangesStatus() {
    this.setChangesStatus();
  }

  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origUser = { ...this.changesControl?.data?.origData?.user };

    if (!this.changesControl || !origUser) return;

    //Password and phone number can be null or an empty string. Set the same type
    if (!origUser.password && !this.value?.password && this.value?.hasOwnProperty("password"))
      origUser.password = this.value?.password;
    if (!origUser.phoneNumber && !this.value?.phoneNumber && this.value?.hasOwnProperty("phoneNumber"))
      origUser.phoneNumber = this.value?.phoneNumber;

    if (!ChangeManager.isObjectEqual(origUser, this.value || {}, { isOrigPartial: true })) {
      this.changesControl?.activate();

      return;
    }

    this.changesControl?.deactivate();
  }
  // end change management

  get disabledSubmitBtn() {
    return !ChangeManager.state().isChanged;
  }

  get showDialog() {
    return this.value != null;
  }
  set showDialog(value: boolean) {
    this.$emit("input", null);
  }

  get dialogHeading() {
    let heading = "";
    if (this.value) {
      heading = this.value?.userId ? `${this.value.username} (ID: ${this.value.userId})` : "New user";
    }
    return heading;
  }

  valid = true;
  loading = false;

  notifyNewUserByEmail = true;

  get selectedUserGroups() {
    if (this.value != null) {
      return this.userGroupsToCompoboxItems(this.value.userGroups);
    } else {
      return [];
    }
  }
  set selectedUserGroups(value: ComboboxItem[]) {
    if (this.value != null) {
      this.value.userGroups = this.comboboxItemsToUserGroups(value);
    }
  }

  userGroups: ComboboxItem[] = [];

  mfaItems = [
    { text: UserHelper.getMfaTypeDisplayName(MultiFactorAuthType.None), value: MultiFactorAuthType.None },
    { text: UserHelper.getMfaTypeDisplayName(MultiFactorAuthType.Email), value: MultiFactorAuthType.Email },
    { text: UserHelper.getMfaTypeDisplayName(MultiFactorAuthType.Sms), value: MultiFactorAuthType.Sms },
  ];

  usernameRules = [
    (v: any) => !!v || "Username is required",
    (v: any) => v.length > 2 || "Username must be at least 3 characters long",
    (v: any) => /^[a-zA-Z0-9-_]+$/.test(v) || "Username must be valid",
  ];
  phoneRules = [(v: string) => !v || /^\d+$/.test(v) || "Phone number should contain digits only."];
  emailRules = [(v: any) => !!v || "E-mail is required", (v: any) => /^\S+@\S+\.\S+$/.test(v) || "E-mail must be valid"];
  passwordRules = [(v: any) => !!(this.$props.value?.userId || v) || "Password is required"];

  get allowDeleteUser() {
    return userProfileService.currentUser?.userId !== this.value?.userId;
  }
  deleting = false;

  created() {
    userGroupResource
      .getAllUserGroups()
      .then((resp) => {
        this.userGroups = this.userGroupsToCompoboxItems(resp.data);
      })
      .catch(userGroupResource.defaultErrorHandler);
  }

  generatePassword() {
    userResource
      .generatePassword(12)
      .then((resp) => {
        this.value!.password = resp.data;
      })
      .catch(userResource.defaultErrorHandler);
  }

  submit() {
    if (this.value === null) {
      return;
    }

    // Validate form
    if ((this.$refs.userForm as Vue & { validate: () => boolean }).validate()) {
      if (this.value.userId) {
        // Update user
        this.loading = true;
        userResource
          .updateUser(this.value)
          .then((resp) => {
            infoMessageService.show(InfoMessageType.Success, "User information updated");
            this.showDialog = false;
            this.$emit("updated");
          })
          .catch(userResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      } else {
        // New user
        this.loading = true;
        userResource
          .addUser(this.value, this.notifyNewUserByEmail)
          .then((resp) => {
            if (resp.data && resp.data.warn) {
              infoMessageService.show(InfoMessageType.Warning, resp.data.warn);
            } else {
              infoMessageService.show(InfoMessageType.Success, "New user created");
            }
            this.showDialog = false;
            this.$emit("updated");
          })
          .catch(userResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  deleteUserConfirm() {
    if (!this.allowDeleteUser || this.value == null) {
      return;
    }

    this.$confirm.show(`Delete user '${this.value.username}'?`).then((confirmed) => {
      if (confirmed) {
        this.deleteUser();
      }
    });
  }

  deleteUser() {
    if (!this.allowDeleteUser || this.value == null) {
      return;
    }

    this.deleting = true;
    userResource
      .deleteUser(this.value.userId)
      .then((resp) => {
        if (this.value != null) {
          infoMessageService.show(InfoMessageType.Success, `User '${this.value.username}' deleted`);
        }
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(userResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }

  signOutEverywhereLoading = false;
  signOutEverywhere() {
    this.$confirm.show("This user will be forcibly signed out on all devices.").then((resp) => {
      if (resp) {
        this.signOutEverywhereLoading = true;
        userResource
          .signOutEverywhere(this.value!.userId)
          .then((resp) => {
            infoMessageService.show(
              InfoMessageType.Success,
              `User '${this.value!.username}' will be singed out on all devices`
            );
          })
          .catch(userResource.defaultErrorHandler)
          .finally(() => {
            this.signOutEverywhereLoading = false;
          });
      }
    });
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }

  private userGroupsToCompoboxItems(userGroups: UserGroup[]): ComboboxItem[] {
    return userGroups.map((userGroup) => {
      return { text: userGroup.name, value: userGroup.userGroupId?.toString() };
    });
  }

  private comboboxItemsToUserGroups(items: ComboboxItem[]): UserGroup[] {
    return items.map((item) => {
      return { userGroupId: parseInt(item.value), name: item.text } as UserGroup;
    });
  }
}
</script>
