<template>
  <div>
    <div v-if="canAddComment" class="mb-6">
      <div class="subtitle-1 mb-2">New comment</div>
      <AddCustomerComment v-on:created="addComment" :customerId="customerId" />
    </div>

    <div v-if="comments && comments.length">
      <div class="subtitle-1 mt-0 mb-2">Comments</div>

      <div v-for="comment in comments" v-bind:key="comment.commentId">
        <v-divider class="my-2" />
        <CustomerComment
          :comment="comment"
          v-on:delete="deleteComment"
          v-on:update="updateComment"
          :customerId="customerId"
        />
      </div>

      <div class="d-flex justify-center">
        <v-btn v-if="!loading && moreAvailable" class="my-2" text small @click="getComments">
          <v-icon left>mdi-arrow-down-bold</v-icon>
          Load more
        </v-btn>

        <v-progress-circular v-if="loading" indeterminate color="primary" size="30" class="my-2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Comment from "@/types/Comment";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddCustomerComment from "@/components/customers/CustomerComments/AddCustomerComment.vue";
import CustomerComment from "@/components/customers/CustomerComments/CustomerComment.vue";
import userProfileService from "@/services/UserProfileService";
import customerResource from "@/resources/CustomerResource";

@Component({
  components: { CustomerComment, AddCustomerComment },
})
export default class CustomerComments extends Vue {
  @Prop()
  readonly customerId!: number | undefined;

  comments: Comment[] = [];
  loading = false;
  moreAvailable = false;

  get canAddComment() {
    return userProfileService.hasPermission(UserPermissionType.ViewCustomers);
  }

  mounted() {
    this.getComments();
  }

  getComments() {
    if ((this.comments.length && !this.moreAvailable) || this.customerId === undefined) {
      return;
    }
    this.loading = true;
    let createdBefore;

    if (this.comments.length) {
      const minDateAsMilliseconds = Math.min(...this.comments.map(({ createdAt }) => new Date(createdAt).getTime()));
      createdBefore = new Date(minDateAsMilliseconds);
    }

    customerResource
      .getCustomerComments(this.customerId, undefined, createdBefore)
      .then((resp) => {
        this.comments = [...this.comments, ...resp.data.comments];
        this.moreAvailable = resp.data.moreAvailable;
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  addComment(newComment: Comment) {
    this.comments.unshift(newComment);
    this.$emit('update')
  }

  updateComment(updComment: Comment) {
    this.comments = this.comments.map((comment) => (comment.commentId === updComment.commentId ? updComment : comment));
  }

  deleteComment(deleteCommentId: number) {
    this.comments = this.comments.filter((comment) => comment.commentId !== deleteCommentId);
    this.$emit('update')
  }
}
</script>

<style scoped></style>
