<template>
  <div
    :class="{
      'd-flex d-md-block align-start justify-space-between': true,
      'issue-side-bar-min-width': $vuetify.breakpoint.smAndUp,
    }"
  >
    <div class="d-block d-md-none">
      <v-btn icon color="primary" @click="back">
        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>
    </div>

    <div class="d-flex d-md-block align-center flex-row">
      <!-- STATUS -->
      <div class="sb-row">
        <div class="sb-row-title d-none d-md-block">Status</div>
        <div class="sb-row-content">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" :class="statusColor" small>
                {{ getIssueStatusName() }}
                <v-icon class="d-none d-md-block ml-1" v-if="canEditIssues" small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list v-if="canEditIssues">
              <v-list-item @click="changeStatusConfirm(item.value)" v-for="(item, index) in statusList" :key="index" dense>
                <v-list-item-title class="text-uppercase" :class="IssueHelper.getIssueStatusColor(item.value, true)">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <!-- PRIORITY -->
      <div class="sb-row ml-3 mt-md-4 ml-md-0">
        <div class="sb-row-title d-none d-md-block">Priority</div>
        <div class="sb-row-content">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text class="priority px-0 pr-0 pr-md-2" v-bind="attrs" v-on="on" small>
                <v-icon :color="IssueHelper.getIssuePriorityColor(issue.priority)">
                  {{ IssueHelper.getIssuePriorityIcon(issue.priority) }}
                </v-icon>
                <span class="d-none d-md-block">{{ IssueHelper.getIssuePriorityName(issue.priority) }}</span>
                <v-icon class="d-none d-md-block ml-1" v-if="canEditIssues" small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list v-if="canEditIssues">
              <v-list-item
                class="pl-1"
                @click="setPriority(item.value)"
                v-for="(item, index) in priorityList"
                :key="index"
                dense
              >
                <v-list-item-title class="text-uppercase">
                  <v-icon :color="IssueHelper.getIssuePriorityColor(item.value)">
                    {{ IssueHelper.getIssuePriorityIcon(item.value) }}
                  </v-icon>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <!-- AFFECTED DEVICES -->
      <div class="sb-row ml-3 mt-md-4 ml-md-0" :class="{ 'mb-md-4': !statusSummary.length }">
        <div class="sb-row-title d-none d-md-block">Affected devices</div>
        <div class="sb-row-content">
          <v-btn
            :loading="totalDeviceLoading"
            color="secondary"
            class="primary--text"
            @click="showAffectedDevices = true"
            small
          >
            {{ totalDevices }} device/s
          </v-btn>
        </div>
      </div>

      <!-- OTHER (will be moved to popup on mobile) -->
      <div class="relative">
        <v-btn icon color="primary" class="d-block d-md-none ml-3" @click="showOther = !showOther">
          <v-icon size="30">mdi-dots-vertical</v-icon>
        </v-btn>

        <div
          :class="{
            'sb-other d-none d-md-block': true,
            'show-other v-card v-sheet theme--dark pa-4': showOther,
          }"
        >
          <div v-if="statusSummary.length" class="sb-row mb-4 mt-md-2">
            <div class="sb-row-title align-self-start"><span v-if="showOther">Device Statuses</span></div>
            <div class="sb-row-content">
              <div>
                <v-tooltip bottom transition="fade-transition" v-for="sum in statusSummary" :key="sum.status">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on">
                      <v-badge
                        v-bind="attrs"
                        :color="IssueHelper.getDeviceStatusColor(sum.status, true)"
                        :content="sum.count"
                        inline
                        class="cursor-default"
                      ></v-badge>
                    </span>
                  </template>
                  <span>{{ getDeviceStatusName(sum.status) }}</span>
                </v-tooltip>
              </div>
              <div class="mt-2" @click.capture="showOther = false">
                <DeviceStats :issue="issue" />
              </div>
            </div>
          </div>

          <div class="sb-row mb-4">
            <div class="sb-row-title">Reporter</div>
            <div class="sb-row-content">
              {{ issue.createdBy }}
            </div>
          </div>

          <v-divider />

          <div class="grey--text text-caption mt-2">
            <span class="font-weight-bold mr-2">Created</span>
            {{ `${moment(issue.createdAt).format("lll")} by ${issue.createdBy}` }}

            <div v-if="issue.lastModifiedAt">
              <span class="font-weight-bold mr-2 mt-2">Updated</span>
              {{ `${moment(issue.lastModifiedAt).format("lll")} by ${issue.lastModifiedBy}` }}
            </div>
          </div>

          <div class="text-right">
            <v-btn
              v-if="canDeleteIssue"
              @click="deleteIssueConfirm"
              :loading="deleting"
              :disabled="deleting"
              text
              small
              color="red"
              class="mt-4"
              test-id="delete-issue-btn"
            >
              Delete issue
            </v-btn>
          </div>
          <IssueAffectedDevices
            :statusSummary="statusSummary"
            v-model="showAffectedDevices"
            :issue="issue"
            @changeTotalDevices="updateDeviceTotal"
            @changeStatus="getTotalDevices"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Issue from "@/types/Issue";
import IssueAffectedDevices from "@/components/issues/IssueAffectedDevices.vue";
import DeviceStats from "@/components/issues/DeviceStats.vue";
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import { IssueStatus } from "@/types/IssueStatus";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import { IssuePriority } from "@/types/IssuePriority";
import issueResource from "@/resources/IssueResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import moment from "moment";
@Component({
  components: { IssueAffectedDevices, DeviceStats },
})
export default class IssueSideBar extends Vue {
  @Prop({ default: null })
  issue!: Issue;

  moment = moment;
  IssueHelper = IssueHelper;
  cancelToken: CancelTokenSource | undefined = undefined;
  cancelStatusToken: CancelTokenSource | undefined = undefined;
  cancelPriorityToken: CancelTokenSource | undefined = undefined;
  showAffectedDevices = false;
  deleting = false;
  totalDeviceLoading = false;
  statusSummary: { status: IssueDeviceStatus; count: number }[] = [];
  totalDevices = 0;
  showOther = false;

  @Watch("issue", { immediate: true })
  onIssueChanged() {
    if (this.issue) {
      this.getTotalDevices();
    }
  }

  back() {
    this.$router.back();
  }

  get priorityList() {
    return Object.values(IssuePriority)
      .filter((v) => !isNaN(Number(v)))
      .map((v) => ({
        text: IssueHelper.getIssuePriorityName(v as number),
        value: v as number,
      }));
  }

  get statusList() {
    return Object.values(IssueStatus)
      .filter((v) => !isNaN(Number(v)))
      .map((v) => ({
        text: IssueHelper.getIssueStatusDisplayName(v as number),
        value: v as number,
      }));
  }

  get statusColor() {
    return IssueHelper.getIssueStatusColor(this.issue.status);
  }

  get canEditIssues() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues);
  }

  get canDeleteIssue() {
    return userProfileService.hasPermission(UserPermissionType.DeleteIssues) && this.issue;
  }

  getIssueStatusName() {
    return IssueHelper.getIssueStatusDisplayName(this.issue.status);
  }

  getDeviceStatusName(status: IssueDeviceStatus) {
    return IssueHelper.getDeviceStatusDisplayName(status);
  }

  updateDeviceTotal(difference: number) {
    this.totalDevices = this.totalDevices + difference;
    this.getTotalDevices();
  }

  getTotalDevices() {
    if (!this.issue) {
      return;
    }

    this.totalDeviceLoading = true;

    issueResource
      .getIssueDevicesSummary(this.issue.issueId)
      .then((resp) => {
        this.totalDevices = resp.data.totalAffectedDevices;
        this.statusSummary = resp.data.statusSummary.filter((v) => v.count).sort((a, b) => a.status - b.status);
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.totalDeviceLoading = false;
      });
  }

  setPriority(newPriority: IssuePriority) {
    const previousStatus = this.issue.status;
    this.issue.priority = newPriority;

    // Cancel existing request
    if (this.cancelPriorityToken) {
      this.cancelPriorityToken.cancel();
    }

    if (!this.canEditIssues || !this.issue) {
      return;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.cancelPriorityToken = axios.CancelToken.source();
      issueResource
        .patchIssue(this.issue.issueId, { priority: newPriority }, this.cancelPriorityToken)
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.issue.status = previousStatus;
        })
        .finally(() => {
          this.cancelPriorityToken = undefined;
        });
    }, 10);
  }

  changeStatusConfirm(newStatus: IssueStatus) {
    if (!this.canEditIssues || !this.issue) {
      return;
    }

    const isAllDevicesResolved = this.statusSummary.every(
      ({ status, count }) => status === IssueDeviceStatus.Resolved || !count
    );
    if (!this.statusSummary.length || isAllDevicesResolved) {
      if (newStatus === this.issue.status) {
        return;
      }
      return this.setStatus(newStatus);
    }

    if (newStatus !== IssueStatus.Closed) {
      return this.setStatus(newStatus);
    }

    this.$confirm
      .show(`Do you want to update status of all linked devices to Resolved?`, {
        width: 525,
        persistent: true,
        buttonTrueText: "Yes",
        buttonFalseText: "No",
        buttonCancelText: "Cancel",
      })
      .then((result) => {
        if (result === null) {
          return;
        }

        if (result) {
          this.setStatus(newStatus, true);
          return;
        }
        this.setStatus(newStatus, false);
      });
  }
  setStatus(newStatus: IssueStatus, resolveLinkedDevices: boolean = false) {
    // Cancel existing request
    if (this.cancelStatusToken) {
      this.cancelStatusToken.cancel();
    }

    if (!this.canEditIssues || !this.issue) {
      return;
    }

    const previousStatus = this.issue.status;
    this.issue.status = newStatus;

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.cancelStatusToken = axios.CancelToken.source();
      issueResource
        .patchIssue(
          this.issue.issueId,
          { status: newStatus },
          this.cancelStatusToken,
          resolveLinkedDevices ? { resolveLinkedDevices } : undefined
        ).then(()=> {
          if(resolveLinkedDevices) {
            this.getTotalDevices()
          }
        })
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.issue.status = previousStatus;
        })
        .finally(() => {
          this.cancelStatusToken = undefined;
        });
    }, 10);
  }

  deleteIssueConfirm() {
    if (!this.canDeleteIssue || this.issue == null) {
      return;
    }

    this.$confirm.show(`Delete '${this.issue.name}' issue?`).then((confirmed) => {
      if (confirmed) {
        this.deleteIssue();
      }
    });
  }

  deleteIssue() {
    if (!this.canDeleteIssue) {
      return;
    }

    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
    this.deleting = true;
    this.cancelToken = axios.CancelToken.source();
    issueResource
      .deleteIssue(this.issue.issueId, this.cancelToken)
      .then(() => {
        infoMessageService.clear();
        infoMessageService.show(InfoMessageType.Success, `Issue '${this.issue.name}' deleted`);
        this.$emit("remove");
        this.$router.push(`/support/issues`);
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
        this.cancelToken = undefined;
      });
  }
}
</script>

<style scoped>
.issue-side-bar-min-width {
  min-width: 320px;
}
.sb-row {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}
.sb-row-title {
  width: 150px;
}
.sb-row-content {
  flex-grow: 1;
}
.show-other {
  position: absolute;
  right: 0.75rem;
  display: block !important;
  z-index: 1;
  margin-top: 0.4rem;
  background-color: var(--v-darkgrey-darken1);
}
.title-line-height {
  line-height: 1;
}

.priority {
  min-width: 0 !important;
}

.stats {
  margin-left: 2px;
  text-transform: none;
  font-size: 14px;
}
</style>
