<template>
  <div style="height: 100%; position: relative;">
    <iframe
      :src="logsUrl"
      style="width: 100%; height: 100%;"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script lang="ts">
import commonHelper from "@/helpers/commonHelper";
import appConfigService from "@/services/AppConfigService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DeviceLogsView extends Vue {

  @Prop()
  readonly deviceId!: number | null;
  
  logsUrl = "";

  get dashboardId() {
    return appConfigService.kibanaLogsDashboardId;
  }

  get panelId() {
    return appConfigService.kibanaLogsPanelId;
  }

  @Watch("deviceId", { immediate: true })
  onValueChanged() {
    if (this.deviceId) {
      this.logsUrl = `${commonHelper.apiHost}/kibana/app/dashboards#/view/${this.dashboardId}?embed=true&show-query-input=true&show-time-filter=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))&_a=(expandedPanelId:'${this.panelId}',filters:!(),query:(language:kuery,query:'ClientId : ${this.deviceId}'))`;
    } else {
      this.logsUrl = "";
    }
  }
}
</script>