<template>
  <v-container fluid style="min-height: 100%; height: 100%">
    <v-flex class="d-flex flex-column" style="height: 100%">
      <v-card class="d-flex flex-grow-0 mb-3 map-top">
        <v-card-title class="d-block d-sm-flex map-top-inner align-end">
          <v-text-field v-model="deviceId" label="Device ID" hide-details class="flex-grow-0 mt-md-0" style="width:150px" :rules="deviceIdRules" />
          <v-select v-model="selectedDateRange" :items="dateRangeOptions" label="Date range" class="ml-0 ml-md-4 mt-2 mt-md-0 flex-grow-0" style="width:150px" hide-details />
          <div v-if="selectedDateRange == 'define'" class="map-date-range-picker-wrap">
            <v-text-field
              v-model="dateRangeText"
              label="From/to date"
              hide-details
              style="width: 280px"
              class="ml-0 ml-md-4 mt-2 mt-md-0"
            ></v-text-field>
            <v-menu
              v-model="showCalendar"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              nudge-bottom="5"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small>
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="datesRange"
                no-title
                scrollable
                range
                :show-current="moment().format('YYYY-MM-DD')"
              ></v-date-picker>
            </v-menu>
          </div>
          <v-btn small color="primary" class="ml-0 ml-md-5 mt-2 mt-md-0" :loading="loading" @click="getLocationHistory()">Search</v-btn>
        </v-card-title>
      </v-card>
      <v-card class="d-flex flex-grow-1" style="min-height: 400px">
        <div class="map-wrap">
          <l-map v-if="showMap" ref="map" class="map-container" :zoom.sync="mapZoom" :center.sync="mapCenter" :bounds.sync="mapBounds">
            <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <!-- LINE -->
            <l-polyline :lat-lngs="polyline.latLngs" :color="polyline.color" :weight="2" :smoothFactor="0"></l-polyline>
            <!-- LOCATION MARKERS -->
            <l-rotated-marker
              v-for="(marker, ind) in locationMarkers"
              :key="ind"
              :lat-lng="[marker.latitude, marker.longitude]"
              :rotationAngle="marker.heading"
              :rotationOrigin="locationMarkerIcon.roatationOrigin"
            >
              <l-icon
                :icon-url="locationMarkerIcon.iconUrl"
                :icon-size="locationMarkerIcon.iconSize"
                :icon-anchor="locationMarkerIcon.iconAnchor"
                :tooltipAnchor="[20, -10]"
                :className="getMarkerColor(marker.speed)"
              ></l-icon>
              <l-tooltip>
                <div class="poi-tooltip">
                  <h4>
                    {{moment(marker.timestamp).local().format("lll")}}<br/>
                    Speed: {{marker.speed}} km/h
                  </h4>
                </div>
              </l-tooltip>
            </l-rotated-marker>
          </l-map>
        </div>
      </v-card>
    </v-flex>
    <v-overlay absolute :value="loading" opacity="0.1" z-index="999">
      <v-progress-linear indeterminate color="secondary" style="width: 200px" height="5px"></v-progress-linear>
      <!-- <v-progress-circular color="secondary" indeterminate size="32"></v-progress-circular> -->
    </v-overlay>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import { CancelTokenSource } from "axios";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LControl, LPolyline } from "vue2-leaflet";
import LRotatedMarker from "vue2-leaflet-rotatedmarker";
import MapHelper from "@/helpers/mapHelper";
import deviceResource from "@/resources/DeviceResource";
import DeviceLocation from "@/types/DeviceLocation";
import MapIcons from "@/types/MapIcons";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  name: "DeviceLocationHistory", // name is needed for keep-alive
  components: {
    L,
    LMap,
    LTileLayer,
    LMarker,
    LRotatedMarker,
    LControlScale,
    LIcon,
    LTooltip,
    LControl,
    LPolyline,
  },
})
export default class DeviceLocationHistory extends Vue {
  moment = moment;

  loading = false;
  cancelToken: CancelTokenSource | undefined = undefined;

  selectedDateRange: string = "lastHour";
  dateRangeOptions = [
    { text: "Last hour", value: "lastHour" },
    { text: "Last 3 hours", value: "last3Hours" },
    { text: "Last 6 hours", value: "last6Hours" },
    { text: "Last 12 hours", value: "last12Hours" },
    { text: "Last 24 hours", value: "last24Hours" },
    { text: "Define", value: "define" },
  ];

  showCalendar = false;
  @Watch("showCalendar")
  onShowCalendar() {
    if (this.showCalendar == false) {
      this.dateInd = 0;
    }
  }
  fromDate: Date = moment().add(-1, "days").startOf("day").toDate();
  toDate: Date = moment().startOf("day").toDate();
  dateInd = 0;

  locationMarkerIcon = MapIcons.smallArrow;
  locationMarkers: DeviceLocation[] = [];
  polyline = {
    latLngs: [] as number[][],
    color: "green"
  }

  deviceId: number | null = null;
  deviceIdRules = [(v: string) => (!v || /^\d+$/.test(v)) || "Device ID should be valid number."];

  dateRangeTextSeparator = " ~ ";
  dateRangeText = moment(this.fromDate).format("YYYY-MM-DD HH:mm") + this.dateRangeTextSeparator + moment(this.toDate).format("YYYY-MM-DD HH:mm");

  activated() {
    // Prefill device id and trigger search
    if (this.$route.query.deviceId) {
      this.deviceId = Number(this.$route.query.deviceId);
      // Use timeout to give map object some time to init
      setTimeout(() => {
        this.getLocationHistory();
      }, 250);
    }

    setTimeout(() => {
      this.showMap = true;
    }, 10);
  }

  deactivated() {
    this.showMap = false;
  }

  get datesRange() {
    var arr = this.dateRangeText.split(this.dateRangeTextSeparator);
    return [moment(arr[0]).format("YYYY-MM-DD"), moment(arr[1]).format("YYYY-MM-DD")];
  }
  set datesRange(val: string[]) {
    if (this.dateInd == 0) {
      this.fromDate = moment(val[0]).startOf("day").toDate();
      this.toDate = moment(this.fromDate).add(1, "days").add(-1, "seconds").toDate();
      this.dateInd++;
    } else {
      this.toDate = moment(val[0]).startOf("day").add(1, "days").add(-1, "seconds").toDate();
      this.dateInd = 0;
    }

    this.dateRangeText = moment(this.fromDate).format("YYYY-MM-DD HH:mm") + this.dateRangeTextSeparator + moment(this.toDate).format("YYYY-MM-DD HH:mm");
  }

  showMap = false;
  mapZoom = 5;
  mapCenter = [64, 19];
  mapBounds: any = null;
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };

  getLocationHistory() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Get date range
    var dateRange = this.getDateRange();

    // Validate id and dates
    if (!dateRange.length) {
      infoMessageService.show(InfoMessageType.Error, "Error parcing date range");
      return;
    }

    if (!this.deviceId) {
      infoMessageService.show(InfoMessageType.Error, "Missing device ID");
      return;
    }

    this.loading = true;
    this.locationMarkers = [];
    this.polyline.latLngs = [];

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      deviceResource
        .getDeviceLocationHistory(this.deviceId!, dateRange[0], dateRange[1])
        .then((resp) => {
          this.locationMarkers = resp.data;

          var lines: number[][] = [];
          this.locationMarkers.forEach(item => {
            lines.push([item.latitude, item.longitude]);
          });

          this.polyline.latLngs = lines;
          
          setTimeout(()=> {
            this.recenterMap();
          }, 10);

          // show location points limit count
          if (this.locationMarkers.length == 500) {
            infoMessageService.show(InfoMessageType.Warning, "The result was limited to 500 location points.");
          }
        })
        .catch(deviceResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  getDateRange(): Date[] {
    if (this.selectedDateRange == "lastHour") {
      return [ moment().utc().add(-1, "hours").toDate(), moment().utc().toDate() ];
    } else if (this.selectedDateRange == "last3Hours") {
      return [ moment().utc().add(-3, "hours").toDate(), moment().utc().toDate() ];
    } else if (this.selectedDateRange == "last6Hours") {
      return [ moment().utc().add(-6, "hours").toDate(), moment().utc().toDate() ];
    } else if (this.selectedDateRange == "last12Hours") {
      return [ moment().utc().add(-12, "hours").toDate(), moment().utc().toDate() ];
    } else if (this.selectedDateRange == "last24Hours") {
      return [ moment().utc().add(-24, "hours").toDate(), moment().utc().toDate() ];
    } else if (this.selectedDateRange == "define") {
      var arr = this.dateRangeText.split(this.dateRangeTextSeparator);
      return [ moment(arr[0]).utc().toDate(), moment(arr[1]).utc().toDate() ];
    }

    return [];
  }

  getMarkerColor(speed: number) {
    if (speed > 120) {
      return "marker-color-red";
    } else if (speed > 80) {
      return "marker-color-orange";
    } else if (speed > 30) {
      return "marker-color-green";
    } else if (speed > 0) {
      return "marker-color-blue";
    } else {
      return "marker-color-gray";
    }
  }

  recenterMap() {
    if (this.locationMarkers.length) {
      var southWest = [0, 0];
      var northEast = [0, 0];

      this.locationMarkers.forEach(item => {
        if (southWest[0] == 0 || southWest[0] > item.latitude) {
          southWest[0] = item.latitude;
        }
        if (southWest[1] == 0 || southWest[1] > item.longitude) {
          southWest[1] = item.longitude;
        }
        if (northEast[0] == 0 || northEast[0] < item.latitude) {
          northEast[0] = item.latitude;
        }
        if (northEast[1] == 0 || northEast[1] < item.longitude) {
          northEast[1] = item.longitude;
        }
      });

      this.mapBounds = [southWest, northEast];
    }
  }
}
</script>

<style scoped>
.map-top {
  z-index: 99;
}
.map-wrap {
  width: 100%;
  height: 100%;
}
.poi-filter {
  width: 180px;
  font-size: 14px;
}
.map-date-range-picker-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.map-top-inner {
  width: 100%;
}
</style>