<template>
  <div>
    <div class="d-flex">
      <div class="d-flex flex-wrap">
        <FileBox
          :key="file.attachmentId"
          v-for="(file, index) in files"
          :file="file"
          :index="index"
          :disabled="disabled"
          v-on:remove="onRemoveFile"
          v-on:open="openFile"
        />
        <FileBox
          :key="`${index}-local`"
          v-for="(file, index) in localFiles"
          :file="file"
          :index="index"
          :disabled="disabled"
          v-on:remove="removeLocalFile"
          v-on:open="openFile"
        />
        <FileInput
          v-if="!disabled"
          @upload="selectImages"
          :disabled="isUploading || isRemoving"
          :hint="maxSize"
          :small="!localFiles.length && !files.length"
        />
      </div>
    </div>
    <div v-if="!disabled" class="mt-3 font-weight-light text-caption text--disabled">
      Max file size is 100 MB
    </div>
    <div v-if="isUploading">
      <v-progress-linear class="my-1" height="2" :value="progress" />
      <div class="text-center primary--text">Uploading file {{ uploadMessage }} ...</div>
    </div>

    <FileViewer :openedFile="openedFile" :onClose="closeViewer" />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import fileResource from "@/resources/FileResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import FileViewer from "./FileViewer.vue";
import FileBox from "./FileBox.vue";
import FileInput from "./FileInput.vue";

@Component({ components: { FileViewer, FileBox, FileInput } })
export default class Attachments extends Vue {
  isUploading = false;
  isRemoving = false;
  uploadMessage = "";
  progress = 0;
  localFiles: File[] = [];
  removedFiles: AttachedFile[] = [];
  openedFile = {};

  @Prop({
    default: () => [],
  })
  readonly files!: AttachedFile[];

  @Prop()
  readonly targetType!: AttachmentTargetType;

  @Prop()
  readonly targetId!: number;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: 100 })
  readonly maxSize!: number;

  // isActionByCommand disables immediately uploading or removing of files. To upload or remove by command, use ref
  @Prop({ default: false })
  readonly isActionByCommand!: boolean;

  get hint() {
    return `Max file size is ${this.maxSize}MB`;
  }

  get allFiles() {
    return this.isActionByCommand ? [...this.files, ...this.localFiles] : this.files;
  }

  closeViewer() {
    this.openedFile = {};
  }

  uploadLocalFiles() {
    return this.uploadFiles(this.localFiles);
  }

  onUploadProgress({ loaded, total }: { loaded: number; total: number }) {
    this.progress = (loaded * 100) / total;
    if (this.progress >= 100) {
      this.progress = 0;
      if (this.isUploading) this.isUploading = false;
    }
  }

  selectImages(files: File[]) {
    let newFiles = files.filter((f) => f instanceof File);
    if (this.maxSize) {
      newFiles = newFiles.filter((f) => {
        if (f.size > this.maxSize * 1048576) {
          infoMessageService.show(
            InfoMessageType.Error,
            `The ${f.name} file you are trying to upload is too big`
          );
          return false;
        }
        return true;
      });
    }

    if (!newFiles.length) {
      return;
    }

    if (this.isActionByCommand) {
      this.localFiles.push(...newFiles);
      this.$emit("updateLocalFiles", this.localFiles);
      return;
    }

    this.uploadFiles(files);
  }

  async uploadFiles(newFiles: File[]) {
    const uploadedFiles: any = [];
    const files = [...newFiles];
    this.localFiles = [];

    for (const file of files) {
      this.progress = 0;
      this.$emit("update", [...this.files]);
      this.isUploading = true;
      this.progress = 1;
      this.uploadMessage = file.name;

      await fileResource
        .attachFile(this.targetType, this.targetId, [file], this.onUploadProgress)
        .then((resp) => {
          if (!this.isActionByCommand) {
            infoMessageService.show(
              InfoMessageType.Success,
              `File ${file.name} are uploaded`
            );
          }

          if (resp.data.errors) {
            resp.data.errors.forEach((message) =>
              infoMessageService.show(InfoMessageType.Error, message)
            );
          }

          uploadedFiles.push(...resp.data.uploaded);
          this.$emit("update", [...this.files, ...resp.data.uploaded]);
          this.uploadMessage = "";
        })
        .catch((e) => {
          this.$emit("update", [...this.files]);
          this.progress = 0;
          if (e.response && e.response.status == 413) {
            infoMessageService.show(
              InfoMessageType.Error,
              `The file you are trying to upload is too big`
            );
          } else {
            fileResource.defaultErrorHandler(e);
          }
        })
        .finally(() => {
          this.isUploading = false;
        });
    }

    if (this.isActionByCommand) {
      this.$emit("updateLocalFiles", this.localFiles);
    }
    return uploadedFiles;
  }

  openFile(file: AttachedFile) {
    this.openedFile = file;
  }

  removeLocalFile(index: number) {
    this.localFiles.splice(index, 1);
    this.$emit("updateLocalFiles", this.localFiles);
  }

  removeFile(index: number) {
    this.isRemoving = true;
    const file = this.files[index];
    fileResource
      .deleteFile(file.attachmentId)
      .then(() => {
        infoMessageService.show(
          InfoMessageType.Success,
          `File ${file.filename} is deleted`
        );
        this.$emit(
          "update",
          this.files.filter((_, ind) => ind !== index)
        );
      })
      .catch(fileResource.defaultErrorHandler)
      .finally(() => {
        this.isRemoving = false;
      });
  }

  async removeFiles(removedFiles: AttachedFile[] = this.removedFiles) {
    const files = [...removedFiles];
    this.removedFiles = [];

    for (const file of files) {
      this.progress = 0;
      this.isRemoving = false;

      await fileResource
        .deleteFile(file.attachmentId)
        .catch((e) => {
          this.$emit("update", [...this.files, file]);
          this.progress = 0;
          if (e.response && e.response.status == 413) {
            infoMessageService.show(
              InfoMessageType.Error,
              `The file you are trying to upload is too big`
            );
          } else {
            fileResource.defaultErrorHandler(e);
          }
        })
        .finally(() => {
          this.isRemoving = false;
        });
    }
  }

  onRemoveFile(index: number) {
    if (!this.isActionByCommand) {
      this.removeFile(index);
      return;
    }
    this.removedFiles.push(this.files[index]);
    this.$emit(
      "update",
      this.files.filter((_, ind) => ind !== index)
    );
  }
}
</script>

<style scoped></style>
