<template>
  <v-overlay v-model="show" opacity=".33" z-index="101">
    <v-dialog
      ref="sideSheet"
      v-bind:value="value"
      v-bind:heading="heading"
      v-model="show"
      persistent
      :no-click-animation="noClickAnimation"
      @click:outside="clickOutside"
      origin="top center 0"
      transition="slide-x-reverse-transition"
      class="side-sheet-dialog"
      :content-class="'side-sheet ' + contentClass"
      hide-overlay
      @keydown.esc="show = false"
    >
      <v-card flat rounded="0">
        <div class="d-flex flex-column" style="height: 100%">
          <v-toolbar dense flat color="primary dark--text" class="flex-grow-0">
            <v-toolbar-title>{{ heading }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <slot name="heading-menu" />
              <v-btn icon @click="show = false">
                <v-icon color="dark">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <div class="flex-grow-0">
            <slot name="tabs"></slot>
          </div>
          <div class="pa-4 flex-grow-1 overflow-auto" style="overflow-x: hidden !important">
            <slot />
          </div>
          <div class="side-sheet-actions pa-4 flex-grow-0 text-right d-flex">
            <slot name="actions" />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SideSheet extends Vue {
  @Prop({ default: false })
  readonly value!: boolean;

  @Prop()
  readonly heading!: string;

  @Prop()
  readonly contentClass!: string;

  @Prop({ default: false })
  readonly noClickAnimation!: boolean;

  mounted() {
    // @ts-ignore
    if(this.$refs.sideSheet?.$refs?.content){
      // @ts-ignore
       this.$refs.sideSheet?.$refs?.content.setAttribute('data-side-sheet', '')
    }
  }
  
  clickOutside(e: PointerEvent) {
    const target = e.target as HTMLDivElement;
    if (target?.classList.contains("v-overlay__scrim")) {
      this.$emit("input", false);
    }
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit("input", value);
  }
}
</script>
