<template>
  <div>
    <v-menu bottom offset-y right z-index="100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn plain small :ripple="false" min-width="0" v-bind="attrs" v-on="on" class="px-0">
          <v-icon>mdi-account-circle</v-icon>
          <span v-if="user" class="user-profile-name ml-1">{{ user.username }}</span>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="toggleTheme">
          <v-list-item-icon class="mr-2">
            <v-icon small>mdi-brightness-6</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Light/dark theme</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon class="mr-2">
            <v-icon small>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sign out</v-list-item-title>
        </v-list-item>
        <v-list-item class="app-info">
          <v-list-item-title class="pt-2">
            <div class="ver-info">APP v{{ appStatus.appVersion }}</div>
            <div class="ver-info">API v{{ appStatus.apiVersion }}</div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import themeSwitcher from "@/services/ThemeSwitcher";
import userProfileService from "@/services/UserProfileService";
import authService from "@/services/AuthService";
import appStatusService from "@/services/AppStatusService";
import authResource from "@/resources/AuthResource";

@Component
export default class AppBar extends Vue {
  appStatus = appStatusService;

  get user() {
    return userProfileService.currentUser;
  }

  logout() {
    authResource.signOut()
      .then((resp) => {
        authService.signOut();
      })
      .catch(authResource.defaultErrorHandler);
  }

  toggleTheme() {
    var isDark = themeSwitcher.toggleTheme();
    this.$vuetify.theme.dark = isDark;
  }
}
</script>

<style scoped>
.user-profile-name { max-width: 120px; overflow: hidden; text-overflow: ellipsis; }
.app-info {
  min-height: 0;
}
.ver-info {
  text-align: right;
  font-size: 0.66rem;
  font-weight: 400;
  opacity: 0.3;
}
</style>