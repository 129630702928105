<template>
  <div>
    <v-expand-transition class="transition">
      <div v-show="show" class="filter-container">
        <div class="filter mb-2">
          <template v-for="section in filter">
            <FilterSelect :key="section.filterName" :data="section" />

            <div class="label" v-for="item in section.selected" :key="`${section.filterName}-${item.value}`">
              <v-expand-x-transition appear>
                <div class="d-flex align-center">
                  <div class="label-text">{{ item.text }}</div>
                  <v-btn x-small icon class="close-btn" @click="removeItem(section, item.value)">
                    <v-icon size="13">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-expand-x-transition>
            </div>

            <span :key="`${section.filterName}-margin`" :class="{ 'mr-lg-6 mr-md-4 mr-sm-2': section.selected.length }" />
            <div :key="`${section.filterName}-divider`" class="d-block d-sm-none mobile-divider" />
          </template>

          <v-divider v-if="!$vuetify.breakpoint.xs" vertical class="my-2 mx-1" />
          <!--  Hide -->
          <v-btn
            text
            small
            @click="closeFilter"
            class="text-capitalize"
            :class="{ 'mobile-action-btn': $vuetify.breakpoint.xs }"
          >
            Hide 
          </v-btn>

          <!-- CLEAR -->
          <v-btn
            text
            small
            @click="clearFilter"
            class="text-capitalize"
            :class="{ 'mobile-action-btn': $vuetify.breakpoint.xs }"
          >
             Clear 
          </v-btn>
        </div>
      </div>
    </v-expand-transition>

    <div transition="fade-transition" v-show="show" class="table-divider" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FilterSelect from "./FilterSelect.vue";
import TableFilter from "@/types/TableFilter";

@Component({ components: { FilterSelect }})
export default class Filters extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: {} })
  filter!: TableFilter[];

  @Watch("filter", { deep: true })
  onChangeFilter() {
    this.$setComponentQuery("filter", Object.values(this.filterQuery).length ? this.filterQuery : null);
    this.$emit("update", this.filter);
  }

  get filterQuery() {
    return this.filter.reduce((acc: { [key: string]: any[] }, item) => {
      if (item.selected.length) {
        acc[item.filterName] = item.selected;
      }
      return acc;
    }, {});
  }

  removeItem(section: TableFilter, itemId: number) {
    const sectionInd = this.filter.findIndex(({ filterName }) => filterName === section.filterName);

    if (sectionInd == -1) return;

    this.filter[sectionInd].selected = this.filter[sectionInd].selected.filter(({ value }) => itemId !== value);
  }

  closeFilter() {
    this.$emit("close");
  }

  clearFilter() {
    this.filter.forEach((item) => (item.selected = []));
  }
}
</script>

<style scoped>
.transition {
  max-width: 100%;
  width: 100%;
}

.filter-container {
  width: 100%;
  overflow: hidden;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.table-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

:root[theme="dark"] .table-divider {
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
}

.label {
  font-size: 13px;
  font-weight: 500;
  margin: 5px;
  white-space: nowrap;
  max-width: 100%;
}

.label-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mobile-divider {
  width: 100%;
  margin-bottom: 3px;
  opacity: 0.7;
}

.close-btn {
  width: 16px;
  height: 16px;
  margin-left: 0.3em;
}

.mobile-action-btn {
  width: 50%;
  margin-top: 5px;
}
</style>
