<template>
  <v-app class="auth-layout">
    <InfoMsg />
    <keep-alive include="Login">
      <router-view></router-view>
    </keep-alive>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import InfoMsg from "@/components/layout/InfoMessage.vue";

@Component({
  components: { InfoMsg },
})
export default class AuthLayout extends Vue {}
</script>

<style>
.auth-layout.theme--dark {
  background-color: #272727 !important;
}
.auth-layout[theme="light"] {
  background-color: #f5f5f5 !important;
}
.auth-layout .form-auth {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.auth-layout .form-auth-heading {
  margin-bottom: 1.2rem;
  margin-top: 2rem;
  text-align: center;
  font-size: 2.7rem;
  line-height: 110%;
  font-weight: 500;
}
.auth-layout .form-auth-heading img {
  width: auto;
  height: 40px;
  margin-right: 0.5em;
}
.auth-layout .input-field input:focus {
  border-bottom: 1px solid #fedd10 !important;
  box-shadow: 0 1px 0 0 #fedd10 !important;
}
.auth-layout .submit-button {
  display: block;
  width: 100%;
  margin-top: 1.7rem;
  position: relative;
  height: 2.8rem !important;
  color: #000;
  background-color: #fedd10 !important;
  border-radius: 2px;
}
.auth-layout .submit-button .preloader-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -18px 0 0 -18px;
}
.auth-layout .submit-button[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.3) !important;
}

.auth-layout .link-container {
  text-align: center;
}
.auth-layout .link-btn {
  color: #bebebe;
  text-decoration: underline;
}
</style>
