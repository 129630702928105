<template>
  <div>
    <v-dialog v-model="dialog" overlay-opacity="0.75" overlay-color="#111">
      <transition name="fade">
        <div :class="toolsStyles" v-if="url && isTools">
          <v-btn
            v-if="url && !localFileUrl"
            small
            text
            class="ma-2 white--text"
            @click="download"
          >
            Download
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
          <v-btn icon small class="ma-2 white--text" @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </transition>
      <div class="wrap">
        <div v-if="isVideo && url" class="overflow-hidden flex-grow-1">
          <h4 class="text-center" v-if="isUnsupportedMediaErr">
            Video not supported. Download the video <a href="" @click.prevent="download">here</a>.
          </h4>
          <video v-if="!isUnsupportedMediaErr" controls autoplay class="video" :src="url" :type="mime" @error="mediaErrorHandler">
            <source :src="url" :type="mime" />
            Your browser doesn't support HTML5 video tag.
          </video>
        </div>

        <div v-if="isAudio && url" class="overflow-hidden flex-grow-1">
          <audio controls autoplay class="audio">
            <source :src="url" :type="mime" />
          </audio>
        </div>

        <object
          v-if="isPdf && url"
          :data="url"
          type="application/pdf"
          frameborder="0"
          width="100%"
          height="100%"
        >
          <embed :src="url" width="100%" height="100%" />
        </object>

        <div v-if="isImage && url" class="image" :style="imgStyle" />
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AttachedFile from "@/types/AttachedFile";
import FileHelper from "@/helpers/fileHelper";
import commonHelper from "@/helpers/commonHelper";

@Component({})
export default class FileViewer extends Vue {
  // isTools is a workaround to remove tool button jumps while appear
  isTools = false;
  dialog = false;
  localFileUrl = "";
  isUnsupportedMediaErr = false;

  @Prop()
  openedFile!: AttachedFile | File;

  @Prop()
  readonly onClose!: () => {};

  @Watch("dialog")
  onChangeDailog() {
    if (!this.dialog) {
      this.isTools = false;

      if (this.onClose) this.onClose();

      if (this.localFileUrl) {
        URL.revokeObjectURL(this.localFileUrl);
        this.localFileUrl = "";
      }
    }
  }

  @Watch("isVideo")
  onVideoChange() {
    if (!this.isVideo) this.isUnsupportedMediaErr = false;
  }

  @Watch("openedFile")
  onChange() {
    if (this.openedFile instanceof File || this.openedFile.url) {
      this.dialog = true;

      if (this.openedFile instanceof File) {
        this.localFileUrl = URL.createObjectURL(this.openedFile);
      }

      // isTools is a workaround to remove tool button jumps while appear
      setTimeout(() => (this.isTools = true), 500);
    }
  }

  mediaErrorHandler(e: ErrorEvent) {
    const target = e.target as HTMLVideoElement;
    if (target.error?.code === 4) {
      this.isUnsupportedMediaErr = true;
    }
  }

  get name() {
    return this.openedFile instanceof File
      ? this.openedFile.name
      : this.openedFile?.filename;
  }

  get mime() {
    return this.openedFile instanceof File ? this.openedFile.type : this.openedFile?.mime;
  }

  get url() {
    return this.openedFile instanceof File
      ? this.localFileUrl
      : `${commonHelper.apiHost}${this.openedFile.url}`;
  }

  get isAudio() {
    return FileHelper.isAudio(this.mime);
  }
  get isVideo() {
    return FileHelper.isVideo(this.mime);
  }

  get isImage() {
    return FileHelper.isImage(this.mime);
  }

  get isPdf() {
    return FileHelper.isPdf(this.mime);
  }

  get isTabletAndDown() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get toolsStyles() {
    return { tools: true, "laptop-tools": false };
  }

  get imgStyle() {
    return `background-image: url("${this.url}");`;
  }

  download() {
    if (this.openedFile instanceof File) return;

    const a = document.createElement("a");
    a.href = `${commonHelper.apiHost}${this.openedFile.downloadUrl}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
</script>

<style scoped>
object {
  width: 100%;
}
.wrap {
  height: calc(100vh - 150px);
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools {
  text-align: right;
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 100;
}

.laptop-tools {
  top: 75px;
  right: 25px;
}

.image {
  height: calc(100vh - 150px);
  width: 100%;
  background-size: contain;
  background-position: center;
}

.video {
  height: calc(100vh - 160px);
  width: 100%;
}

.audio {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
