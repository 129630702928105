<template>
  <div class="wrap d-block d-md-flex">
    <v-col  sm="12" md="6" class="map-container mb-md-0 mb-10">
      <Heatmap />
    </v-col>
    <v-col  sm="12" md="6"  class="left-container">
      <div class="chart-container mt-md-0 mt-5 ">
        <ActiveDevicesChart />
      </div>
      <div class="chart-container">
        <ActiveDevicesHourlyChart />
      </div>
      <div class="device-firmware-chart-container">
        <DeviceFirmwareChart />
      </div>
    </v-col>
  </div>
</template>

<script lang="ts">
import themeSwitcher from "@/services/ThemeSwitcher";
import { Component, Vue } from "vue-property-decorator";
import ActiveDevicesChart from "@/components/dashboard/ActiveDevicesChart.vue";
import ActiveDevicesHourlyChart from "@/components/dashboard/ActiveDevicesHourlyChart.vue";
import DeviceFirmwareChart from "@/components/dashboard/DeviceFirmwareChart.vue";
import Heatmap from "@/components/dashboard/Heatmap.vue";

@Component({
  name: "Dashboard", // name is needed for keep-alive
  components: { ActiveDevicesChart, ActiveDevicesHourlyChart, DeviceFirmwareChart, Heatmap },
})
export default class Dashboard extends Vue {
  get theme() {
    return themeSwitcher.isDark ? "dark" : "light";
  }
}
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
}
.map-container {
  box-sizing: border-box;
}

.left-container {
  height: 100%;
  /* width: 50%; */
}
.chart-container {
  height: 30%;
}

.device-firmware-chart-container {
  height: 40%;
}

@media (max-width: 959px) { 
  .wrap {
    height: auto;
    min-height: 100% !important;
  }
  .map-container {
    display: block !important;
    height: 500px;
    height: 50vh;
  }
  .left-container {
    display: block !important;
    height: auto;
  }
  .chart-container, .device-firmware-chart-container {
    height: 250px;
    height: 30vh;
  }
}
</style>
