<template>
  <v-container fluid style="min-height: 100%; height: 100%">
    <v-flex class="d-flex flex-column" style="height: 100%">
      <v-card class="d-flex flex-grow-1" style="min-height: 400px">
        <div class="map-wrap">
          <l-map
            ref="map"
            class="map-container"
            :options="options"
            :zoom.sync="mapZoom"
            :center="mapCenter"
            :noBlockingAnimations="true"
            @contextmenu="openMapContextMenu"
            @update:bounds="mapBoundsUpdated"
          >
            <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <l-control position="topright">
              <div class="text-right">
                <div class="d-flex no-wrap mb-4 mt-2 justify-end">
                  <v-autocomplete
                    v-model="geocodeSelectedItem"
                    :items="geocodeItems"
                    :loading="geocodeLoading"
                    :search-input.sync="geocodeSearchTerm"
                    color="secondary"
                    dense
                    flat
                    clearable
                    light
                    hide-no-data
                    no-filter
                    label="Address search"
                    item-text="formatted_address"
                    :loader-height="2"
                    return-object
                    hide-details
                    :disabled="loading"
                    class="poi-map-search-input flex-grow-0"
                  ></v-autocomplete>
                </div>

                <div class="d-flex no-wrap poi-filter">
                  <v-btn small icon color="secondary" @click="reload" :disabled="loading" title="Reload POI">
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>

                  <v-btn
                    small
                    text
                    @click="showFilters = true"
                    title="Show filters"
                    color="secondary"
                    class="d-flex flex-grow-1 px-1 ml-1"
                    :disabled="loading"
                  >
                    <v-icon>mdi-filter-variant</v-icon>
                    <span :title="filterPresetsName" class="p-relative flex-grow-1">
                      <span class="filter-preset-label">{{
                          filterPresetsName
                        }}</span>
                    </span>
                  </v-btn>
                </div>

                <div class="d-flex no-wrap justify-end mt-2">
                  <v-btn v-if="canAddPoi" small light outlined @click="newPoi()" :disabled="loading"><b>New POI</b></v-btn>
                </div>
              </div>
            </l-control>
          </l-map>
        </div>
      </v-card>
    </v-flex>
    <div v-if="hasMorePoi" class="partial-data-alert">
      <v-alert dense type="error" :icon="false" class="d-inline-block text-caption px-1 py-0" >{{ 
        `Showing max ${maxPoi} POI. Zoom in to see all POI in the area.`
      }}</v-alert>
    </div>
    <PoiFilter
      v-model="showFilters"
      :filterPresetsSelected="filterPresetsSelected"
      :typeSelected="typeSelected"
      :activeSelected="activeSelected"
      @update="updateFilter"
    />
    <EditPoi
      v-model="poiToEdit"
      v-on:updated="reload()"
      @edit-another-poi="editPoi"
      :poiInitTab="poiInitTab"
    />

    <v-menu
      v-model="showMapContextMenu"
      :position-x="mapContextMenuX"
      :position-y="mapContextMenuY"
      absolute
      offset-y
      z-index="999"
    >
      <v-list dense class="py-0">
        <v-list-item @click="newPoi(lastMapContextMenuLatLng.lat, lastMapContextMenuLatLng.lng)">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-title>New POI here</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-overlay v-if="poiLoading" absolute class="progress" opacity="0" z-index="101">
      <v-progress-linear indeterminate absolute color="primary darken-3" />
    </v-overlay>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import moment from "moment";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LControl } from "vue2-leaflet";
import LRotatedMarker from "vue2-leaflet-rotatedmarker";
import Poi from "@/types/Poi";
import SimplePoi from "@/types/SimplePoi";
import { PoiType } from "@/types/PoiType";
import poiResource from "@/resources/PoiResource";
import PoiHelper from "@/helpers/poiHelper";
import MapHelper from "@/helpers/mapHelper";
import EditPoi from "@/components/poi/EditPoi.vue";
import PoiFilter from "@/components/poi/PoiFilter.vue";
import userStorage from "@/services/UserStorageService";
import googleMapsResource from "@/resources/GoogleMapsResource";
import "@/components/common/leaflet/leaflet-markers-canvas.js";

@Component({
  name: "PoiMap", // name is needed for keep-alive
  components: {
    EditPoi,
    L,
    LMap,
    LTileLayer,
    LMarker,
    LRotatedMarker,
    LControlScale,
    LIcon,
    LTooltip,
    LControl,
    PoiFilter,
  },
})
export default class PoiMap extends Vue {
  moment = moment;
  markersCanvas = new L.MarkersCanvas({ resetOnBoundingBoxChange: false });

  @Prop({ default: null })
  initData!: { poiTab: string; poiId: number } | null;

  maxPoi = 1000;
  loading = false;
  poiLoading = false;

  options = {
    preferCanvas: true,
  };

  searchTerm = "";
  searchThrottleTimer = 0;

  poiToEdit: Poi | null = null;
  poiInitTab: string | null = null;
  mapCenter = [64, 19];
  mapZoom = 5;
  bounds = {} as {
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
  };
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };

  filterPresetStorageKey = "poiMapFilterPreset";
  filterPresetsSelected = userStorage.get(this.filterPresetStorageKey) ?? "active";
  filterPresetsName = "init";

  activeSelectedStorageKey = "poiMapActiveSelected";
  activeSelected = userStorage.get(this.activeSelectedStorageKey) ?? "any";

  typeSelectedStorageKey = "poiMapTypeSelected";
  typeSelected = (userStorage.get(this.typeSelectedStorageKey) ?? []) as PoiType[];

  showFilters = false;

  hasMorePoi = false;
  partialDataMessage = "";

  mapBoundsUpdateThresholdTimer = 0;
  mapBoundsUpdated(bounds: any) {
    if (this.mapBoundsUpdateThresholdTimer) {
      clearTimeout(this.mapBoundsUpdateThresholdTimer);
    }

    this.mapBoundsUpdateThresholdTimer = setTimeout(() => {
      this.bounds = {
        ne: { lat: bounds._northEast.wrap().lat, lng: bounds._northEast.wrap().lng },
        sw: { lat: bounds._southWest.wrap().lat, lng: bounds._southWest.wrap().lng },
      };
      this.reload();
    }, 100);
  }
  @Watch("poiToEdit")
  onChangeCustomerToEdit() {
    if (!this.poiToEdit) {
      this.poiInitTab = null;
    }
  }

  get canAddPoi() {
    return userProfileService.hasPermission(UserPermissionType.AddPoi);
  }

  mounted() {
    const map = this.$refs.map as any;
    this.markersCanvas.addTo(map.mapObject);
    const bounds = map.mapObject.getBounds();
    this.bounds = {
      ne: { lat: bounds._northEast.wrap().lat, lng: bounds._northEast.wrap().lng },
      sw: { lat: bounds._southWest.wrap().lat, lng: bounds._southWest.wrap().lng },
    };
    this.reload();

    if (this.initData?.poiId) {
      this.getInitPoiById(this.initData?.poiId);
    }
  }

  dataReloadTimeoutId: number | undefined = undefined;
  dataReloadIntervalSeconds = 60;
  componentActive = false;
  activated() {
    this.componentActive = true;

    // reload data (user haven't been on the page longer than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0) {
      this.reload();
    }
  }
  deactivated() {
    this.componentActive = false;
  }

  getInitPoiById(poiId: number) {
    poiResource
      .getPoiById(poiId)
      .then((resp) => {
        this.editPoi(resp.data);
        this.poiInitTab = this.initData?.poiTab || null;
      })
      .catch(poiResource.defaultErrorHandler);
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.reload();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  editPoi(poi: Poi) {
    this.poiToEdit = null;

    // use timeout to ensure poi is reset
    setTimeout(() => {
      this.poiToEdit = Object.assign({}, poi);
    }, 100);
  }

  showMapContextMenu = false;
  mapContextMenuX = 0;
  mapContextMenuY = 0;
  lastMapContextMenuLatLng = { lat: 0, lng: 0 };
  openMapContextMenu(e: any) {
    this.lastMapContextMenuLatLng = e.latlng;
    this.mapContextMenuX = e.originalEvent.clientX;
    this.mapContextMenuY = e.originalEvent.clientY;
    this.showMapContextMenu = true;
  }

  // Geocoding
  geocodeSearchTerm = "";
  geocodeLoading = false;
  geocodeSelectedItem: google.maps.GeocoderResult | null = null;
  geocodeItems: google.maps.GeocoderResult[] = [];
  geocodeTimeout = 0;

  @Watch("geocodeSearchTerm")
  onGeocodeSearch(term: string) {
    // Search threshold
    if (this.geocodeTimeout) {
      clearTimeout(this.geocodeTimeout);
      this.geocodeTimeout = 0;
    }

    if (!term) {
      this.geocodeSelectedItem = null;
      this.geocodeItems = [];
      this.geocodeLoading = false;
      return;
    }

    if (this.geocodeSelectedItem != null) return;

    this.geocodeItems = [];
    this.geocodeLoading = true;
    this.geocodeTimeout = setTimeout(() => {
      this.geocode();
    }, 2000);
  }

  @Watch("geocodeSelectedItem")
  onGeocodeItemSelected() {
    if (this.geocodeSelectedItem) {
      this.mapZoom = 15;
      this.mapCenter = [
        this.geocodeSelectedItem!.geometry.location.lat(),
        this.geocodeSelectedItem!.geometry.location.lng(),
      ];
    }
  }

  geocode() {
    this.geocodeLoading = true;
    googleMapsResource
      .geocodeAddress(this.geocodeSearchTerm)
      .then((resp) => {
        if (resp.results) {
          this.geocodeItems = resp.results;
        }
      })
      .catch(googleMapsResource.defaultErrorHandler)
      .finally(() => {
        this.geocodeLoading = false;
      });
  }
  // Geocoding end

  getPoiTypeName(type: PoiType) {
    return PoiHelper.getPoiTypeDisplayName(type);
  }

  newPoi(lat: number | undefined = undefined, lng: number | undefined = undefined) {
    var newPoi = PoiHelper.getNewPoiTemplate();
    if (lat) {
      newPoi.latitude = Number(lat.toFixed(6));
    }
    if (lng) {
      newPoi.longitude = Number(lng.toFixed(6));
    }

    this.editPoi(newPoi);
  }

  getPoiTooltip(poi: SimplePoi) {
    return `<div class="poi-tooltip"><h4> ${this.getPoiTypeName(poi.type)} ID: ${poi.poiId}</h4></div>`;
  }

  updateMarkers(markerData: SimplePoi[]) {
    const markers: any[] = [];
    markerData
      .sort((a, b) => (a.isActive === b.isActive) ? 0 : a.isActive ? 1 : -1)
      .forEach((item) => {
        const iconData = PoiHelper.getMapIconByType(item.type);
        const icon = L.icon({
          ...iconData,
          rotationAngle: iconData.roatationOrigin ? item.direction : 0,
          tooltipAnchor: [20, -20],
          popupAnchor: [0, -40],
          isActive: item.isActive,
          // isTransparent: true,
        });
        const marker = L.marker(L.latLng(item.latitude, item.longitude), { icon, data:item })
          .bindTooltip(this.getPoiTooltip(item))
          .on({ click: () => this.poiClick(item) });

        markers.push(marker);
      });

    setTimeout(() => this.markersCanvas.updateMarkers(markers), 0);
  }

  getPoiInBounds() {
    if (!this.bounds.ne || !this.typeSelected.length) return;

    // Save sorting, filters and search terms
    userStorage.set(this.filterPresetStorageKey, this.filterPresetsSelected);
    userStorage.set(this.typeSelectedStorageKey, this.typeSelected);
    userStorage.set(this.activeSelectedStorageKey, this.activeSelected);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    // Filters
    let isActive: boolean | undefined = undefined;

    // isActive
    if (this.activeSelected === "active") {
      isActive = true;
    } else if (this.activeSelected === "inactive") {
      isActive = false;
    }

    this.loading = true;
    poiResource
      .getPoiInBounds(
        this.bounds.ne.lat,
        this.bounds.ne.lng,
        this.bounds.sw.lat,
        this.bounds.sw.lng,
        isActive,
        this.typeSelected,
        this.maxPoi
      )
      .then((resp) => {
        this.hasMorePoi = resp.data.length >= this.maxPoi;
        this.updateMarkers(resp.data);
      })
      .catch(poiResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  poiClick(poi: SimplePoi) {
    if (!poi?.poiId) return;
    this.poiLoading = true;

    poiResource
      .getPoiById(poi.poiId)
      .then((resp) => this.editPoi(resp.data))
      .catch(poiResource.defaultErrorHandler)
      .finally(() => {
        this.poiLoading = false;
      });
  }

  reload() {
    if (!this.typeSelected.length) {
      return this.markersCanvas.clear();
    }
    this.getPoiInBounds();

    // Restart data reload timeout
    this.restartDataReloadTimeout();
  }

  updateFilter(
    filterPresetsSelected: string,
    typeSelected: PoiType[],
    activeSelected: string,
    filterPresetsName: string,
    reloadPoi: boolean
  ) {
    this.filterPresetsSelected = filterPresetsSelected;
    this.typeSelected = typeSelected;
    this.activeSelected = activeSelected;
    this.filterPresetsName = filterPresetsName;

    if (reloadPoi) {
      this.showFilters = false;
      this.reload();
    }
  }
}
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 100%;
}

.poi-filter,
.poi-map-search-input {
  width: 220px;
  font-size: 14px;
}

.filter-preset-label {
  position: absolute;
  left: 0;
  top: -.55em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  text-align: left;
}

.zoom-message {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}

.progress >>> .v-overlay__content {
  width: 20%;
  margin-bottom: 55px;
}

.partial-data-alert {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  padding: 0 1rem;
  z-index: 99;

  text-align: center;
}
</style>
<style>
.poi-map-search-input .v-label {
  font-size: 14px;
}
</style>
