<template>
  <v-dialog v-model="showDialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-badge dot bordered overlap :value="headersUpdated" class="align-self-end">
        <v-btn 
          icon 
          small 
          title="Table configuration"
          v-bind="attrs"
          v-on="on"
          @click="openDialog"
        >
          <v-icon>mdi-format-columns</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-toolbar dense flat color="primary" height="4px"> </v-toolbar>
      <v-card-title> Table configuration </v-card-title>

      <v-card-text
        :class="{
          'd-sm-flex list-container pb-1': true,
          'mobile-list-container': $vuetify.breakpoint.smAndDown,
        }"
      >
        <div
          :class="{
            'column mr-sm-2 fill-sm-height': true,
            'mobile-column': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-subheader class="heading">Available columns</v-subheader>
          <v-card class="columns-card" tile>
            <v-list dense class="items-list">
              <div v-if="availableColumns.length">
                <v-list-item
                  v-for="(item, i) in availableColumns"
                  :key="i"
                  :selectable="false"
                  :ripple="false"
                  inactive
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                  <v-btn small icon @click="add(item)" class="table-settings-remove-btn">
                    <v-icon small>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-list-item>
              </div>
              <v-list-item v-else>
                <v-list-item-content class="grey--text">
                  <v-list-item-title>No more columns available</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>

        <div
          :class="{
            'column ml-sm-2 fill-sm-height': true,
            'mobile-column': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-subheader class="heading mt-4 mt-sm-auto">Selected columns</v-subheader>
          <v-card class="columns-card" tile>
            <v-list dense class="items-list">
              <v-list-item-group class="items-list">
                <draggable
                  v-if="selectedHeaders.length"
                  v-model="draggableList"
                  delay="200"
                  touchStartThreshold="4"
                  :delayOnTouchOnly="true"
                  ghost-class="dragged-item-ghost"
                >
                  <transition-group>
                    <v-list-item
                      v-for="(item, i) in selectedHeaders"
                      :key="item.value"
                      :selectable="false"
                      :ripple="false"
                      inactive
                    >
                      <v-list-item-content class="drag">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>

                      <v-btn x-small :disabled="i === 0" icon @click="moveUp(item)">
                        <v-icon small> mdi-arrow-up </v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        :disabled="i === selectedHeaders.length - 1"
                        icon
                        @click="moveDown(item)"
                      >
                        <v-icon small> mdi-arrow-down </v-icon>
                      </v-btn>
                      <v-btn
                        small
                        icon
                        @click="remove(item)"
                      >
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </v-list-item>
                  </transition-group>
                </draggable>
                <v-list-item v-else :disabled="true">
                  <v-list-item-content class="grey--text">
                    <v-list-item-title>No columns selected</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import userStorage from "@/services/UserStorageService";
import draggable from "vuedraggable";

@Component({
  components: { draggable },
})
export default class TableConfiguration extends Vue {
  @Prop({ default: [] })
  allHeaders!: { text: string; value: any }[];

  @Prop()
  tableKey!: string;

  @Model("update", { default: () => [] })
  selectedHeaders!: { text: string; value: any }[];

  showDialog = false;
  headersUpdated = false;

  @Watch("selectedHeaders")
  onChangeSelectedHeaders() {
    if (this.tableKey) {
      userStorage.set(this.tableKey, this.selectedHeaders.map((v) => v.value));
    }
  }
  get availableColumns() {
    return this.allHeaders?.filter(
      (v) => !this.selectedHeaders?.find((s) => s.value === v.value)
    );
  }

  get draggableList() {
    return this.selectedHeaders;
  }
  set draggableList(newSelectedHeaders) {
    this.$emit("update", newSelectedHeaders);
  }

  get headersUpdateHashStorageKey() {
    return `${this.tableKey}Hash`;
  }

  checkHeadersUpdate(usedHeadings: string[] | undefined) {
    const newHash = this.generateHeadersHash();
    const cachedHash = userStorage.get(this.headersUpdateHashStorageKey);

    if (!cachedHash) {
      // This is the first time user opens this table (or first time after heading update notification was implemented).
      // We check to see if user has any column in Available list. If user does, we show headers update notification.
      if (usedHeadings) {
        const hasColumnsAvailable = this.allHeaders.some((h) => {
          return usedHeadings.includes(h.value) === false;
        });

        // Show notification without saving hash. This will force notification to be visible until user opens dialog.
        if (hasColumnsAvailable) {
          this.headersUpdated = true;
          return;
        }
      }

      // Just save new hash
      userStorage.set(this.headersUpdateHashStorageKey, newHash);
      return;
    }

    this.headersUpdated = cachedHash !== newHash;
  }

  mounted() {
    let usedHeadings = userStorage.get(this.tableKey);

    // Remove the block after 04.2024
    if (usedHeadings?.length && usedHeadings[0].value) {
      usedHeadings = usedHeadings.map((v: any) => v.value);
    }

    this.checkHeadersUpdate(usedHeadings);

    if (!usedHeadings && this.tableKey) {
      userStorage.set(this.tableKey, this.allHeaders.map((v) => v.value));
      this.$emit("update", this.allHeaders);
      return;
    }

    if (this.allHeaders) {
      this.$emit(
        "update",
        usedHeadings
          .map((value: any) => this.allHeaders.find((s) => s.value === value))
          .filter((v: any) => v)
      );
    }
  }

  openDialog() {
    // Store latest headers hash and reset isUpdate flag
    const newHash = this.generateHeadersHash();
    userStorage.set(this.headersUpdateHashStorageKey, newHash);
    this.headersUpdated = false;
  }

  generateHeadersHash() {
    return this.hash(this.allHeaders.map((v) => v.value).sort().join(""));
  }

  hash(str: string) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  add(item: { text: string; value: any }) {
    this.$emit("update", this.selectedHeaders.concat(item));
  }

  moveUp(item: { text: string; value: any }) {
    const itemInd = this.selectedHeaders.findIndex(({ value }) => item.value === value);
    const newSelectedHeaders = [...this.selectedHeaders];

    if (itemInd === -1 || itemInd === 0) return;
    newSelectedHeaders[itemInd - 1] = newSelectedHeaders.splice(
      itemInd,
      1,
      newSelectedHeaders[itemInd - 1]
    )[0];

    this.$emit("update", newSelectedHeaders);
  }

  moveDown(item: { text: string; value: any }) {
    const itemInd = this.selectedHeaders.findIndex(({ value }) => item.value === value);
    const newSelectedHeaders = [...this.selectedHeaders];

    if (itemInd === -1 || itemInd === this.selectedHeaders.length - 1) return;
    newSelectedHeaders[itemInd + 1] = newSelectedHeaders.splice(
      itemInd,
      1,
      newSelectedHeaders[itemInd + 1]
    )[0];

    this.$emit("update", newSelectedHeaders);
  }

  remove(item: { text: string; value: any }) {
    const newSelectedHeaders = this.selectedHeaders.filter(
      ({ value }) => item.value !== value
    );
    this.$emit("update", newSelectedHeaders);
  }

  close() {
    this.showDialog = false;

    // Workaround for open dialog icon btn not losing focus
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
    }, 50);
  }
}
</script>

<style scoped>
.list-container {
  height: calc(80vh - 60px);
  max-height: 400px;
  min-height: 200px;
  padding-bottom: 0;
  overflow: hidden;
}

.mobile-list-container {
  max-height: calc(80vh - 60px);
}

.columns-card {
  height: calc(100% - 30px);
  overflow-y: auto;
}

.column {
  flex: 1;
}

.mobile-column {
  height: 50%;
}

.heading {
  height: auto;
  padding-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.items-list {
  overflow: hidden;
  min-height: 100%;
}

.drag {
  cursor: move;
}

.dragged-item-ghost {
  background: rgba(0, 0, 0, 0.2);
}

.theme--dark .dragged-item-ghost {
  background: rgba(255, 255, 255, 0.2);
}
</style>
