<template>
  <v-card :loading="loading" :disabled="loading" flat>
    <v-card-text class="pa-0">
      <div class="comment-container">
        <div class="d-flex align-center text-truncate mb-2">
          <div class="author">
            <div class="d-flex align-center text-truncate">
              {{ data.createdBy }}
              <div class="created-date ml-1">
                commented on
                {{ moment(data.createdAt).format("lll") }}
              </div>
            </div>
            <div v-if="data.lastModifiedBy" class="d-flex align-center text-truncate">
              <div class="secondary-font mr-1">edited by </div>
               {{ data.lastModifiedBy }}
              <div class="created-date ml-1">on {{ moment(data.lastModifiedAt).format("lll") }}</div>
            </div>
          </div>
          <v-btn v-if="canEditComment" @click="editMode = true" :disabled="loading || editMode" icon x-small title="Edit">
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            v-if="canEditComment"
            @click="deleteCommentConfirm"
            :disabled="loading || editMode"
            icon
            x-small
            title="Delete"
            class="ml-2"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </div>

        <v-sheet>
          <RichText v-if="editMode" v-model="data.content" :alwaysFocus="true" :small="true" />
          <div v-else class="rte-content-view" v-html="data.content"></div>
        </v-sheet>

        <div class="d-sm-flex justify-space-between align-end">
          <div v-if="data.attachments || editMode">
            <Attachments
              ref="attachmentsRef"
              :files="data.attachments"
              :targetType="targetType"
              :targetId="data.commentId"
              v-on:update="updateFiles"
              v-on:updateLocalFiles="updateLocalFiles"
              :disabled="!editMode || !canEditComment"
              isActionByCommand="true"
            />
          </div>

          <div v-if="editMode" class="text-right mt-4">
            <v-btn text @click="cancelContent" small> Cancel </v-btn>
            <v-btn
              color="primary"
              class="ml-4"
              @click="updateComment"
              :loading="loading"
              :disabled="loading || !isReadyForm"
              small
            >
              Save
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import Comment from "@/types/Comment";
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import deviceResource from "@/resources/DeviceResource";

@Component({
  components: { RichText, Attachments },
})
export default class DeviceComment extends Vue {
  @Ref() readonly attachmentsRef!: Attachments;

  @Prop()
  readonly comment!: Comment;

  @Prop()
  readonly deviceId!: number | undefined;

  moment = moment;
  targetType = AttachmentTargetType.DeviceComment;
  loading = false;
  data: Comment = { ...this.$props.comment };
  localFiles: File[] = [];
  editMode = false;

  @Watch("comment")
  onChangeComment() {
    this.data = { ...this.comment };
  }

  get canEditComment() {
    return (
      userProfileService.currentUser?.isAdministrator || this.comment?.createdById === userProfileService.currentUser?.userId
    );
  }

  get isReadyForm() {
    return Boolean(this.data.content.trim().length);
  }

  updateFiles(files: AttachedFile[]) {
    this.data.attachments = files;
  }

  updateLocalFiles(files: File[]) {
    this.localFiles = files;
  }

  deleteCommentConfirm() {
    if (!this.canEditComment || this.comment == null) {
      return;
    }
    this.$confirm
      .show(
        `Delete ${this.comment.createdBy}'s comment dated ${moment(
          this.comment.lastModifiedAt || this.comment.createdAt
        ).format("lll")}?`
      )
      .then((confirmed) => {
        if (confirmed) {
          this.deleteComment();
        }
      });
  }

  deleteComment() {
    if (!this.canEditComment || this.comment == null || this.deviceId === undefined) {
      return;
    }

    this.loading = true;
    deviceResource
      .deleteComment(this.deviceId, this.comment.commentId)
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, `Comment deleted`);
        this.$emit("delete", this.comment.commentId);
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  cancelContent() {
    this.data = { ...this.comment };
    this.localFiles = [];
    this.editMode = false;
  }

  updateComment() {
    if (this.deviceId === undefined) return;

    this.loading = true;
    return deviceResource
      .updateDeviceComment(this.deviceId, this.data)
      .then(() => (this.$refs.attachmentsRef as Attachments)?.uploadLocalFiles())
      .then(() => (this.$refs.attachmentsRef as Attachments)?.removeFiles())
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, "Comment updated");
        this.data.lastModifiedAt = new Date().toISOString();
        this.data.lastModifiedBy = userProfileService.currentUser?.username;
        this.data.lastModifiedById = userProfileService.currentUser?.userId;
        this.$emit("update", { ...this.data });
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.editMode = false;
      });
  }
}
</script>

<style scoped>
.comment-container {
  position: relative;
}
.comment-header {
  font-size: 13px;
}
.created-date {
  font-weight: 400;
  color: grey;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}
.secondary-font {
  font-weight: 400;
  color: grey;
}
.author {
  max-width: calc(100% - 55px);
  flex: 1;
  font-size: 12px;
  line-height: 1.2;
}
</style>
