<template>
  <v-container>
    <v-card v-for="(data, i) in versionList.slice(0, lastLoadInd)" :key="i" class="mb-5">
      <v-card-title>
        v{{ data.version }}
      </v-card-title>
      <v-card-subtitle>
        {{ moment(data.date).format("MMMM DD, YYYY") }}
      </v-card-subtitle>
      <v-divider/>
      <v-card-text>
        <HtmlSource :src="`/releases/${data.version}.html`" />
      </v-card-text>
    </v-card>

    <div class="d-flex justify-center">
      <v-btn v-if="!loading && versionList.length > lastLoadInd" class="my-2" text small @click="lastLoadInd += limit">
        <v-icon left>mdi-arrow-down-bold</v-icon>
        Show more
      </v-btn>
    </div>
    <v-overlay absolute :value="loading" opacity="0.3" z-index="99999">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import staticResource from "@/resources/StaticResource";
import ReleaseData from "@/types/ReleaseData";
import HtmlSource from "@/components/common/HtmlSource.vue";
import moment from "moment";

@Component({ components: { HtmlSource } })
export default class WhatIsNew extends Vue {
  loading = false;
  versionList: ReleaseData[] = [];
  lastLoadInd = 0;
  limit = 5;
  moment = moment;

  mounted() {
    this.getVersionList();
  }

  getVersionList() {
    this.loading = true;

    staticResource
      .getReleases()
      .then((res) => {
        this.versionList = res.data.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
        this.lastLoadInd += this.limit;
      })
      .catch(staticResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
