import { render, staticRenderFns } from "./CreateIssue.vue?vue&type=template&id=17abe4e9&scoped=true&"
import script from "./CreateIssue.vue?vue&type=script&lang=ts&"
export * from "./CreateIssue.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17abe4e9",
  null
  
)

export default component.exports