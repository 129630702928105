import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import moment from "moment";
import { Loader } from "@googlemaps/js-api-loader";

import "./assets/css/shared.css";
import "./assets/css/theme.css";
 // Leaflet is not installed by the package.json to fix 
//  the error described in this link:https://github.com/vue-leaflet/Vue2Leaflet/issues/314
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = true;

// Momment
Vue.prototype.moment = moment;
moment.locale("en-gb");

// Google Maps
const loader = new Loader({
    apiKey: process.env?.VUE_APP_GOOGLE_API_KEY,
    version: "weekly",
    language: "no", // NB! AreaInfo suggestions depend on language set to "no"
});
loader.load();

new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app");
