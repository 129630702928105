<template>
  <side-sheet  v-model="showFilters" heading="Filter POI" contentClass="filters">
    <template>
      <h4>Filter presets</h4>
      <v-select
        attach
        v-model="c_filterPresetsSelected"
        :items="filterPresetsItems"
        dense
        hide-details
        class="filter-item"
        @input="applyFilterPreset()"
      />

      <h4 class="mt-6">POI Types</h4>
      <div>
        <v-btn x-small link @click="selectAllPoiTypes()">Select all</v-btn> /
        <v-btn x-small link @click="deselectAllPoiTypes()">Deselect all</v-btn>
      </div>
      <div v-for="type in typeItems" :key="type.value">
        <v-checkbox
          v-model="c_typeSelected"
          :label="type.text"
          :value="type.value"
          dense
          hide-details
          @click="setCustomFilterPreset()"
        />
      </div>

      <h4 class="mt-6">Active status</h4>
      <v-select
        v-model="c_activeSelected"
        :items="activeItems"
        dense
        hide-details
        class="filter-item"
        @input="setCustomFilterPreset()"
      />
    </template>
    <template slot="actions">
      <v-spacer />
      <v-btn text @click="resetFilters()">Reset</v-btn>
      <v-btn color="primary" class="ml-4" @click="applyFilters(true)">Apply</v-btn>
    </template>
  </side-sheet>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { PoiType } from "@/types/PoiType";
import PoiHelper from "@/helpers/poiHelper";
import SideSheet from "@/components/layout/SideSheet.vue";

@Component({
  name: "PoiFilter", // name is needed for keep-alive
  components: {SideSheet},
})
export default class PoiFilter extends Vue {
  @Prop({ default: false })
  readonly value!: boolean;

  @Prop({ default: "" })
  readonly filterPresetsSelected!: string;

  @Prop({ default: [] })
  readonly typeSelected!: PoiType[];

  @Prop({ default: "" })
  readonly activeSelected!: string;

  c_activeSelected: string = this.$props.activeSelected;
  activeItems = [
    { text: "Any", value: "any" },
    { text: "Active only", value: "active" },
    { text: "Inactive only", value: "inactive" },
  ];

  c_filterPresetsSelected: string = this.$props.filterPresetsSelected;
  filterPresetsItems = [
    { text: "All POI", value: "all" },
    { text: "Active non-static POI only", value: "active" },
    { text: "Static POI only", value: "static" },
    { text: "Custom", value: "custom" },
  ];
  c_typeSelected: PoiType[] = this.$props.typeSelected;
  typeItems = [
    { text: this.getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
    { text: this.getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
    { text: this.getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
    { text: this.getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
    { text: this.getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
    { text: this.getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
    { text: this.getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
    { text: this.getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
    { text: this.getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
    { text: this.getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
  ];

  @Watch("showFilters")
  showFiltersData() {
    if (this.showFilters) {
      this.c_filterPresetsSelected = this.filterPresetsSelected;
      this.c_typeSelected = [...this.typeSelected];
      this.c_activeSelected = this.activeSelected;
    }
  }

  get showFilters() {
    return this.value;
  }
  set showFilters(value: boolean) {
    this.$emit("input", value);
  }

  created() {
    this.applyFilterPreset();
    this.applyFilters(false);
  }

  getPoiTypeDisplayName(type: PoiType) {
    return PoiHelper.getPoiTypeDisplayName(type);
  }

  resetFilters() {
    this.c_filterPresetsSelected = "active";
    this.applyFilterPreset();
    this.applyFilters(true);
  }

  applyFilters(reloadPoi: boolean) {
    const filterPresetsName = this.filterPresetsItems.find((item) => item.value === this.c_filterPresetsSelected)?.text
    this.$emit("update", this.c_filterPresetsSelected, [...this.c_typeSelected], this.c_activeSelected, filterPresetsName, reloadPoi);

    if (reloadPoi) {
      this.showFilters = false;
    }
  }

  setCustomFilterPreset() {
    this.c_filterPresetsSelected = "custom";
  }

  selectAllPoiTypes() {
    this.c_typeSelected = this.typeItems.map((el) => el.value);
    this.setCustomFilterPreset();
  }

  deselectAllPoiTypes() {
    this.c_typeSelected = [];
    this.setCustomFilterPreset();
  }

  applyFilterPreset() {
    if (this.c_filterPresetsSelected === "all") {
      this.c_typeSelected = this.typeItems.map((el) => el.value);
      this.c_activeSelected = "any";
    } else if (this.c_filterPresetsSelected === "active") {
      this.c_typeSelected = PoiHelper.getNonPermanentPoiTypes();
      this.c_activeSelected = "active";
    } else if (this.c_filterPresetsSelected === "static") {
      this.c_typeSelected = PoiHelper.getPermanentPoiTypes();
      this.c_activeSelected = "any";
    }
  }

}
</script>

<style scoped></style>
