<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-text>
      <v-sheet class="add-issue-comment">
        <RichText v-model="newContent" :disabled="loading" small="true" placeholder="Write your comment here..." />
      </v-sheet>
      <Attachments
        ref="attachmentsRef"
        :targetType="targetType"
        :targetId="commentId"
        v-on:updateLocalFiles="updateLocalFiles"
        isActionByCommand="true"
      />
      <div class="text-right mt-4">
        <v-btn color="primary" @click="addComment" :loading="loading" :disabled="!isReadyForm || loading" small>
          Add comment
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserPermissionType } from "@/types/UserPermissionType";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import userProfileService from "@/services/UserProfileService";
import issueResource from "@/resources/IssueResource";
import { InfoMessageType } from "@/types/InfoMessageType";
import infoMessageService from "@/services/InfoMessageService";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import ChangeManager from "@/services/ChangeManager";

@Component({
  components: { RichText, Attachments },
})
export default class AddIssueComment extends Vue {
  loading = false;
  newContent = "";
  targetType = AttachmentTargetType.IssueComment;
  commentId = 0;
  localFiles: File[] = [];
  changesControl = new ChangeManager();
  mounted() {
    this.changesControl.init({
      target: `new_comment`,
      onSave: this.addComment,
      message: "Did you forget to submit new comment?",
      isChanged: false,
    });
  }

  destroyed() {
    this.changesControl.deactivate()
  }

  @Watch("newContent")
  onChangeContent() {
    if (this.newContent) {
      this.changesControl.activate();
    }

    if (!this.newContent && !this.localFiles?.length) {
      this.changesControl.deactivate();
    }
  }

  get canAddComment() {
    return userProfileService.hasPermission(UserPermissionType.ViewIssues);
  }

  get isReadyForm() {
    return Boolean(this.newContent.trim().length);
  }

  updateLocalFiles(files: File[]) {
    this.localFiles = files;
    if (files?.length) {
      this.changesControl.activate();
    }

    if (!this.newContent && !files?.length) {
      this.changesControl.deactivate();
    }
  }

  addComment() {
    if (!this.canAddComment || !this.isReadyForm) {
      if(!this.newContent.trim().length) {
        infoMessageService.show(InfoMessageType.Info, "Add text to the new comment");
      }
      return;
    }

    this.loading = true;
    let data: any;
    issueResource
      .addIssueComment(Number(this.$route.params.id), this.newContent)
      .then((resp) => {
        this.commentId = resp.data.commentId;
        data = resp.data;
      })
      .then(() => (this.$refs.attachmentsRef as Attachments)?.uploadLocalFiles())
      .then((attachments) => {
        data.attachments = attachments;
        this.commentId = 0;
        this.localFiles = [];

        infoMessageService.show(InfoMessageType.Success, "Comment added");
        this.$emit("created", data);
        this.newContent = "";
        this.changesControl.deactivate();
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>
.add-issue-comment {
  position: relative;
}
</style>
