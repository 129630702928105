<template>
  <div>
    <div class="subtitle-1 mb-1">Mobile network provider information</div>

    <v-progress-linear v-if="!status && loading" indeterminate></v-progress-linear>

    <table v-if="status" class="subtitle-2 mt-4 info-table">
      <tr>
        <td class="grey--text">Iccid:</td>
        <td>{{ status.iccid }}</td>
      </tr>
      <tr>
        <td class="grey--text">Imsi:</td>
        <td>{{ status.imsi }}</td>
      </tr>
      <tr>
        <td class="grey--text">Msisdn:</td>
        <td>{{ status.msisdn }}</td>
      </tr>
      <tr>
        <td class="grey--text">Imei:</td>
        <td>{{ status.imei }}</td>
      </tr>
      <tr>
        <td class="grey--text">Rate plan:</td>
        <td>{{ status.ratePlan }}</td>
      </tr>
      <tr>
        <td class="grey--text">Communication plan:</td>
        <td>{{ status.communicationPlan }}</td>
      </tr>
      <tr>
        <td class="grey--text">Added on:</td>
        <td>{{ moment(status.dateAdded).format("lll") }}</td>
      </tr>
      <tr v-if="status.dateActivated">
        <td class="grey--text">Activated on:</td>
        <td>{{ moment(status.dateActivated).format("lll") }}</td>
      </tr>
      <tr v-if="status.dateUpdated">
        <td class="grey--text">Updated on:</td>
        <td>{{ moment(status.dateUpdated).format("lll") }}</td>
      </tr>
      <tr>
        <td class="grey--text">Status:</td>
        <td :class="statusClass()">{{ status.status }}</td>
      </tr>
    </table>

    <div class="mt-4">
      <v-btn :disabled="loading" color="secondary" class="mr-4" @click="refresh">Refresh</v-btn>
      <v-btn v-if="canEditDevice && isActivated" @click="deactivateConfirmation" :loading="loading" color="red" class="mr-4"
        >Deactivate</v-btn
      >
      <v-btn v-if="canEditDevice && isDeactivated" @click="activate" :loading="loading" color="green" class="mr-4">Activate</v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import deviceResource from "@/resources/DeviceResource";
import MobileNetworkProviderStatus from "@/types/MobileNetworkProviderStatus";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

@Component({
  components: {},
})
export default class DeviceMobileNetProvider extends Vue {
  moment = moment;

  @Prop()
  readonly deviceId!: number | null;

  loading = false;
  status: MobileNetworkProviderStatus | null = null;

  @Watch("deviceId", { immediate: true })
  onValueChanged() {
    this.getData();
  }

  get canEditDevice() {
    return userProfileService.hasPermission(UserPermissionType.EditDevices);
  }

  get isActivated() {
    return this.status?.status === "ACTIVATED" || this.status?.status === "TEST_READY";
  }

  get isDeactivated() {
    return this.status?.status === "DEACTIVATED" || this.status?.status === "TEST_READY";
  }

  getData() {
    if (!this.deviceId) {
      return;
    }

    this.loading = true;

    deviceResource
      .getMobileNetworkProviderStatus(this.deviceId)
      .then((resp) => {
        this.status = resp.data;
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  deactivateConfirmation() {
    this.$confirm
      .show(`Device will no longer be able to connect to any services until activated again.`, {
        width: 360,
      })
      .then((confirmed) => {
        if (confirmed) {
          this.deactivate();
        }
      });
  }

  deactivate() {
    if (!this.canEditDevice) {
      return;
    }

    this.loading = true;

    deviceResource
      .deactivateSimCard(this.deviceId!)
      .then(() => {
        this.status = null;
        this.getData();
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  activate() {
    if (!this.canEditDevice) {
      return;
    }

    this.loading = true;

    deviceResource
      .activateSimCard(this.deviceId!)
      .then(() => {
        this.status = null;
        this.getData();
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  refresh() {
    this.status = null;
    this.getData();
  }

  statusClass() {
    if (this.status!.status === "ACTIVATED") {
      return "green--text";
    } else if (this.status!.status === "DEACTIVATED") {
      return "red--text";
    } else {
      return "";
    }
  }
}
</script>

<style scoped>
.info-table td:first-child {
  padding-right: 2rem;
}
</style>