<template>
  <div>
    <div class="subtitle-1 mb-1">POI Change History</div>
    <p class="caption">List of the last POI information changes.</p>

    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <div v-if="poiChanges && poiChanges.length">
      <v-divider />
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">Modified on</th>
              <th scope="col">Modified by</th>
              <th scope="col">Field name</th>
              <th scope="col">Old value</th>
              <th scope="col">New value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rec, ind) in poiChanges" :key="ind">
              <td class="caption">{{ moment(rec.modifiedDate).format("lll") }}</td>
              <td class="caption">{{ rec.modifiedBy }}</td>
              <td class="caption">{{ rec.fieldName }}</td>
              <td class="caption">{{ formatValue(rec.valueOld) }}</td>
              <td class="caption">{{ formatValue(rec.valueNew) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-else-if="!loading">No changes found.</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import poiResource from "@/resources/PoiResource";

@Component({
  components: {},
})
export default class PoiChangeHistory extends Vue {
  moment = moment;

  @Prop()
  readonly poiId!: number | null;

  loading = false;
  poiChanges = [] as EntityChangeRecord[];

  @Watch("poiId", { immediate: true })
  onValueChanged() {
    this.poiChanges = [];
    this.getData();
  }

  getData() {
    if (!this.poiId) {
      return;
    }

    this.loading = true;

    poiResource
      .getPoiChangeHistory(this.poiId)
      .then((resp) => {
        this.poiChanges = resp.data;
      })
      .catch(poiResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  formatValue(val: any) {
    var result = val;
    if (typeof val === "object") {
      result = "";
    } else if (val.toString().match(/^\d\d\d\d-\d\d-\d\d/)) {
      result = moment(val).format("lll");
    }
    
    return result;
  }
}
</script>