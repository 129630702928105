<template>
  <v-card flat rounded="0" min-height="100%" class="main-navigation">
    <v-list-item class="primary">
      <v-list-item-content>
        <v-col cols="8" class="pa-0 pr-2 dark--text">
          <v-list-item-title class="title">Safedrive</v-list-item-title>
          <v-list-item-subtitle class="darkgrey--text">Studio</v-list-item-subtitle>
        </v-col>
        <v-col cols="4" class="pa-0 text-right">
          <v-chip v-if="environment" label :color="environmentColor" class="font-weight-bold white--text">{{
            environment
          }}</v-chip>
        </v-col>
      </v-list-item-content>
    </v-list-item>

    <v-list v-if="user">
      <v-list-item to="/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="canViewCustomers" to="/customers">
        <v-list-item-icon>
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Customers</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-if="canViewDevices"
        group="devices"
        active-class="nav-active"
        prepend-icon="mdi-tablet-cellphone"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Devices</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-if="canViewDeviceList" active-class="nav-active" to="/devices/list">
          <v-list-item-content>
            <v-list-item-title>List</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canViewDeviceLogs" active-class="nav-active" to="/devices/logs">
          <v-list-item-content>
            <v-list-item-title>Logs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewPoi" group="poi" active-class="nav-active" prepend-icon="mdi-map-marker-multiple" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>POI</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item active-class="nav-active" to="/poi/list">
          <v-list-item-content>
            <v-list-item-title>List</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/poi/map">
          <v-list-item-content>
            <v-list-item-title>Map</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewAds" group="advertisement" active-class="nav-active" prepend-icon="mdi-basket" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Advertisement</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item active-class="nav-active" to="/advertisement/adverts">
          <v-list-item-content>
            <v-list-item-title>Adverts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canEditAdsSettings" active-class="nav-active" to="/advertisement/settings">
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group
        v-if="canViewSupport"
        group="support"
        active-class="nav-active"
        prepend-icon="mdi-message-reply-text"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-if="canViewIssues" active-class="nav-active" to="/support/issues">
          <v-list-item-content>
            <v-list-item-title>Issues</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewAdmin" group="tools" active-class="nav-active" prepend-icon="mdi-wrench" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Tools</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item active-class="nav-active" to="/tools/geohash-map">
          <v-list-item-content>
            <v-list-item-title>Geohash Map</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/tools/device-message-coding">
          <v-list-item-content>
            <v-list-item-title>Device msg de/encode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/tools/location-history">
          <v-list-item-content>
            <v-list-item-title>Device Location History</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewAdmin" group="admin" active-class="nav-active" prepend-icon="mdi-cog" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item active-class="nav-active" to="/admin/users">
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/admin/user-groups">
          <v-list-item-content>
            <v-list-item-title>User Groups</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="nav-active" to="/admin/api-keys">
          <v-list-item-content>
            <v-list-item-title>API Keys</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import appResource from "@/resources/AppResource";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

@Component({
  components: {},
})
export default class SidebarNav extends Vue {
  environment = "";
  environmentColor = "blue darken-2";
  get user() { return userProfileService.currentUser; }
  get canViewCustomers() {
    return  this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.ViewCustomers)));
  }
  get canViewDeviceList() {
    return  this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.ViewDevices)));
  }
  get canViewDeviceLogs() {
    return this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.ViewDeviceLogs)));
  }
  get canViewDevices() {
    return  this.canViewDeviceList;
  }
  get canViewPoi() {
    return  this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.ViewPoi)));
  }
  get canViewIssues() {
    return this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.ViewIssues)));
  }
  get canViewSupport() {
    return this.canViewIssues;
  }
  get canViewAds() {
    return  this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.ViewAds)));
  }
  get canEditAdsSettings() {
    return  this.user && (this.user.isAdministrator || (this.user.userPermissions.some((p)=> p === UserPermissionType.EditAdsSettings)));
  }
  get canViewAdmin() {
    return  this.user && this.user.isAdministrator;
  }

  get isLocalOrTest() {
    return document.location.host.includes("localhost") || document.location.host.includes("studio-test.mysafedrive.io");
  }

  created() {
    appResource
      .getInfo()
      .then((resp) => {
        if (resp.data.environment) {
          this.environment = resp.data.environment;
          this.environmentColor = "red";
        }
      })
      .catch(appResource.defaultErrorHandler);
  }
}
</script>

<style scoped></style>