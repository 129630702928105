<template>
  <div v-if="canAddIssue || canEditIssues">
    <v-combobox
      v-model="selectedIssue"
      :filter="filter"
      :items="suggestion"
      :hide-no-data="!search && !creating && !issueUpdating"
      :loading="loading || creating || issueUpdating"
      :disabled="creating"
      :search-input.sync="search"
      clearable
      item-value="issueId"
      auto-select-first
      ref="issue"
      label="Search existing or create new issue"
      persistent-hint
      hint="Select an existing issue or create a new issue and automatically connect this device to the issue."
      @focus="onFocus"
      :menu-props="{ maxWidth: '770' }"
    >
      <template v-slot:no-data>
        <v-list-item v-if="creating" class="justify-center primary--text">...Creating</v-list-item>
        <v-list-item v-else-if="loading" class="justify-center">Searching...</v-list-item>
        <v-list-item v-else-if="!canAddIssue" class="text-disabled subheading">No data available</v-list-item>
        <v-list-item v-else class="d-none" />
      </template>
      <template v-slot:selection="{ attrs, item, selected }">
        <span small v-bind="attrs" :input-value="selected" class="text-no-wrap text-truncate selection">
          {{ item.name }}
        </span>
      </template>
      <template v-slot:item="{ item }">
        <div class="d-flex flex-grow-1 text-left">
          <div class="flex-srink-1">{{ item.issueId }}</div>
          <div class="flex-grow-1 mx-4">{{ item.name }}</div>
          <div :class="IssueHelper.getIssueStatusColor(item.status, true)" class="flex-srink-1">
            {{ IssueHelper.getIssueStatusDisplayName(item.status) }}
          </div>
        </div>
      </template>
      <template v-slot:append-item>
        <v-list-item
          v-if="search && !loading && !creating && !issueUpdating && canAddIssue"
          class="d-flex justify-center"
          @click="addNewIssue"
        >
          <span class="subheading mr-2">Create Issue: </span>
          {{ search }}
        </v-list-item>
      </template>
    </v-combobox>
    <div class="text-right mt-2 mb-2">
      <v-btn
        v-if="canEditIssues"
        small
        color="primary"
        :disabled="!selectedIssue"
        :loading="issueUpdating"
        class="align-self-end"
        @click="addDeviceToIssue"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({ components: {} })
export default class AddDeviceIssue extends Vue {
  @Prop()
  readonly deviceId!: number | null;

  IssueHelper = IssueHelper;
  suggestion: Issue[] = [];
  loading = false;
  creating = false;
  issueUpdating = false;

  search: string | null = null;
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  selectedIssue: Issue | null = null;

  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  get canEditIssues() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues);
  }

  @Watch("selectedIssue")
  onSelectedIssue() {
    if(this.search) this.search = null;
    if (typeof this.selectedIssue === "string") {
      this.selectedIssue = null;
      // @ts-ignore
      this.$refs.issue.reset();
    }
  }

  @Watch("search")
  onSearchChanged() {
    if (this.creating || !this.search) return;

    this.selectedIssue = null;
    this.suggestion = this.suggestion.filter((v) => v.issueId);
    this.clearSearchTimeout();
    this.searchThrottleTimer = setTimeout(() => {
      this.getData();
    }, 800);
  }

  clearSearchTimeout() {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }
  }

  onFocus() {
    // Initial tags load on focus
    if (this.creating) return;
    if (this.suggestion?.length === 0) {
      this.getData();
    }
  }

  getData() {
    if (!this.deviceId || !this.canEditIssues) return;
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    if (!this.deviceId) return;
    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      if (!this.deviceId) return;
      issueResource
        .getIssuesPaged(
          10,
          1,
          this.search || "",
          undefined,
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          this.cancelToken
        )
        .then((resp) => {
          this.suggestion = resp.data.items;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  addDeviceToIssue() {
    if (!this.canEditIssues || !this.selectedIssue) {
      return;
    }
    this.issueUpdating = true;
    if (!this.deviceId) return;
    issueResource
      .addDevice(this.selectedIssue.issueId, this.deviceId)
      .then(() => {
        infoMessageService.show(
          InfoMessageType.Success,
          `This device is now connected to issue ${this.selectedIssue?.name}`
        );
        this.search = null
        // @ts-ignore
        this.$refs.issue.reset();
        this.$emit('update')
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.issueUpdating = false;
      });
  }

  addNewIssue() {
    if (!this.canAddIssue || !this.search) {
      return;
    }
    this.creating = true;
    if (!this.deviceId) return;
    issueResource
      .addIssue(this.search, this.deviceId ? [this.deviceId] : undefined)
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, `New Issue with name ${this.search} created`);
        this.$emit('update')
        // @ts-ignore
        this.$refs.issue.blur()
        // @ts-ignore
        this.$refs.issue.reset();
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.creating = false;
      });
  }

  filter(item: Issue, queryText: string) {
    const query = queryText || "";
    return `${item.issueId} ${item.name.toLowerCase()} 
    ${item.tags?.map(({ name }) => name?.toLowerCase())?.join(" ")}`.includes(query.toString().toLowerCase());
  }
}
</script>

<style scoped>
</style>
