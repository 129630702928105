<template>
  <div v-click-outside="onBlur" @click="onFocus">
    <quill-editor
      ref="quillEditor"
      :options="editorOption"
      v-model="inputValue"
      :disabled="disabled"
      :class="styles"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// @ts-ignore
import { quillEditor } from "vue-quill-editor";

@Component({
  components: { quillEditor },
})
export default class RichText extends Vue {
  @Prop({ default: "" })
  readonly value!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: false })
  readonly small!: any;

  @Prop({ default: false })
  readonly placeholder!: string;

  @Prop({ default: false })
  readonly alwaysFocus!: boolean;

  showToolbar = false;
 
  mounted() {
    if (this.alwaysFocus) {
      this.onFocus();
    }
  }

  get styles() {
    return {
      "w-hover": true,
      "hide-toolbar": !this.showToolbar,
      active: this.showToolbar,
      "small-rich-text": this.small,
    };
  }

  get editorOption() {
    return { placeholder: this.placeholder, modules: { toolbar: true } };
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(newValue) {
    this.$emit("input", newValue);
  }

  onFocus() {
    if (!this.disabled && !this.showToolbar) {
      this.showToolbar = true;
      this.$emit("focus");
    }
  }

  onBlur() {
    if (this.alwaysFocus) return;
    
    if (this.showToolbar) {
      this.showToolbar = false;
      this.$emit("blur");
    }
  }
}
</script>

<style>
.quill-editor {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid var(--v-dark-lighten3);
  border-radius: 4px;
  min-height: 200px;
}

.ql-container.ql-snow {
  min-height: 150px;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ql-editor {
  font-family: Roboto, sans-serif !important;
  font-size: 0.875rem;
  padding: 5px 0;
}
.ql-editor.ql-blank::before {
  right: 0;
  left: 0;
}

.active .ql-editor {
  font-family: Roboto, sans-serif !important;
  font-size: 0.875rem;
  padding: 10px 15px;
}

.active .ql-editor.ql-blank::before {
  right: 15px;
  left: 15px;
}

.small-rich-text.quill-editor {
  min-height: 0;
}

.small-rich-text .ql-container.ql-snow {
  min-height: 0;
}

.quill-editor {
  border: none;
}

.active.quill-editor {
  border: 1px solid var(--v-primary-base);
  box-shadow: inset 0px 0px 0px 1px var(--v-primary-base);
}
.ql-toolbar.ql-snow {
  border: none;
}

:root[theme="dark"] .ql-toolbar .ql-stroke {
  stroke: var(--v-darkgrey-lighten5);
}

:root[theme="dark"] .ql-toolbar .ql-fill {
  fill: var(--v-darkgrey-lighten5);
}

:root[theme="dark"] .ql-toolbar .ql-picker {
  color: var(--v-darkgrey-lighten5);
}

.hide-toolbar .ql-toolbar {
  padding: 0;
}
.hide-toolbar .ql-toolbar span {
  display: none;
}
.ql-editor.ql-blank {
  flex: 1;
}
.ql-snow .ql-tooltip {
  z-index: 99999;
}
.theme--dark .ql-snow .ql-picker-options {
  background-color: #2d2d2d;
}
.theme--dark .ql-snow .ql-tooltip {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
}
.theme--dark .ql-snow .ql-tooltip input[type="text"] {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
  outline: none;
}
.theme--dark .ql-editor.ql-blank::before {
  color: #fff;
  opacity: 0.4;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--v-primary-base);
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--v-primary-base);
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--v-primary-base);
}
</style>
